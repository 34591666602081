import React, { useRef, useMemo, useState, useEffect } from "react";
import {
  Button,
  Box,
  Card,
  CardActions,
  CardContent,
  Container,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  Snackbar,
  Stack,
  Input,
  Radio,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {
  ArrowRightAlt,
  FiberManualRecord,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import { AgentContext } from "../../contexts";
import { useContext } from "react";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export const Signup = () => {
  const search = useLocation().search;
  const { agent, setAgent } = useContext(AgentContext);
  const serv = new URLSearchParams(search).get("s");
  const amount = new URLSearchParams(search).get("a");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState("");
  const [open, setOpen] = useState(false);
  const [openIam, setOpenIam] = useState(false);
  const [errVal, setErrVal] = useState("");
  const [errType, setErrType] = useState("");
  const [agree, setAgree] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);

  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    setErrType("");
    setErrVal("");
  };

  const handleLogin = () => {
    navigate("/sign-in?s=" + serv + "&a=" + amount);
  };

  const raw = {
    email,
    password,
  };
  function MyFormHelperText(e) {
    let pass = e.target.value;
    setPassword(pass);
    if (pass == "") {
      document.getElementById("pser").innerHTML = "Password is required";
      document.getElementById("pser").style.color = "red";
      document.querySelector(".pass fieldset").style.borderColor = "red";
      return;
    }
    if (pass.length < 6) {
      document.getElementById("pser").innerHTML =
        "Password must be minimum 6 characters";
      document.getElementById("pser").style.color = "red";
      document.querySelector(".pass fieldset").style.borderColor = "red";
      return;
    }
    document.getElementById("pser").innerHTML = "";
    document.querySelector(".pass fieldset").style.borderColor = "";
    return "";
  }
  function emailValid(e) {
    let eml = e.target.value;
    setEmail(eml);
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!eml.match(validRegex)) {
      setIsValidEmail(false);
      document.querySelector("#eser").innerHTML =
        "Please enter a valid email address";
      document.querySelector("#eser").style.color = "red";
      document.querySelector(".email fieldset").style.borderColor = "red";
      return;
    }
    setIsValidEmail(true);
    document.querySelector("#eser").innerHTML = "";
    document.querySelector(".email fieldset").style.borderColor = "";
    return "";
  }

  const handleSignUp = async () => {
    setOpen(true);
    setErrType("primary");
    setErrVal("Processing...");
    let body = {
      email: email,
      password: password,
    };
    const resp = await fetch(
      "https://transecure.onrender.com/api/v1/auth/users/register",
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: { "Content-type": "application/json" },
      }
    );
    const data = await resp.json();
    console.log(data);
    if (data.error) {
      console.log(data.error);
      setOpen(true);
      setErrType("error");
      setErrVal(data.error.message);
      console.log(data.error.message);
      return;
    }
    setTimeout(() => {
      setOpen(true);
      setErrType("success");
      setErrVal("Registration successful!");
    }, 1000);
    setTimeout(() => {
      setOpen(true);
      setErrType("success");
      setErrVal("Redirecting...");
      if (serv == "" || amount == "" || serv == null || amount == null) {
        if (data.user) {
          setAgent(data.user);
          localStorage.setItem("agent", JSON.stringify(data.user));
          localStorage.setItem("token", data.token);
          navigate("/transactions");
        }
      } else {
        if (data.user) {
          setAgent(data.user);
          localStorage.setItem("agent", JSON.stringify(data.user));
          localStorage.setItem("token", data.token);
          navigate("/transactions");
        }
      }
    }, 2000);
    return;
  };

  return (
    <main
      style={{
        backgroundColor: "#fff",
        width: "100vw",
        minHeight: "100vh",
      }}
      className="authMain">
      <header
        style={{
          padding: "10px 16px",
          marginBottom: 152,
          margin: "0 auto",
          justifyContent: "space-between",
          boxShadow: "0 2px 4px 0 rgb(0 0 0 / 10%)",
        }}
        className="flex justify-between align-center desktopHide">
        <Link to="/" style={{ marginRight: 30 }}>
          <img src="/images/logoinvert.png" width="65px" />
        </Link>
      </header>
      <Container>
        <header
          style={{
            padding: "10px 0",
            marginBottom: 152,
            margin: "0 auto",
            justifyContent: "space-between",
          }}
          className="flex justify-between align-center mobileHide">
          <Link to="/" style={{ marginRight: 30 }}>
            <img src="/images/logoinvert.png" width="100px" />
          </Link>
        </header>
        <Box className="authMob">
          <Grid2 container>
            <Grid2 className="mobileHide" md lg={6}>
              <Box>
                <img src="/images/illustration_login.svg" />
              </Box>
            </Grid2>
            <Grid2 md={12} lg={4} lgOffset={"auto"} className="cardGrid">
              <Card
                sx={
                  window.innerHeight <= 899
                    ? { minWidth: 275, padding: "24px 20px 20px" }
                    : { minWidth: 275, padding: "32px 20px 0px" }
                }
                className="gridcard">
                <Typography
                  variant="h6"
                  component="h6"
                  style={{
                    color: "#fff",
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                  }}>
                  Kele gi,
                </Typography>
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { marginTop: "8px", width: "25ch" },
                  }}
                  noValidate
                  autoComplete="off">
                  <CardContent>
                    <Typography
                      variant="h3"
                      component="h3"
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: 700,
                        color: "#fff",
                        paddingTop: "0px",
                        marginTop: "0px",
                      }}>
                      Sign Up
                    </Typography>
                    <div
                      style={{
                        margin: "7px 0px 16px",
                        background: "#fff",
                        height: "2.4px",
                        width: "100px",
                      }}></div>

                    <Box sx={{ marginTop: "12px", width: "100%" }}>
                      <FormControl
                        className="email"
                        style={{
                          fontFamily: "Montserrat!important",
                          width: "100%",
                        }}>
                        <TextField
                          style={{
                            color: "#fff",
                            fontFamily: "Montserrat!important",
                            width: "100%",
                          }}
                          label="Email Address"
                          variant="standard"
                          type="email"
                          value={email}
                          onChange={emailValid}
                          className="emailLabel"
                        />
                        <FormHelperText id="eser"></FormHelperText>
                      </FormControl>
                      <FormControl
                        className="pass"
                        sx={{ marginTop: "10px", width: "100%" }}
                        variant="standard">
                        <InputLabel htmlFor="standard-adornment-password">
                          Password
                        </InputLabel>
                        <Input
                          id="standard-adornment-password"
                          type={showPassword ? "text" : "password"}
                          style={{
                            fontFamily: "Montserrat",
                            width: "100%",
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword((show) => !show)}
                                onMouseDown={(e) => e.preventDefault()}
                                edge="end">
                                {showPassword ? (
                                  <VisibilityOff style={{ color: "#fff" }} />
                                ) : (
                                  <Visibility style={{ color: "#fff" }} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Password"
                          value={password}
                          onChange={MyFormHelperText}
                          className="passLabel"
                        />
                        <FormHelperText id="pser"></FormHelperText>
                      </FormControl>
                    </Box>
                  </CardContent>
                  <CardActions>
                    <Box
                      sx={{
                        display: "flex",
                        verticalAlign: "bottom",
                        marginTop: "0px",

                        alignItems: "center",
                      }}>
                      <Radio
                        checked={agree === true}
                        onChange={() => setAgree(!agree)}
                        value={agree}
                        name="radio-buttons"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 28,
                            color: "#fff",
                            marginRight: "20px",
                          },
                        }}
                      />
                      <Typography
                        variant="caption"
                        component="p"
                        style={{ fontFamily: "Montserrat", color: "#fff" }}>
                        I accept{" "}
                        <Link
                          to="/legal"
                          style={{ fontWeight: 700, color: "#fe7200" }}>
                          terms
                        </Link>{" "}
                        and{" "}
                        <Link
                          to="/privacy-policy"
                          style={{ fontWeight: 700, color: "#fe7200" }}>
                          policy
                        </Link>
                      </Typography>
                    </Box>
                  </CardActions>
                </Box>
              </Card>
              <Box className="space-y-8 mt-0.5">
                <FormControl style={{ position: "relative", width: "100%" }}>
                  <Button
                    disabled={
                      password.length < 6 == true ||
                      isValidEmail == false ||
                      agree == false
                    }
                    style={
                      password.length < 6 == true ||
                      isValidEmail == false ||
                      agree == false
                        ? {
                            background: "grey",
                            position: "absolute",
                            top: "-30px",
                            zIndex: 1,
                            display: "none",
                            left: "auto",
                            right: "0px",
                            borderRadius: 0,
                            fontFamily: "Montserrat",
                            fontSize: "18px",
                            textTransform: "none",
                            width: "130px",
                            padding: "10px 10px 10px 30px",
                            borderTopLeftRadius: "20px",
                            borderBottomLeftRadius: "20px",
                          }
                        : {
                            background: "#063851",
                            position: "absolute",
                            color: "#fff",
                            zIndex: 1,
                            top: "15px",
                            left: "auto",
                            right: "0px",
                            borderRadius: 0,
                            fontFamily: "Montserrat",
                            fontSize: "18px",
                            textTransform: "none",
                            width: "130px",
                            padding: "10px 10px 10px 30px",
                            borderTopLeftRadius: "20px",
                            borderBottomLeftRadius: "20px",
                          }
                    }
                    onClick={handleSignUp}
                    variant="contained">
                    Sign Up
                  </Button>
                </FormControl>

                <FormControl style={{ position: "relative", width: "100%" }}>
                  <Button
                    style={{
                      background: "#fe7200",
                      position: "absolute",
                      top: "20px",
                      zIndex: 1,
                      left: "auto",
                      right: "0px",
                      borderRadius: 0,
                      fontFamily: "Montserrat",
                      fontSize: "18px",
                      textTransform: "none",
                      width: "130px",
                      padding: "10px 10px 10px 30px",
                      borderTopLeftRadius: "20px",
                      borderBottomLeftRadius: "20px",
                    }}
                    onClick={handleLogin}
                    variant="contained">
                    Login
                  </Button>
                </FormControl>
              </Box>
            </Grid2>
          </Grid2>
        </Box>
      </Container>
      <Stack>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}>
          <Alert
            id="snckErr"
            onClose={handleClose}
            severity={errType}
            sx={{ width: "100%" }}>
            {errVal}
          </Alert>
        </Snackbar>
      </Stack>
    </main>
  );
};
