import React from "react";
import Carousel from "react-elastic-carousel";
import "./carousel.css";
import { Container, Box, Typography } from "@mui/material";

const breaks = [
  { width: 1, itemsToShow: 1 },
  { width: 576, itemsToShow: 2 },
  { width: 768, itemsToShow: 2 },
  { width: 998, itemsToShow: 2 },
];
const Testimonials = () => {
  const Testimonials = [
    {
      Text: "Using TranSecure Escrow for services like cleaning has been a game-changer. I hired a local cleaning service through the platform, and the process was seamless. TranSecure ensured that my payment was secure until the job was done to my satisfaction",
      Name: "Mahmud Musa",
      Occupation: "Business Owner",
    },
    {
      Text: "TranSecure has made hiring services online a breeze. I needed someone to help with my garden, and TranSecure provided a secure way to pay and ensure quality service. This platform is a gem for hassle-free service transactions",
      Name: "Sarah Adeyemi",
      Occupation: "Health Personnel",
    },
    {
      Text: "Finding reliable home maintenance services online used to be daunting. But with TranSecure, I had no worries when hiring a handyman. I knew my payment was secure until the work was completed to my satisfaction.",
      Name: "Caleb Osasu",
      Occupation: "Student",
    },
    {
      Text: "I am a tech lover, and TranSecure Escrow has made hiring tech repair services stress-free. When my laptop needed fixing, I used TranSecure to ensure my payment was held until I got my device back in working condition",
      Name: "Peters Johnson",
      Occupation: "Engineer",
    },
    {
      Text: "TranSecure Escrow has changed the way I book services online. I recently hired a local cleaning crew, and using TranSecure gave me peace of mind. My payment was secure until the service was completed to my satisfaction",
      Name: "Suzan Adeleke",
      Occupation: "Artist",
    },
    {
      Text: "Booking beauty services through TranSecure Escrow has been fantastic. From haircuts to massages, the platform ensures my payment is secure until Im satisfied with the service. Its a win-win for both clients and service providers",
      Name: "Charles Jerry",
      Occupation: "Business Owner",
    },
  ];
  return (
    <Box className="bg-[#fafafa] py-10 mt-10">
      <Container>
        <Box className=" text-center mt-5">
          <Typography
            variant="h2"
            sx={{
              fontSize: "38px",
              fontFamily: "Montserrat Bold",
              color: "#063851",
              "@media (max-width: 640px)": {
                fontSize: "32px",
              },
            }}>
            See Our Users{" "}
            <span style={{ color: "#fe7200", fontFamily: "Montserrat Bold" }}>
              Reviews
            </span>
          </Typography>
          <h3 class="text-sm  mt-1.5  opacity-80 mx-auto leading-1.5 sm:w-full w-72">
            TranSecure Escrow has gained popularity amongst users. See what
            users have to say.
          </h3>
        </Box>
        <Box className="mt-10">
          <Carousel
            breakPoints={breaks}
            enableAutoPlay
            autoPlaySpeed={10500}
            className="p-2">
            {Testimonials.map((item, i) => (
              <div
                class="bg-[#063851] p-6 rounded-lg shadow mx-2 mb-2 "
                key={i}>
                <p class="text-white mb-2 text-[15px]">"{item.Text}"</p>
                <p class="font-semibold text-[#fafafa] mt-4">{item.Name}</p>
                <p class=" text-[#fe7200] text-sm font-bold">
                  {item.Occupation}
                </p>
              </div>
            ))}
          </Carousel>
        </Box>
      </Container>
    </Box>
  );
};

export default Testimonials;
