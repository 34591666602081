import {
  Box,
  Stack,
  Grow,
  Popper,
  MenuList,
  Typography,
  Icon,
  ListItem,
  ListItemButton,
  ListItemText,
  Autocomplete,
  List,
  TextField,
  Container,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { ArrowRight, ArrowRightAlt } from "@mui/icons-material";
import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
export const LandingFooter = () => {
  useEffect(() => {
    // Your Google Translate widget initialization code
    window.gtranslateSettings = {
      default_language: "en",
      languages: [
        "en",
        "fr",
        "de",
        "es",
        "fi",
        "nl",
        "hi",
        "da",
        "cs",
        "el",
        "ko",
        "hr",
        "zh-TW",
        "ja",
        "ru",
        "it",
        "ha",
      ],
      wrapper_selector: ".gtranslate_wrapper",
    };

    // Load the Google Translate widget script
    const script = document.createElement("script");
    script.src = "https://cdn.gtranslate.net/widgets/latest/float.js";
    script.defer = true;
    document.body.appendChild(script);
  }, []);
  const [navIsOpen, setNavIsOpen] = useState(false);
  const [langLabel, setLangLabel] = useState("Language");
  const [locale, setLocale] = useState("enUS");
  const [localeT, setLocaleT] = useState("English");
  const langOptions = [
    {
      title: "English",
      flag: "US",
      code: "enUS",
    },
    {
      title: "Spanish",
      flag: "ES",
      code: "esES",
    },
  ];

  const handleLang = (e, newval) => {
    setLocale(newval.code);
    setLocaleT(newval);
    document.querySelector("footer label").innerHTML =
      "<img src='https://flagcdn.com/w20/" +
      newval.flag.toLowerCase() +
      ".png'/>";
  };
  return (
    <footer
      style={{ padding: "44px 0px", background: "#063851" }}
      className="flex flex-col align-center">
      <Container>
        <div className="gtranslate_wrapper">{/* Your content goes here */}</div>
        <Grid2 container sx={{}}>
          <Grid2 xs={12}>
            <div className="footerBottom"></div>
            <div style={{ marginTop: "40px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "24px",
                }}>
                <img
                  src="/images/logo192.png"
                  style={{ width: "90px", height: "100%" }}
                />
              </div>
              <div>
                <ul
                  className={window.innerWidth >= 600 && "flex"}
                  style={{
                    justifyContent: "center",
                    marginBottom: "20px",
                  }}>
                  {[
                    { title: "Privacy Policy", link: "/policy" },
                    { title: "Licences and Complaints", link: "/license" },
                    { title: "Legal", link: "/legal" },
                  ].map((page, idx) => (
                    <li
                      style={
                        window.innerWidth <= 599
                          ? {
                              padding: "16px 0",
                              fontFamilY: "Montserrat",
                              fontSize: "14px",
                              fontWeight: 400,
                              textTransform: "uppercase",
                            }
                          : {
                              padding: "0 24px",
                              borderRight: idx != 2 ? "2px solid #fff" : "none",
                              fontFamilY: "Montserrat",
                              fontSize: "14px",
                              fontWeight: 400,
                              textTransform: "uppercase",
                            }
                      }>
                      <Link
                        to={page.link}
                        style={{ color: "white", whiteSpace: "nowrap" }}>
                        {page.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div
                style={
                  window.innerWidth <= 599
                    ? {
                        display: "flex",
                        marginBottom: "44px",
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                        color: "#fff",
                        textAlign: "left",
                      }
                    : {
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "44px",
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                        color: "#fff",
                        textAlign: "center",
                      }
                }>
                <Typography variant="caption" component="p">
                  <Link to="#" style={{ color: "#fff", marginRight: 5 }}>
                    Copyright
                  </Link>
                  <span>
                    © 2023 TranSecure Escrow, Inc. All rights reserved
                    <br />A company of Freelancer Limited
                  </span>
                </Typography>
              </div>
            </div>
          </Grid2>
        </Grid2>
      </Container>
    </footer>
  );
};
