import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonLink,
  DashboardHeader,
  FlexRow,
  Input,
} from "../../components";
import { useUser } from "../../hooks";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LandingHeader } from "../../components/Landing";
import styles from "./Index.module.css";
import {
  Alert,
  Box,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import { ArrowBack, ExitToAppTwoTone } from "@mui/icons-material";

import img from "../../assets/verified.svg";
import failedimg from "../../assets/failed.png";
import axios from "axios";
import { LoadingButton } from "@mui/lab";

export const VerifyLink = () => {
  const [dAgent, setDAgent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { userId, uniqueString } = useParams();

  const sendVerifyEmail = async () => {
    setLoading(true);
    await axios
      .get(
        `https://transecure.onrender.com/api/v1/users/verifyUserEmail/${userId}/${uniqueString}`
      )
      .then((res) => {
        setLoading(false);
        setSuccess(true);
        setError(false);
        localStorage.setItem("agent", JSON.stringify(res.data.data));
        localStorage.setItem("token", JSON.stringify(res.data.token));

        setTimeout(() => {
          navigate("/profile");
        }, 3000);
      })
      .catch((err) => {
        setLoading(false);
        setSuccess(false);
        setError(true);
        console.log(err);
      });
  };

  useEffect(() => {
    sendVerifyEmail();
  }, []);

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: "#f5f7f9",
        width: "100vw",
        minHeight: "100vh",
      }}>
      {dAgent?._id === undefined ? <LandingHeader /> : <DashboardHeader />}

      <Box className="py-10 bg-blue-100">
        <Box className={`${styles.container} flex items-center space-x-3`}>
          <Link to="/profile">
            <ArrowBack className="text-[25px]" />
          </Link>
          <Typography
            sx={{ fontWeight: "bold", fontSize: "25px", opacity: "0.7" }}>
            VERIFY ACCOUNT
          </Typography>
        </Box>
      </Box>

      <Box className={styles.container}>
        <Box className="shadow-md text-center mb-5 rounded-md px-16 py-20 mt-10 sm:w-[50%]  w-full m-auto">
          <Typography sx={{ fontSize: "20px", margin: "3% 0%" }}>
            <i>
              {loading
                ? "Verifying Link..."
                : !loading && success
                ? "Verification Completed"
                : "Verification Failed"}
            </i>
          </Typography>
          <Box className="mt-5">
            {!loading && success === true ? (
              <>
                <Alert onClose={() => {}}>
                  {" "}
                  Email Verification Successfull <span>{dAgent.email}</span>
                </Alert>

                <Box className="flex flex-col items-center justify-center my-5">
                  <img alt="sent" src={img} className="sm:w-[25%] w-[50%]" />
                </Box>
              </>
            ) : !loading && error === true ? (
              <>
                <Alert severity="error" onClose={() => {}}>
                  {" "}
                  Unable to verify your Link, please click the button to restart
                  the process again <span>{dAgent.email}</span>
                </Alert>
                <Box className="flex flex-col items-center justify-center my-5">
                  <img alt="sent" src={failedimg} width="150px" />
                </Box>
                <Link to="/verify">
                  <LoadingButton
                    size="small"
                    color="success"
                    endIcon={<ArrowBack />}
                    variant="contained"
                    style={{
                      backgroundColor: "#fe7200",
                      color: "#fff",
                      fontFamily: "Montserrat",
                      padding: "10px  24px ",
                      height: 55,
                      fontWeight: 700,
                      width: "100%",
                      margin: "auto",
                      "@media (min-width: 567px)": {
                        width: "100%",
                      },
                    }}
                    onMouseOver={(e) =>
                      (e.target.style.backgroundColor = "#063851")
                    }
                    onMouseOut={(e) =>
                      (e.target.style.backgroundColor = "#fe7200")
                    }>
                    Back
                  </LoadingButton>
                </Link>
              </>
            ) : (
              ""
            )}
          </Box>

          {loading && (
            <CircularProgress
              size={54}
              sx={{ color: "#fe7200" }}
              className="mt-10"
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
