import {
  Box,
  ClickAwayListener,
  MenuItem,
  Paper,
  Stack,
  Grow,
  Popper,
  MenuList,
  Typography,
  Icon,
  Container,
} from "@mui/material";
import { ArrowRight, ArrowRightAlt } from "@mui/icons-material";
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./Index.module.css";
export const LandingHeader = () => {
  const [navIsOpen, setNavIsOpen] = useState(false);
  return (
    <header
      style={{
        padding: "10px 0",
        marginBottom: 152,
        margin: "0 auto",
        justifyContent: "space-between",
        background: "#063851",
        // zIndex:"1",
        position: "relative",
      }}
      className="">
      <Container
        sx={{ display: "flex" }}
        className="flex justify-between align-center">
        <Link to="/" style={{ marginRight: 30 }}>
          <img src="/images/logo192.png" width="65px" />
        </Link>
        {/* harmburger menu
         */}
        <div
          onClick={() => setNavIsOpen(!navIsOpen)}
          className={`pointer ${styles.desktopHide}`}
          style={{ rowGap: 4, flexDirection: "column" }}>
          {[1, 2, 3].map((item, idx) => (
            <Box
              key={idx}
              style={{ height: "2px", backgroundColor: "#fff", width: "25px" }}
            />
          ))}
        </div>
        <Stack
          sx={{
            position: "absolute",
            right: 5,
            top: 80,
            backgroundColor: "#fff",
            paddingBlock: 2,
            width: "150px",
            borderRadius: 2,
            display: navIsOpen ? "block" : "none",
            zIndex: 2,
          }}>
          {[
            { title: "About Us", link: "/about-us" },
            { title: "Contact Us", link: "/contact-us" },
            { title: "Login", link: "/sign-in" },
            { title: "Sign Up", link: "/sign-up" },
          ].map((page, idx) => (
            <Link
              key={idx}
              to={page.link}
              style={{
                padding: "5px 20px",
                display: "block",
              }}>
              <Typography sx={{ color: "#000" }}>{page.title}</Typography>
            </Link>
          ))}
        </Stack>

        <div className={styles.mobileHide} style={{}}>
          {/* <Link
            to="/services"
            style={{
              marginRight: "5vw",
              color: "#fff",
              fontFamily: "Montserrat",
            }}>
            Services
          </Link> */}
          <Link
            to="/about-us"
            style={{
              marginRight: "5vw",
              color: "#fff",
              fontFamily: "Montserrat",
            }}>
            About us
          </Link>
          <Link
            to="/contact-us"
            style={{
              marginRight: "5vw",
              color: "#fff",
              fontFamily: "Montserrat",
            }}>
            Contact us
          </Link>
        </div>

        <div
          className={styles.mobileHide}
          style={{ marginLeft: "auto", marginRight: 0 }}>
          <Link
            to="/sign-in"
            style={{
              padding: "5px 20px",
              border: "1px solid #fe7200",
              height: "fit-content",
              color: "#FFF",
              fontFamily: "Montserrat",
              backgroundColor: "#fe7200",
              marginRight: 20,
              position: "relative",
              zIndex: 9,
            }}>
            Login
          </Link>
          <Link
            to="/sign-up"
            style={{
              color: "#fff",
              fontFamily: "Montserrat",
              position: "relative",
              zIndex: 9,
            }}>
            Get Started
            <span>
              <ArrowRightAlt
                style={{ marginLeft: 5, verticalAlign: "bottom" }}
              />
            </span>
          </Link>
        </div>
      </Container>
    </header>
  );
};
