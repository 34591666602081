import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  CardContent,
  TextField,
  Button,
  CircularProgress,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import { ExitToAppTwoTone } from "@mui/icons-material";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object().shape({
  Firstname: Yup.string(),
  Lastname: Yup.string(),
  Gender: Yup.string(),
  PrimaryPhone: Yup.string(),
  AlternatePhone: Yup.string(),
  Address: Yup.string(),
  BankAccount: Yup.string(),
  BankName: Yup.string(),
});

const ProfileForm = () => {
  const [loading, setLoading] = useState(false);
  const [dAgent, setDAgent] = useState([]);
  const [token, setToken] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const handleCloseSuccess = () => {
    navigate("/profile");
  };

  useEffect(() => {
    const ditems = JSON.parse(localStorage.getItem("agent"));
    const token = localStorage.getItem("token");
    setToken(token);
    setDAgent(ditems);
  }, []);

  const details = [
    {
      title: "Firstname",
      value: dAgent?.firstName,
    },
    {
      title: "Lastname",
      value: dAgent?.lastName,
    },
    {
      title: "PrimaryPhone",
      value: dAgent?.phone,
    },
    {
      title: "AlternatePhone",
      value: dAgent?.altPhone,
    },
    {
      title: "Address",
      value: dAgent?.billingAddress,
    },
    {
      title: "BankAccount",
      value: dAgent?.bankAccount,
    },
    {
      title: "BankName",
      value: dAgent?.bankName,
    },
  ];

  const formik = useFormik({
    initialValues: {
      Firstname: dAgent?.firstName || "",
      Lastname: dAgent?.lastName ? dAgent?.lastName : "",
      PrimaryPhone: dAgent?.phone ? dAgent?.phone : "",
      AlternatePhone: dAgent?.altPhone ? dAgent?.altPhone : "",
      Address: dAgent?.billingAddress ? dAgent?.billingAddress : "",
      BankAccount: dAgent?.bankAccount ? dAgent?.bankAccount : "",
      BankName: dAgent?.bankName ? dAgent?.bankName : "",
      Gender: dAgent?.gender ? dAgent?.gender : "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      await axios
        .put(
          `https://transecure.onrender.com/api/v1/users/user/${dAgent._id}`,
          values,
          {
            headers: headers,
          }
        )
        .then((res) => {
          setLoading(false);
          setError(false);
          setSuccess(true);
          localStorage.setItem("agent", JSON.stringify(res.data.data));
          localStorage.setItem("token", res.data.token);
        })
        .catch((err) => {
          console.log(err);
          setError(true);
          setSuccess(false);
          setLoading(false);
        });
    },
  });

  return (
    <Box className="my-8 sm:w-[60%] w-full m-auto">
      <Card className="shadow-md">
        <CardContent>
          <h2 className="text-xl font-bold mb-4">Update Profile</h2>
          <form onSubmit={formik.handleSubmit} className="space-y-6">
            {details.map((detail, index) => (
              <TextField
                key={index}
                label={detail.title}
                fullWidth
                focused
                variant="outlined"
                className="mb-4"
                name={detail.title}
                placeholder={detail.value || "Not Set"}
                value={formik.values[detail.title]}
                onChange={formik.handleChange}
                error={
                  formik.touched[detail.title] &&
                  Boolean(formik.errors[detail.title])
                }
                helperText={
                  formik.touched[detail.title] && formik.errors[detail.title]
                }
              />
            ))}

            <FormControl fullWidth>
              <InputLabel id="Gender">Gender</InputLabel>
              <Select
                labelId="Gender"
                id="Gender"
                label="Gender"
                size="medium"
                sx={{ fontSize: "13px", width: "100%" }}
                value={formik.values["Gender"]}
                onChange={(e) => {
                  formik.setFieldValue("Gender", e.target.value);
                }}
                onBlur={(e) => {
                  formik.setFieldValue("Gender", e.target.value);
                }}>
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female"> Female</MenuItem>
              </Select>
              {formik.errors["Gender"] && (
                <Typography sx={{ fontSize: "10px", color: "red" }}>
                  {formik.errors["Gender"]}
                </Typography>
              )}
            </FormControl>

            <Snackbar
              open={success}
              autoHideDuration={6000}
              onClose={handleCloseSuccess}>
              <Alert
                onClose={handleCloseSuccess}
                severity="success"
                sx={{ width: "100%" }}>
                Your profile has been updated successfully
              </Alert>
            </Snackbar>

            <Snackbar
              open={error}
              autoHideDuration={6000}
              onClose={() => setError(false)}>
              <Alert
                onClose={() => setError(false)}
                severity="success"
                sx={{ width: "100%" }}>
                Error updating your profile, please try again
              </Alert>
            </Snackbar>

            <LoadingButton
              size="small"
              disabled={loading}
              type="submit"
              color="success"
              endIcon={<ExitToAppTwoTone />}
              variant="contained"
              style={{
                backgroundColor: "#fe7200",
                color: "#fff",
                fontFamily: "Montserrat",
                padding: "10px  24px ",
                height: 45,
                fontWeight: 700,
              }}
              onMouseOver={(e) => (e.target.style.backgroundColor = "#063851")}
              onMouseOut={(e) => (e.target.style.backgroundColor = "#fe7200")}>
              {loading ? <CircularProgress size={24} /> : "Update Profile"}
            </LoadingButton>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ProfileForm;
