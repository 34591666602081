import React, { useMemo, useState, useRef, useEffect } from "react";
import {
  Box,
  Container,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Button,
  Snackbar,
  Card,
  CardContent,
  Typography,
  Link,
  FormHelperText,
  IconButton,
  CardActions,
  Divider,
  Switch,
  FormControlLabel,
  Backdrop,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Edit, ContentCopy, WhatsApp } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { Context, AgentContext } from "../../contexts";
import MuiAlert from "@mui/material/Alert";
import {
  DashboardHeader,
  FeeCalcModal,
  StartHeader,
  VerifyEmailAlert,
  WelcomeModal,
} from "../../components";
import QRCode from "react-qr-code";
import { useContext } from "react";
import axios from "axios";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const isEmail = (email) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
export const StartTransaction = () => {
  const urlRef = useRef(null);
  const { agent, setAgent } = useContext(AgentContext);
  const search = useLocation().search;
  const serv = new URLSearchParams(search).get("s");
  const amount = new URLSearchParams(search).get("a");
  let [IsValid, setIsValid] = useState(false);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [startTransaction, SetStartTransaction] = useState(false);
  const [startContinue, setStartContinue] = useState(false);
  const [transactionCreated, setTransactionCreated] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [checkedTerms, setCheckedTerms] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editIsOpen, setEditIsOpen] = useState(false);
  const [fopen, setFOpen] = useState(false);
  const [qrValue, setQrValue] = useState("");
  const [QrUrl, setQrUrl] = useState("");
  const [welcomeOpen, setWelcomeOpen] = useState(true);
  const [open, setOpen] = useState(false);
  const [opensel, setOpensel] = useState(false);
  const [errVal, setErrVal] = useState("");
  const [errType, setErrType] = useState("");
  const [verify, setVerify] = useState("");
  const [role, setRole] = useState("");
  const [role2, setRole2] = useState("");
  const [email, setEmail] = useState("");
  const [beneficiaryEmail, setBeneficiaryEmail] = useState("");
  const [beneficiaryPhone, setBeneficiaryPhone] = useState("");
  const [dTitle, setDTitle] = useState("");
  const [dPeriod, setDPeriod] = useState(0);
  const [itemId, setItemId] = useState("");
  const [itemKey, setItemKey] = useState("");
  const [itemIndex, setItemIndex] = useState("");
  const [itemName, setItemName] = useState("");
  const [itemPrice, setItemPrice] = useState(0);
  const [itemCat, setItemCat] = useState("");
  const [itemDesc, setItemDesc] = useState("");
  const [itemPeriod, setItemPeriod] = useState("");
  const [miles, setMiles] = useState(false);
  const [items, setItems] = useState([]);
  const [cart, setCart] = useState(false);
  const [ncur, setNCur] = useState("");
  const [scur, setSCur] = useState("");
  const [buyerFee, setBuyerFee] = useState("");
  const [sellerFee, setSellerFee] = useState("");
  const [subtotal, setSubtotal] = useState("");
  const [fsubtotal, setFSubtotal] = useState("");
  const [adminfee, setAdminfee] = useState("");
  const [escrowfee, setEscrowfee] = useState("");
  const [shippingfee, setShippingfee] = useState("0.00");
  const [adper, setAdper] = useState("0.0325");
  const [adminCharge, setAdminCharge] = useState("buyer");
  const [shippingCharge, setShippingCharge] = useState("both");
  const [sfee, setSfee] = useState("");
  const [bfee, setBfee] = useState("");
  const [eqfee, setEqfee] = useState("");
  const [currency, setCurrency] = useState([
    {
      name: "NGN",
      symbol: "₦",
    },
  ]);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("agent"));
    const storedToken = localStorage.getItem("token");

    setUserId(storedUser._id);
  }, []);

  let curname = "";
  const navigate = useNavigate();
  const raw = {
    name: itemName,
    price: itemPrice,
    category: itemCat,
    description: itemDesc,
    period: itemPeriod,
  };
  const timedOut = 2000;
  const handleClose = () => {
    setOpen(false);
  };
  const handleCurrency = (e) => {
    let currtitle = "#" + e.target.value;
    let currcollection = document
      .querySelector(currtitle)
      .getAttribute("title");
    setSCur(currcollection);
    setNCur(e.target.value);
    if (e.target.value == "") {
      document.querySelector("#currency-select-helper").style.display = "block";
    } else {
      document.querySelector("#currency-select-helper").style.display = "none";
    }
  };
  const handlePrice = (e) => {
    let iprice = e.target.value;
    setItemPrice(iprice);
    if (e.target.value <= 0.0) {
      document.querySelector("#item-price-helper-text").style.display = "block";
    } else {
      document.querySelector("#item-price-helper-text").style.display = "none";
    }
  };
  const handlePriceBlur = async () => {
    let price = await itemPrice;
    let iprice = parseFloat(price).toFixed(2);
    setItemPrice(iprice);
  };
  const handleMiles = (e) => {
    setMiles(!miles);
  };
  const handleAddItem = () => {
    let adfee;
    let scharge;
    let bcharge;
    let msgTotal;
    let totalPeriod;
    let fmsgTotal;
    setCart(true);
    items.push(raw);

    if (items.length > 1) {
      msgTotal = items.reduce((a, n) => a + Number(n.price), 0);
    } else {
      msgTotal = raw.price;
    }
    if (miles == true && items.length > 1) {
      totalPeriod = items.reduce((a, n) => a + Number(n.period), 0);
    }
    if (miles == true && items.length <= 1) {
      totalPeriod = raw.period;
    }
    if (miles == false && items.length <= 1) {
      raw.period = dPeriod;
      totalPeriod = raw.period;
    }
    adfee = adper * msgTotal;
    setAdminfee(adfee.toFixed(2));
    setEscrowfee(adfee.toFixed(2));
    setAdminCharge(role);
    setSubtotal(msgTotal);
    setDPeriod(totalPeriod);
    fmsgTotal = parseFloat(msgTotal).toFixed(2);
    bcharge = Number(msgTotal) + Number(adfee);
    bcharge = parseFloat(bcharge).toFixed(2);
    setFSubtotal(fmsgTotal);
    setBfee(bcharge);
    setSfee(fmsgTotal);
    setItemName("");
    setItemCat("");
    setItemDesc("");
    setItemPeriod("");
    setItemPrice(0);
  };
  const handleCharge = (e) => {
    let dfee;
    let tfee;
    let ofee;
    let charge = e.target.value;
    if (charge == "buyer") {
      dfee = adper * subtotal;
      tfee = Number(dfee) + Number(subtotal);
      ofee = parseFloat(subtotal);

      setAdminfee(dfee.toFixed(2));
      setBfee(tfee.toFixed(2));
      setSfee(ofee.toFixed(2));
    }
    if (charge == "seller") {
      dfee = adper * subtotal;
      tfee = Number(subtotal) - Number(dfee);
      ofee = parseFloat(subtotal);

      setAdminfee(dfee.toFixed(2));
      setBfee(ofee.toFixed(2));
      setSfee(tfee.toFixed(2));
    }
    if (charge == "both") {
      dfee = (adper * subtotal) / 2;
      tfee = Number(subtotal) + Number(dfee);

      setAdminfee(dfee.toFixed(2));
      setBfee(tfee.toFixed(2));
      setSfee(tfee.toFixed(2));
    }
    setAdminCharge(e.target.value);
  };
  const handleAnotherItem = () => {
    setCart(false);
  };
  const handleEditOpen = () => {
    setEditIsOpen(true);
    setItemKey(itemIndex);
    let ditem = items[itemIndex];
    setCart(false);
    setItemName(ditem["name"]);
    setItemCat(ditem["category"]);
    setItemDesc(ditem["description"]);
    setItemPeriod(ditem["period"]);
    setItemPrice(ditem["price"]);
  };
  const handleEditClose = () => {
    let uitem = items[itemKey];
    let adfee;
    let bcharge;
    let msgTotal;
    let fmsgTotal;
    let totalPeriod;
    uitem.name = itemName;
    uitem.category = itemCat;
    uitem.description = itemDesc;
    uitem.period = itemPeriod;
    uitem.price = itemPrice;
    if (items.length > 1) {
      msgTotal = items.reduce((a, n) => a + Number(n.price), 0);
    } else {
      msgTotal = raw.price;
    }
    if (miles == true && items.length > 1) {
      totalPeriod = items.reduce((a, n) => a + Number(n.period), 0);
    }
    if (miles == true && items.length <= 1) {
      totalPeriod = raw.period;
    }
    adfee = adper * msgTotal;
    setAdminfee(adfee.toFixed(2));
    setSubtotal(msgTotal);
    setDPeriod(totalPeriod);
    fmsgTotal = parseFloat(msgTotal).toFixed(2);
    bcharge = Number(msgTotal) + Number(adfee);
    bcharge = parseFloat(bcharge).toFixed(2);
    setFSubtotal(fmsgTotal);
    setBfee(bcharge);
    setSfee(fmsgTotal);
    setItemName("");
    setItemCat("");
    setItemDesc("");
    setItemPeriod("");
    setItemPrice(0);
    setEditIsOpen(false);
    setCart(true);
  };
  const handleQrValue = async (itx) => {
    let idNumber = itx;
    let qtx = "https://transecureescrow.vercel.app/sign-in?idNo=" + idNumber;

    setItemId(idNumber);
    setQrValue(qtx);
    return qtx;
  };
  const handleQrUrl = async () => {
    const svg = document.querySelector("#qrcode").innerHTML;
    const blob = new Blob([svg], { type: "image/svg+xml;charset=utf-8" });
    const doURL = window.URL || window.webkitURL || window;
    const svgurl = URL.createObjectURL(blob);
    const image = document.querySelector("#qrImg");
    image.src = svgurl;
    const myFile = new File([blob], "qrcode.jpeg", {
      type: blob.type,
    });
    setQrUrl(svgurl);
  };
  const handleTransaction = async (e) => {
    SetStartTransaction(true);
    const bdy = {
      title: dTitle,
      userId: userId,
      amount: subtotal,
      escrowFee: escrowfee,
      shippingFee: shippingfee,
      escrowCharge: adminCharge,
      shippingCharge: shippingCharge,
      currency: ncur,
      senderEmail: email,
      senderRole: role,
      beneficiaryEmail: beneficiaryEmail,
      beneficiaryPhone: beneficiaryPhone,
      beneficiaryRole: role2,
      inspectionPeriod: dPeriod,
      milestone: miles,
      action: "beneficiary",
      items: items,
    };

    if (
      !dTitle ||
      !subtotal ||
      !escrowfee ||
      !shippingfee ||
      !adminCharge ||
      !shippingCharge ||
      !ncur ||
      !email ||
      !beneficiaryEmail ||
      !beneficiaryPhone ||
      !dPeriod
    ) {
      setOpen(true);
      setErrType("error");
      setErrVal("All Fields Are Required");
      return;
    }

    const token = localStorage.getItem("token");
    const headers = { Authorization: "Bearer " + token };
    const resp = await axios
      .post("https://transecure.onrender.com/api/v1/transactions/start", bdy, {
        headers,
      })
      .then((res) => {
        const myId = res?.data;

        return myId;
      })
      .catch((err) => {
        setOpen(true);
        setErrType("error");
        setErrVal(`${err?.response?.data?.error?.message}`);
        return;
      });
    let itx = resp?.data;
    let dvx;
    let uvx;

    if (itx !== "" && itx !== undefined && itx !== null) {
      dvx = await handleQrValue(itx);
      setTimeout(() => {
        SetStartTransaction(false);
        setStartContinue(true);
      }, 3000);
    }
  };
  const handleContinue = async () => {
    setStartContinue(false);
    SetStartTransaction(true);
    let uvx;
    uvx = await handleQrUrl();

    setTimeout(() => {
      setTransactionCreated(true);
    }, 5000);
  };
  const handleCopy = (e) => {
    navigator.clipboard.writeText(qrValue);
    // urlRef.current.select();
    // document.execCommand('copy');
    // e.target.focus();
    setOpen(true);
    setErrType("success");
    setErrVal("URL copied successfully!");
  };
  useEffect(() => {
    setEmail(agent?.email);
    setVerify(agent?.emailVerification);
    if (serv == "buy") {
      setItemPrice(amount);
      setRole("buyer");
      setRole2("seller");
    }
    if (serv == "sell") {
      setItemPrice(amount);
      setRole("seller");
      setRole2("buyer");
    }
  }, [qrValue]);
  return (
    <main
      style={{
        backgroundColor: "#fff",
        width: "100vw",
        minHeight: "100vh",
      }}>
      <DashboardHeader />

      {transactionCreated == false ? (
        <Container
          style={{
            backgroundColor: "#fff",
            border: "1px solid #e8eef2",
            borderBottom: "none",
          }}
          maxWidth="xl">
          <Box>
            <Grid2 container>
              <Grid2 md={12} className="cardGrid">
                <Card
                  className="gridcard"
                  sx={
                    window.innerWidth <= 899
                      ? { minWidth: 275, padding: "24px 10px" }
                      : {
                          maxWidth: 920,
                          padding: "40px 80px",
                          margin: "40px auto",
                        }
                  }>
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { marginTop: "8px" },
                    }}
                    noValidate
                    autoComplete="off">
                    <CardContent>
                      <Typography
                        variant="h5"
                        component="h5"
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: 700,
                          color: "#1d284a",
                          marginBottom: "16px",
                        }}>
                        Start Transaction
                      </Typography>
                      <Divider />

                      <Box
                        sx={{ marginTop: "32px", width: "100%" }}
                        autoComplete="off">
                        <FormControl
                          style={{
                            fontFamily: "Montserrat!important",
                            width: "100%",
                          }}>
                          <TextField
                            style={{
                              backgroundColor: "#fff",
                              fontFamily: "Montserrat!important",
                              width: "100%",
                              color: "#063851!important",
                            }}
                            label="Transaction Title"
                            variant="outlined"
                            value={dTitle}
                            onChange={(e) => {
                              setDTitle(e.target.value);
                              if (e.target.value == "") {
                                document.querySelector(
                                  "#dTitle-Helper"
                                ).style.display = "block";
                              } else {
                                document.querySelector(
                                  "#dTitle-Helper"
                                ).style.display = "none";
                              }
                            }}
                            autoComplete="off"
                            color={dTitle == "" && "error"}
                          />
                          <FormHelperText
                            style={{
                              color: "red",
                              display: "none",
                              fontFamily: "Montserrat",
                            }}
                            id="dTitle-Helper">
                            Required
                          </FormHelperText>
                        </FormControl>
                      </Box>
                      <Box
                        sx={
                          window.innerWidth <= 899
                            ? { width: "100%", display: "block" }
                            : {
                                width: "100%",
                                justifyContent: "space-between",
                                display: "flex",
                              }
                        }
                        autoComplete="off">
                        <FormControl
                          style={
                            window.innerWidth <= 899
                              ? {
                                  fontFamily: "Montserrat!important",
                                  width: "100%",
                                  marginTop: "25px",
                                }
                              : {
                                  fontFamily: "Montserrat!important",
                                  width: "30%",
                                  marginTop: "25px",
                                }
                          }>
                          <InputLabel
                            id="my-role-select-label"
                            style={{ fontFamily: "Montserrat" }}>
                            My Role
                          </InputLabel>
                          <Select
                            labelId="my-role-select-label"
                            id="my-role-select"
                            value={role}
                            label="My Role"
                            onChange={(e) => {
                              setRole(e.target.value);
                              if (e.target.value == "") {
                                document.querySelector(
                                  "#my-role-select-helper"
                                ).style.display = "block";
                              } else {
                                document.querySelector(
                                  "#my-role-select-helper"
                                ).style.display = "none";
                              }
                              if (e.target.value == "buyer") {
                                setRole2("seller");
                              } else {
                                setRole2("buyer");
                              }
                            }}
                            color={role == "" && "error"}
                            style={{ fontFamily: "Montserrat" }}>
                            <MenuItem value="buyer">Buyer</MenuItem>
                            <MenuItem value="seller">Seller</MenuItem>
                          </Select>
                          <FormHelperText
                            style={{
                              color: "red",
                              display: "none",
                              fontFamily: "Montserrat",
                            }}
                            id="my-role-select-helper">
                            Required
                          </FormHelperText>
                        </FormControl>
                        <FormControl
                          style={
                            window.innerWidth <= 899
                              ? {
                                  fontFamily: "Montserrat!important",
                                  width: "100%",
                                  marginTop: "25px",
                                }
                              : {
                                  fontFamily: "Montserrat!important",
                                  width: "30%",
                                  marginTop: "25px",
                                }
                          }>
                          <InputLabel id="currency-select-label">
                            Currency
                          </InputLabel>
                          <Select
                            labelId="currency-select-label"
                            className="currency-select"
                            value={ncur}
                            label="Currency"
                            onChange={handleCurrency}
                            color={currency == "" && "error"}
                            style={{ fontFamily: "Montserrat" }}>
                            {currency.map((curr, icx) => (
                              <MenuItem
                                id={curr.name}
                                key={icx}
                                value={curr.name}
                                title={curr.symbol}>
                                {curr.name}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText
                            style={{
                              color: "red",
                              display: "none",
                              fontFamily: "Montserrat",
                            }}
                            id="currency-select-helper">
                            Required
                          </FormHelperText>
                        </FormControl>
                        <FormControl
                          style={
                            window.innerWidth <= 899
                              ? {
                                  fontFamily: "Montserrat!important",
                                  width: "100%",
                                  marginTop: "25px",
                                }
                              : {
                                  fontFamily: "Montserrat!important",
                                  width: "30%",
                                  marginTop: "25px",
                                }
                          }>
                          <TextField
                            style={{
                              backgroundColor: "#fff",
                              marginTop: 0,
                              fontFamily: "Montserrat!important",
                            }}
                            type="number"
                            label="Inspection period (days)"
                            variant="outlined"
                            value={dPeriod}
                            onChange={(e) => {
                              setDPeriod(e.target.value);
                              if (e.target.value == "") {
                                document.querySelector(
                                  "#period-helper-text"
                                ).style.display = "block";
                                setDPeriod(0);
                              } else {
                                document.querySelector(
                                  "#period-helper-text"
                                ).style.display = "none";
                              }
                            }}
                            disabled={miles == true && "disabled"}
                            autoComplete="off"
                            color={dPeriod == "" && "error"}
                            onMouseOver={() =>
                              (document.querySelector(
                                "#period-helper-text"
                              ).style.display = "block")
                            }
                            onMouseOut={() => {
                              if (dPeriod !== "") {
                                document.querySelector(
                                  "#period-helper-text"
                                ).style.display = "none";
                              }
                            }}
                          />
                          <FormHelperText
                            id="period-helper-text"
                            style={{
                              display: "none",
                              fontFamily: "Montserrat",
                            }}>
                            This is the allowed time for a Buyer to inspect the
                            items before funds are automatically released.
                          </FormHelperText>
                        </FormControl>
                      </Box>
                      <Box sx={{ width: "100%" }}>
                        <Typography
                          variant="h6"
                          component="h6"
                          style={{
                            fontFamily: "Montserrat",
                            marginTop: "30px",
                            marginBottom: "15px",
                            fontWeight: 700,
                            fontSize: "16px",
                          }}>
                          Transaction details
                        </Typography>
                        {items.length >= 1 && (
                          <Box container sx={{ padding: "15px 0px" }}>
                            {items.map((item, iex) => (
                              <Box
                                className="eachitem"
                                key={iex}
                                sx={{
                                  border: "1px solid rgba(0,0,0,.2)",
                                  borderRadius: "6px",
                                  lineHeight: "25px",
                                  fontSize: "16px",
                                  color: "rgba(0,0,0,.2)",
                                  marginBottom: "30px",
                                  cursor: "pointer",
                                }}
                                onMouseOver={(e) => {
                                  setEditOpen(true);
                                  setItemIndex(iex);
                                }}
                                onMouseLeave={(e) => {
                                  setEditOpen(false);
                                  setItemIndex(iex);
                                }}>
                                <div
                                  style={{
                                    position: "relative",
                                    padding: "20px 20px",
                                  }}>
                                  <Backdrop
                                    sx={{
                                      color: "#fff",
                                      zIndex: (theme) =>
                                        theme.zIndex.drawer + 1,
                                      position: "absolute",
                                      borderRadius: "6px",
                                    }}
                                    open={editOpen && itemIndex == iex}
                                    onClick={handleEditOpen}>
                                    <div>
                                      <Edit
                                        color="inherit"
                                        sx={{ fontSize: "40px" }}
                                      />
                                    </div>
                                    <div>
                                      <Typography
                                        variant="h5"
                                        component="h5"
                                        sx={{
                                          color: "inherit",
                                          fontSize: "20px",
                                          fontFamily: "Montserrat",
                                          fontWeight: 700,
                                        }}>
                                        Edit item
                                      </Typography>
                                    </div>
                                  </Backdrop>
                                  <div
                                    className="boxHeader"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}>
                                    <Typography
                                      variant="h5"
                                      component="h5"
                                      sx={{
                                        color: "#000",
                                        fontSize: "16px",
                                        fontFamily: "Montserrat",
                                        fontWeight: 700,
                                      }}>
                                      {item.name}
                                    </Typography>
                                    <Typography
                                      variant="h5"
                                      component="h5"
                                      sx={{
                                        color: "#000",
                                        fontSize: "16px",
                                        fontFamily: "Montserrat",
                                      }}>
                                      {scur}
                                      {parseFloat(item.price)
                                        .toFixed(2)
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </Typography>
                                  </div>
                                  <div className="boxContent">
                                    <Typography
                                      variant="caption"
                                      component="p"
                                      style={{
                                        fontStyle: "italic",
                                        color: "#000",
                                        fontFamily: "Montserrat",
                                        fontSize: "15px",
                                      }}>
                                      {item.category}
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      component="p"
                                      style={{
                                        color: "#000",
                                        fontFamily: "Montserrat",
                                        fontSize: "15px",
                                      }}>
                                      {item.description}
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      component="p"
                                      style={{
                                        color: "#000",
                                        fontFamily: "Montserrat",
                                        fontSize: "15px",
                                      }}>
                                      Inspection Period:{" "}
                                      {miles == true ? item.period : dPeriod}{" "}
                                      Days
                                    </Typography>
                                  </div>
                                </div>
                              </Box>
                            ))}
                            {cart == true && (
                              <FormControl sx={{ float: "right" }}>
                                <Button
                                  onClick={() => setCart(false)}
                                  variant="outlined"
                                  color="success">
                                  Add Item
                                </Button>
                              </FormControl>
                            )}
                          </Box>
                        )}
                      </Box>
                      {cart == false ? (
                        <>
                          <Box
                            sx={{
                              width: "100%",
                              justifyContent: "space-between",
                              display: "flex",
                            }}
                            autoComplete="off">
                            <FormControl
                              style={{
                                fontFamily: "Montserrat!important",
                                width: "45%",
                                marginTop: "25px",
                              }}>
                              <TextField
                                style={{
                                  fontFamily: "Montserrat",
                                  marginTop: 0,
                                }}
                                id="item-name"
                                label="Item Name"
                                variant="outlined"
                                value={itemName}
                                onChange={(e) => {
                                  setItemName(e.target.value);
                                  if (e.target.value == "") {
                                    document.querySelector(
                                      "#item-name-helper-text"
                                    ).style.display = "block";
                                  } else {
                                    document.querySelector(
                                      "#item-name-helper-text"
                                    ).style.display = "none";
                                  }
                                }}
                                autoComplete="off"
                                aria-describedby="item-name-helper-text"
                              />
                              <FormHelperText
                                style={{ color: "red", display: "none" }}
                                id="item-name-helper-text">
                                Required
                              </FormHelperText>
                            </FormControl>
                            <FormControl
                              style={{
                                fontFamily: "Montserrat!important",
                                width: "45%",
                                marginTop: "25px",
                              }}>
                              <InputLabel
                                htmlFor="item-price"
                                style={{ fontFamily: "Montserrat" }}>
                                Item Price
                              </InputLabel>
                              <OutlinedInput
                                id="item-price"
                                startAdornment={
                                  <InputAdornment position="start">
                                    {scur}
                                  </InputAdornment>
                                }
                                label="Item Price"
                                aria-describedby="item-price-helper-text"
                                value={itemPrice}
                                onChange={handlePrice}
                                color={itemPrice <= 0.0 ? "error" : ""}
                                type="number"
                                style={{ fontFamily: "Montserrat" }}
                                onBlur={handlePriceBlur}
                              />
                              <FormHelperText
                                style={{
                                  color: "red",
                                  display: "none",
                                  fontFamily: "Montserrat",
                                }}
                                id="item-price-helper-text">
                                Invalid
                              </FormHelperText>
                            </FormControl>
                          </Box>

                          <Box sx={{ width: "100%" }} autoComplete="off">
                            <FormControl
                              style={{
                                fontFamily: "Montserrat!important",
                                width: "100%",
                                marginTop: "25px",
                              }}>
                              <InputLabel
                                id="item-category-select-label"
                                style={{ fontFamily: "Montserrat" }}>
                                Item Category
                              </InputLabel>
                              <Select
                                labelId="item-category-select-label"
                                id="item-category-select"
                                value={itemCat}
                                label="Item Category"
                                onChange={(e) => {
                                  setItemCat(e.target.value);
                                  if (e.target.value == "") {
                                    document.querySelector(
                                      "#item-cat-helper-text"
                                    ).style.display = "block";
                                  } else {
                                    document.querySelector(
                                      "#item-cat-helper-text"
                                    ).style.display = "none";
                                  }
                                }}
                                style={{ fontFamily: "Montserrat" }}>
                                <MenuItem value="services">Services</MenuItem>
                              </Select>
                              <FormHelperText
                                style={{ color: "red", display: "none" }}
                                id="item-cat-helper-text">
                                Required
                              </FormHelperText>
                            </FormControl>
                            <FormControl
                              style={{
                                fontFamily: "Montserrat!important",
                                width: "100%",
                                marginTop: "25px",
                              }}>
                              <TextField
                                style={{
                                  backgroundColor: "#fff",
                                  fontFamily: "Montserrat!important",
                                  marginTop: 0,
                                }}
                                id="item-desc"
                                label="Item Description"
                                variant="outlined"
                                value={itemDesc}
                                onChange={(e) => {
                                  setItemDesc(e.target.value);
                                  if (e.target.value == "") {
                                    document.querySelector(
                                      "#item-desc-helper-text"
                                    ).style.display = "block";
                                  } else {
                                    document.querySelector(
                                      "#item-desc-helper-text"
                                    ).style.display = "none";
                                  }
                                }}
                                autoComplete="off"
                                aria-describedby="item-desc-helper-text"
                              />
                              <FormHelperText
                                style={{ color: "red", display: "none" }}
                                id="item-desc-helper-text">
                                Required
                              </FormHelperText>
                            </FormControl>
                            {(items.length <= 1 && editIsOpen == true) ||
                              (items.length == 0 && (
                                <FormControl
                                  style={{
                                    fontFamily: "Montserrat!important",
                                    width: "100%",
                                    marginTop: "25px",
                                  }}>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={miles}
                                        onChange={handleMiles}
                                        name="miles"
                                      />
                                    }
                                    label="Set transaction items as milestones"
                                  />
                                  <FormHelperText
                                    style={{ color: "red", display: "none" }}
                                    id="item-desc-helper-text">
                                    Required
                                  </FormHelperText>
                                </FormControl>
                              ))}
                            <FormControl
                              style={
                                miles == true
                                  ? {
                                      fontFamily: "Montserrat!important",
                                      width: "100%",
                                      marginTop: "25px",
                                    }
                                  : {
                                      fontFamily: "Montserrat!important",
                                      width: "100%",
                                      marginTop: "25px",
                                      display: "none",
                                    }
                              }>
                              <TextField
                                style={{
                                  backgroundColor: "#fff",
                                  marginTop: 0,
                                  fontFamily: "Montserrat!important",
                                }}
                                type="number"
                                label="Inspection period (days)"
                                variant="outlined"
                                value={itemPeriod}
                                onChange={(e) => {
                                  setItemPeriod(e.target.value);
                                  if (e.target.value == "") {
                                    document.querySelector(
                                      "#itemperiod-helper-text"
                                    ).style.display = "block";
                                  } else {
                                    document.querySelector(
                                      "#itemperiod-helper-text"
                                    ).style.display = "none";
                                  }
                                }}
                                autoComplete="off"
                                color={itemPeriod == "" && "error"}
                              />
                              <FormHelperText
                                id="itemperiod-helper-text"
                                style={{
                                  color: "red",
                                  display: "none",
                                  fontFamily: "Montserrat",
                                }}>
                                Required
                              </FormHelperText>
                            </FormControl>
                          </Box>
                        </>
                      ) : (
                        <Box sx={{ width: "100%", margin: "60px 0px" }}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}>
                            <Typography
                              variant="h6"
                              component="h6"
                              style={{
                                fontFamily: "Montserrat",
                                marginBottom: "15px",
                                fontWeight: 700,
                                fontSize: "16px",
                              }}>
                              Transaction summary
                            </Typography>

                            <Typography
                              onClick={() => setFOpen(true)}
                              variant="h6"
                              component="h6"
                              style={{
                                fontFamily: "Montserrat",
                                marginBottom: "15px",
                                fontWeight: 400,
                                fontSize: "16px",
                                color: "#09f",
                                cursor: "pointer",
                              }}>
                              How the totals are calculated?
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}>
                            <Typography
                              variant="caption"
                              component="p"
                              style={{
                                fontFamily: "Montserrat",
                                marginBottom: "10px",
                                fontSize: "16px",
                                fontWeight: 500,
                              }}>
                              Subtotal:
                            </Typography>

                            <Typography
                              onClick={() => setFOpen(true)}
                              variant="caption"
                              component="p"
                              style={{
                                fontFamily: "Montserrat",
                                marginBottom: "10px",
                                fontWeight: 400,
                                fontSize: "16px",
                                fontWeight: 500,
                                cursor: "pointer",
                              }}>
                              {scur +
                                fsubtotal.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}>
                            <Box sx={{ display: "flex" }}>
                              <Typography
                                variant="caption"
                                component="p"
                                style={{
                                  fontFamily: "Montserrat",
                                  marginBottom: "10px",
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}>
                                TranSecure Escrow fee paid by:
                              </Typography>
                              <FormControl
                                variant="standard"
                                sx={{ m: 1, minWidth: 120, top: "-10px" }}>
                                <Select
                                  labelId="demo-simple-select-standard-label"
                                  id="demo-simple-select-standard"
                                  value={adminCharge}
                                  onChange={handleCharge}
                                  label="Buyer">
                                  <MenuItem value="buyer">Buyer</MenuItem>
                                  <MenuItem value="seller">Seller</MenuItem>
                                  <MenuItem value="both">
                                    50% Buyer / 50% Seller
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Box>
                            <Typography
                              onClick={() => setFOpen(true)}
                              variant="caption"
                              component="p"
                              style={{
                                fontFamily: "Montserrat",
                                marginBottom: "10px",
                                fontWeight: 400,
                                fontSize: "16px",
                                fontWeight: 500,
                                cursor: "pointer",
                              }}>
                              {scur +
                                adminfee.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </Typography>
                          </Box>
                          <Divider />
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}>
                            <Typography
                              variant="caption"
                              component="p"
                              style={{
                                fontFamily: "Montserrat",
                                marginBottom: "10px",
                                fontSize: "16px",
                                fontWeight: 500,
                              }}>
                              Buyer price:
                            </Typography>

                            <Typography
                              onClick={() => setFOpen(true)}
                              variant="caption"
                              component="p"
                              style={{
                                fontFamily: "Montserrat",
                                marginBottom: "10px",
                                fontWeight: 400,
                                fontSize: "16px",
                                fontWeight: 500,
                                cursor: "pointer",
                              }}>
                              {scur +
                                bfee.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}>
                            <Typography
                              variant="caption"
                              component="p"
                              style={{
                                fontFamily: "Montserrat",
                                marginBottom: "10px",
                                fontSize: "16px",
                                fontWeight: 500,
                              }}>
                              Seller proceeds:
                            </Typography>

                            <Typography
                              onClick={() => setFOpen(true)}
                              variant="caption"
                              component="p"
                              style={{
                                fontFamily: "Montserrat",
                                marginBottom: "10px",
                                fontWeight: 400,
                                fontSize: "16px",
                                fontWeight: 500,
                                cursor: "pointer",
                              }}>
                              {scur +
                                sfee.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              variant="caption"
                              component="p"
                              style={{
                                fontFamily: "Montserrat",
                                marginBottom: "10px",
                                fontSize: "16px",
                                fontWeight: 500,
                                textAlign: "center",
                                fontStyle: "italic",
                              }}>
                              All prices are in {ncur}. Taxes may apply.
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}>
                            <Typography
                              variant="h6"
                              component="h6"
                              style={{
                                fontFamily: "Montserrat",
                                marginTop: "15px",
                                marginBottom: "15px",
                                fontWeight: 700,
                                fontSize: "16px",
                              }}>
                              {role == "seller"
                                ? "Buyer details"
                                : "Seller details"}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "25px",
                            }}>
                            <FormControl
                              style={{
                                fontFamily: "Montserrat!important",
                                width: "100%",
                                marginRight: "15px",
                              }}>
                              <TextField
                                style={{
                                  backgroundColor: "#fff",
                                  fontFamily: "Montserrat!important",
                                  width: "100%",
                                  color: "#063851!important",
                                }}
                                label="Email"
                                aria-describedby="my-helper-text"
                                value={beneficiaryEmail}
                                onChange={(e) => {
                                  setBeneficiaryEmail(e.target.value);
                                  if (e.target.value == "") {
                                    setEmailError(true);
                                    document.querySelector(
                                      "#beneficiaryE-Helper"
                                    ).style.display = "block";
                                  } else if (!isEmail(e.target.value)) {
                                    setEmailError(true);
                                    document.querySelector(
                                      "#beneficiaryE-Helper"
                                    ).style.display = "block";
                                  } else {
                                    setEmailError(false);
                                    document.querySelector(
                                      "#beneficiaryE-Helper"
                                    ).style.display = "none";
                                  }
                                }}
                                autoComplete="off"
                                color={beneficiaryEmail == "" && "error"}
                              />
                              <FormHelperText
                                style={{
                                  color: "red",
                                  display: "none",
                                  fontFamily: "Montserrat",
                                }}
                                id="beneficiaryE-Helper">
                                Required
                              </FormHelperText>
                            </FormControl>
                            <FormControl
                              style={{
                                fontFamily: "Montserrat!important",
                                width: "100%",
                                marginLeft: "15px",
                              }}>
                              <TextField
                                style={{
                                  backgroundColor: "#fff",
                                  fontFamily: "Montserrat!important",
                                  width: "100%",
                                  color: "#063851!important",
                                }}
                                label="Phone"
                                inputProps={{
                                  inputMode: "numeric",
                                  pattern: "[0-9]*",
                                }}
                                value={beneficiaryPhone}
                                onChange={(e) => {
                                  setBeneficiaryPhone(e.target.value);
                                  if (e.target.value == "") {
                                    document.querySelector(
                                      "#beneficiaryP-Helper"
                                    ).style.display = "block";
                                  } else {
                                    document.querySelector(
                                      "#beneficiaryP-Helper"
                                    ).style.display = "none";
                                  }
                                }}
                                autoComplete="off"
                                color={beneficiaryPhone == "" && "error"}
                              />
                              <FormHelperText
                                style={{
                                  color: "red",
                                  display: "none",
                                  fontFamily: "Montserrat",
                                }}
                                id="beneficiaryP-Helper">
                                Required
                              </FormHelperText>
                            </FormControl>
                          </Box>
                          <Divider />
                          <Box sx={{ textAlign: "center", marginTop: "15px" }}>
                            <FormControl
                              style={{ fontFamily: "Montserrat!important" }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}>
                                <Checkbox
                                  checked={checkedTerms}
                                  onChange={() =>
                                    setCheckedTerms(!checkedTerms)
                                  }
                                />
                                <label style={{ display: "flex" }}>
                                  I have read and agree to the TranSecure Escrow{" "}
                                  <Link href="/instructions">
                                    {" "}
                                    Instructions{" "}
                                  </Link>{" "}
                                  and{" "}
                                  <Link href="/privacy-policy">
                                    {" "}
                                    Privacy Policy
                                  </Link>
                                  .
                                </label>
                              </div>
                            </FormControl>
                            {startTransaction == false &&
                              startContinue == false && (
                                <Button
                                  id="handle-tran"
                                  onClick={handleTransaction}
                                  disabled={
                                    emailError == true ||
                                    beneficiaryEmail == "" ||
                                    beneficiaryPhone == "" ||
                                    (checkedTerms == false && "disabled")
                                  }
                                  sx={{
                                    marginTop: "25px",
                                    height: "50px",
                                    maxWidth: "450px",
                                    width: "100%",
                                  }}
                                  variant="contained"
                                  color="success"
                                  size="large">
                                  Start transaction
                                </Button>
                              )}
                            {startTransaction == true &&
                              startContinue == false && (
                                <Box>
                                  <CircularProgress
                                    color="warning"
                                    sx={{ marginTop: "25px" }}
                                  />
                                </Box>
                              )}
                            {startTransaction == false &&
                              startContinue == true && (
                                <Button
                                  id="handle-cont"
                                  onClick={handleContinue}
                                  sx={{
                                    marginTop: "25px",
                                    height: "50px",
                                    maxWidth: "450px",
                                    width: "100%",
                                  }}
                                  variant="contained"
                                  color="success"
                                  size="large">
                                  Next
                                </Button>
                              )}
                          </Box>
                          <Box
                            sx={{ marginTop: "32px", width: "100%" }}
                            autoComplete="off">
                            <div
                              className="center"
                              style={{ position: "relative", display: "none" }}>
                              <img src="" id="qrImg" />
                            </div>
                            <div
                              id="qrcode"
                              style={{
                                background: "white",
                                padding: "16px",
                                display: "none",
                              }}>
                              <QRCode
                                size={150}
                                style={{
                                  height: "auto",
                                  maxWidth: "100%",
                                  width: "100%",
                                }}
                                value={qrValue}
                                viewBox={`0 0 150 150`}
                              />
                            </div>
                          </Box>
                        </Box>
                      )}
                    </CardContent>
                    {cart == false && (
                      <CardActions>
                        <Box sx={{ width: "100%", marginBottom: "16px" }}>
                          <FormControl
                            sx={{ float: "right", display: "inline" }}>
                            {items.length >= 1 && editIsOpen == false && (
                              <Button
                                onClick={() => {
                                  setCart(true);
                                }}
                                variant="text"
                                sx={{ marginRight: "25px" }}>
                                Cancel
                              </Button>
                            )}
                            {editIsOpen == true ? (
                              <Button
                                onClick={handleEditClose}
                                disabled={
                                  (miles == true && itemPeriod == "") ||
                                  dTitle == "" ||
                                  role == "" ||
                                  currency == "" ||
                                  itemName == "" ||
                                  itemPrice == "" ||
                                  itemCat == "" ||
                                  itemDesc == ""
                                    ? true
                                    : false
                                }
                                variant="contained"
                                color="success">
                                Update Item
                              </Button>
                            ) : (
                              <Button
                                onClick={handleAddItem}
                                disabled={
                                  (miles == true && itemPeriod == "") ||
                                  dTitle == "" ||
                                  role == "" ||
                                  currency == "" ||
                                  itemName == "" ||
                                  itemPrice == "" ||
                                  itemCat == "" ||
                                  itemDesc == ""
                                    ? true
                                    : false
                                }
                                variant="contained"
                                color="success">
                                Add Item
                              </Button>
                            )}
                          </FormControl>
                        </Box>
                      </CardActions>
                    )}
                  </Box>
                </Card>
              </Grid2>
            </Grid2>
          </Box>
          <FeeCalcModal fopen={fopen} setFOpen={setFOpen} />
        </Container>
      ) : (
        <Container
          style={{
            backgroundColor: "#fff",
            border: "1px solid #e8eef2",
            borderBottom: "none",
          }}
          maxWidth="xl">
          <Box>
            <Grid2 container>
              <Grid2 md={12} className="cardGrid">
                <Card
                  className="gridcard"
                  sx={
                    window.innerWidth <= 899
                      ? { minWidth: 275, padding: "24px 10px" }
                      : {
                          maxWidth: 920,
                          padding: "30px 110px",
                          margin: "40px auto",
                        }
                  }>
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { marginTop: "8px" },
                    }}
                    noValidate
                    autoComplete="off">
                    <CardContent>
                      <Typography
                        variant="h5"
                        component="h5"
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: 700,
                          color: "#1d284a",
                          marginBottom: "16px",
                          textAlign: "center",
                        }}>
                        Transaction Created!
                      </Typography>
                      <Typography
                        variant="p"
                        component="p"
                        style={{
                          fontFamily: "Montserrat",
                          color: "#1d284a",
                          marginBottom: "16px",
                          fontSize: "14px",
                          textAlign: "center",
                        }}>
                        Your transaction has been created, waiting for both
                        parties to agree. Share the transaction via the URL or
                        QR code so that the{" "}
                        {role == "seller" ? "Buyer" : "Seller"} can agree to the
                        terms.
                      </Typography>
                      <Box
                        sx={{ marginTop: "45px", width: "100%" }}
                        autoComplete="off">
                        <div
                          className="center"
                          style={{ position: "relative" }}>
                          <img src={QrUrl} id="qrImg0" />
                        </div>
                        <Box
                          sx={
                            window.innerWidth <= 899
                              ? { width: "100%", margin: "auto" }
                              : { width: "345px", margin: "auto" }
                          }>
                          <Typography
                            variant="h6"
                            component="h6"
                            style={{
                              fontFamily: "Montserrat",
                              marginTop: "15px",
                              marginBottom: "15px",
                              fontWeight: 500,
                              fontSize: "14px",
                            }}>
                            Share
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-evenly",
                            }}>
                            <Box
                              onClick={() =>
                                window.open(
                                  "whatsapp://send?text=I've%20started%20a%20transaction%20titled%20" +
                                    dTitle +
                                    "%20on%20TranSecure%20Escrow. Please visit this link to agree to the terms of the transaction:%20" +
                                    qrValue,
                                  "_blank",
                                  "location=yes,height=570,width=520,scrollbars=yes,status=yes"
                                )
                              }
                              sx={{ textAlign: "center", cursor: "pointer" }}>
                              <img alt="whatsapp" src="/images/whatsapp.svg" />
                              <Typography
                                style={{
                                  fontFamily: "Montserrat",
                                  marginTop: "5px",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                }}>
                                Whatsapp
                              </Typography>
                            </Box>
                            <Box
                              onClick={() =>
                                window.open(
                                  "mailto:?subject=TranSecure%20Escrow%20Transaction%20-%20" +
                                    dTitle +
                                    "&body=I've%20started%20a%20transaction%20titled%20" +
                                    dTitle +
                                    "%20on%20TranSecure%20Escrow. Please visit this link to agree to the terms of the transaction:%20" +
                                    qrValue,
                                  "_blank",
                                  "location=yes,height=570,width=520,scrollbars=yes,status=yes"
                                )
                              }
                              sx={{ textAlign: "center", cursor: "pointer" }}>
                              <img alt="email" src="/images/email.svg" />
                              <Typography
                                style={{
                                  fontFamily: "Montserrat",
                                  marginTop: "5px",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                }}>
                                Email
                              </Typography>
                            </Box>
                            <Box
                              onClick={() =>
                                window.open(
                                  "https://www.facebook.com/sharer.php?u=I've%20started%20a%20transaction%20titled%20" +
                                    dTitle +
                                    "%20on%20TranSecure%20Escrow.%20Please%20visit%20this%20link%20to%20agree%20to%20the%20terms%20of%20the%20transaction:%20" +
                                    qrValue,
                                  "_blank",
                                  "location=yes,height=570,width=520,scrollbars=yes,status=yes"
                                )
                              }
                              sx={{ textAlign: "center", cursor: "pointer" }}>
                              <img
                                alt="messenger"
                                src="/images/messenger.svg"
                              />
                              <Typography
                                style={{
                                  fontFamily: "Montserrat",
                                  marginTop: "5px",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                }}>
                                Messenger
                              </Typography>
                            </Box>
                            <Box
                              onClick={() =>
                                window.open(
                                  "https://twitter.com/intent/tweet?url=I've%20started%20a%20transaction%20titled%20" +
                                    dTitle +
                                    "%20on%20TranSecure%20Escrow.%20Please%20visit%20this%20link%20to%20agree%20to%20the%20terms%20of%20the%20transaction:%20" +
                                    qrValue,
                                  "_blank",
                                  "location=yes,height=570,width=520,scrollbars=yes,status=yes"
                                )
                              }
                              sx={{ textAlign: "center", cursor: "pointer" }}>
                              <img alt="twitter" src="/images/twitter.svg" />
                              <Typography
                                style={{
                                  fontFamily: "Montserrat",
                                  marginTop: "5px",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                }}>
                                Twitter
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box sx={{ width: "100%", margin: "0px 0px" }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}>
                          <Typography
                            variant="h6"
                            component="h6"
                            style={{
                              fontFamily: "Montserrat",
                              marginTop: "15px",
                              marginBottom: "15px",
                              fontWeight: 500,
                              fontSize: "16px",
                            }}>
                            URL
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "25px",
                          }}>
                          <FormControl
                            style={{
                              fontFamily: "Montserrat!important",
                              width: "100%",
                            }}>
                            <OutlinedInput
                              id="outlined-adornment-url"
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton onClick={handleCopy} edge="end">
                                    <ContentCopy />
                                  </IconButton>
                                </InputAdornment>
                              }
                              ref={urlRef}
                              value={qrValue}
                            />
                          </FormControl>
                        </Box>
                        <Box sx={{ textAlign: "center", marginTop: "15px" }}>
                          <Button
                            onClick={() =>
                              (window.location =
                                "/transaction/view?idNo=" + itemId)
                            }
                            sx={{
                              marginTop: "25px",
                              height: "50px",
                              maxWidth: "400px",
                              width: "100%",
                            }}
                            variant="contained"
                            color="warning"
                            size="large">
                            View transaction
                          </Button>
                        </Box>
                      </Box>
                    </CardContent>
                  </Box>
                </Card>
              </Grid2>
            </Grid2>
          </Box>
        </Container>
      )}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          id="snckErr"
          onClose={handleClose}
          severity={errType}
          sx={{ width: "100%" }}>
          {errVal}
        </Alert>
      </Snackbar>
    </main>
  );
};
