import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import styles from "./Index.module.css";
import { LoadingButton } from "@mui/lab";
import { ExitToAppTwoTone } from "@mui/icons-material";

const ContactHero = ({ scrollToForm }) => {
  return (
    <Box className="bg-[#f5f7f9]">
      <Box className={styles.container}>
        <Grid container>
          <Grid item sm={12} md={6} className="">
            <Box className="my-36">
              <Typography variant="h3" sx={{ fontWeight: "bold" }}>
                Contact Us
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ fontSize: "13px", lineHeight: "1.3rem", color: "#333" }}>
                We are here to HELP, and our commitment to helping knows no
                bounds. With a dedicated team of experts and a wealth of
                experience, we stand ready to assist you in any way we can.
              </Typography>

              <Box className="mt-10">
                <LoadingButton
                  size="small"
                  onClick={scrollToForm}
                  color="success"
                  endIcon={<ExitToAppTwoTone />}
                  variant="contained"
                  style={{
                    backgroundColor: "#fe7200",
                    color: "#fff",
                    fontFamily: "Montserrat",
                    padding: "10px  24px ",
                    height: 45,
                    fontWeight: 700,
                  }}
                  onMouseOver={(e) =>
                    (e.target.style.backgroundColor = "#063851")
                  }
                  onMouseOut={(e) =>
                    (e.target.style.backgroundColor = "#fe7200")
                  }>
                  Send a Message
                </LoadingButton>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            sm={12}
            md={6}
            className="sm:flex hidden flex-col items-center justify-center">
            <img
              alt="about"
              src={require("../../assets/contact.png")}
              className="sm:block hidden"
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ContactHero;
