import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  ButtonLink,
  DashboardHeader,
  FlexRow,
  Input,
} from "../../components";
import { useUser } from "../../hooks";
import { Link } from "react-router-dom";
import { LandingFooter, LandingHeader } from "../../components/Landing";
import styles from "./Index.module.css";
import { Box } from "@mui/material";
import ContactHero from "./ContactHero";
import ContactContent from "./ContactContent";
export const ContactUs = () => {
  const [dAgent, setDAgent] = useState([]);
  useEffect(() => {
    const ditems = JSON.parse(localStorage.getItem("agent"));
    setDAgent(ditems);
  }, []);
  const formRef = useRef(null);

  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: "#fff",
        width: "100vw",
        minHeight: "100vh",
      }}>
      {dAgent?._id === undefined ? <LandingHeader /> : <DashboardHeader />}

      <Box>
        <ContactHero scrollToForm={scrollToForm} />
        <ContactContent formRef={formRef} />
      </Box>

      <LandingFooter />
    </Box>
  );
};
