import { Link } from "react-router-dom";
import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Snackbar, Alert, Button } from "@mui/material";
import { useEffect } from "react";
import axios from "axios";
export default function Details({}) {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [encrypted, setEncrypted] = useState(true);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [dAgent, setDAgent] = useState([]);

  const handleCloseSuccess = () => {
    navigate("/profile");
  };

  useEffect(() => {
    const ditems = JSON.parse(localStorage.getItem("agent"));
    const token = localStorage.getItem("token");
    setToken(token);
    setDAgent(ditems);
  }, []);

  const notify = (message) => toast(message);

  async function createPass() {
    if (!password || !confirmPassword) {
      notify("All Fields are required!");
      return;
    } else if (password !== confirmPassword) {
      notify("Password must match");
      return;
    } else {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      await axios
        .put(
          `https://transecure.onrender.com/api/v1/users/create-password/${dAgent?._id}`,
          { password: password, oldPassword: oldPassword },
          {
            headers: headers,
          }
        )
        .then((res) => {
          setLoading(false);
          setError(false);
          setSuccess(true);
        })
        .catch((err) => {
          console.log(err);
          setError(true);
          setSuccess(false);
          setLoading(false);
        });
      return;
    }
  }

  return (
    <section className="">
      <Toaster
        toastOptions={{
          className: "",
          style: {
            border: "1px solid rgba(145, 64, 64, 1)",
            padding: "16px",
            color: "rgba(145, 64, 64, 1)",
          },
        }}
      />
      <section className="sm:p-6 p-0 space-y-6">
        <div className="mb-6 ">
          <p className="text-black mb-1">Old Password</p>
          <div className="relative">
            <input
              type={encrypted ? "password" : "text"}
              placeholder="Your password"
              className="w-full p-2 border rounded"
              value={oldPassword}
              onChange={(e) => {
                setOldPassword(e.target.value);
              }}
            />
            <div
              className="pointer absolute right-5 top-0 h-full flex items-center"
              onClick={() => setEncrypted(!encrypted)}>
              {!encrypted ? (
                <VisibilityOff style={{ color: "#000" }} />
              ) : (
                <Visibility style={{ color: "#000" }} />
              )}
            </div>
          </div>
        </div>
        <div className="mb-6 ">
          <p className="text-black mb-1">New Password</p>
          <div className="relative">
            <input
              type={encrypted ? "password" : "text"}
              placeholder="Your password"
              className="w-full p-2 border rounded"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <div
              className="pointer absolute right-5 top-0 h-full flex items-center"
              onClick={() => setEncrypted(!encrypted)}>
              {!encrypted ? (
                <VisibilityOff style={{ color: "#000" }} />
              ) : (
                <Visibility style={{ color: "#000" }} />
              )}
            </div>
          </div>
        </div>

        <div className="mb-6 ">
          <p className="text-black mb-1">Confirm New password</p>
          <div className="relative">
            <input
              type={encrypted ? "password" : "text"}
              placeholder="Confirm password"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              className="w-full p-2 border rounded"
            />
            <div
              className="pointer absolute right-5 top-0 h-full flex items-center"
              onClick={() => setEncrypted(!encrypted)}>
              {!encrypted ? (
                <VisibilityOff style={{ color: "#000" }} />
              ) : (
                <Visibility style={{ color: "#000" }} />
              )}
            </div>
          </div>
        </div>

        <Snackbar
          open={success}
          autoHideDuration={6000}
          onClose={handleCloseSuccess}>
          <Alert
            onClose={handleCloseSuccess}
            severity="success"
            sx={{ width: "100%" }}>
            Your password has been updated successfully
          </Alert>
        </Snackbar>

        <Snackbar
          open={error}
          autoHideDuration={6000}
          onClose={() => setError(false)}>
          <Alert
            onClose={() => setError(false)}
            severity="error"
            sx={{ width: "100%" }}>
            Error updating your password, please check your password or network
            and try again
          </Alert>
        </Snackbar>

        <Button variant="contained" onClick={createPass} disabled={loading}>
          {loading ? "Please wait..." : "Create Password"}
        </Button>
      </section>
    </section>
  );
}
