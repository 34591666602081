import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonLink,
  DashboardHeader,
  FlexRow,
  Input,
} from "../../components";
import { useUser } from "../../hooks";
import { Link } from "react-router-dom";
import { LandingHeader } from "../../components/Landing";
import styles from "./Index.module.css";
import { Box, Container, Typography } from "@mui/material";
import {
  ArrowRightAlt,
  Password,
  Person,
  VerifiedUser,
} from "@mui/icons-material";

export const Profile = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [dAgent, setDAgent] = useState([]);

  useEffect(() => {
    const ditems = JSON.parse(localStorage.getItem("agent"));
    setDAgent(ditems);
  }, []);

  console.log(dAgent);

  const details = [
    {
      title: "Firstname",
      value: dAgent?.firstName || "Not Set",
    },
    {
      title: "Lastname",
      value: dAgent?.lastName || "Not Set",
    },
    {
      title: "Email Address",
      value: dAgent?.email,
    },
    {
      title: "Gender",
      value: dAgent?.gender,
    },
    {
      title: "Primary Phone",
      value: dAgent?.phone || "Not Set",
    },
    {
      title: "Alternate Phone",
      value: dAgent?.altPhone || "Not Set",
    },
    {
      title: "Address",
      value: dAgent?.billingAddress || "Not Set",
    },
    {
      title: "Bank Account",
      value: dAgent?.bankAccount || "Not Set",
    },
    {
      title: "Bank Name",
      value: dAgent?.bankName || "Not Set",
    },
  ];

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: "#f5f7f9",
        width: "100vw",
        minHeight: "100vh",
      }}>
      {dAgent?._id === undefined ? <LandingHeader /> : <DashboardHeader />}

      <Box>
        <Box className="py-10 sm:px-28 px-5  bg-blue-100">
          <Box>
            <Typography
              sx={{ fontWeight: "bold", fontSize: "25px", opacity: "0.7" }}>
              ACCOUNT SETTINGS
            </Typography>
          </Box>
        </Box>

        <Box className="sm:w-[85%] m-auto w-[95%] ">
          <Link to="/verify">
            <Box className="shadow-md bg-white hover:bg-gray-200 hover:shadow-none cursor-pointer my-5 rounded-md sm:p-8 p-2 flex justify-between items-center">
              <Box className="flex items-center space-x-2">
                <VerifiedUser className="text-[#fe7200]" />
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: "15px",
                    "@media(max-width: 575px)": {
                      fontSize: "13px",
                    },
                  }}>
                  {" "}
                  VERIFICATION STATUS :{" "}
                </Typography>
                <Box className="flex items-center space-x-2">
                  <Typography
                    variant="span"
                    sx={{
                      fontWeight: "Bold",
                      fontSize: "14px",
                      "@media(max-width: 575px)": {
                        fontSize: "11px",
                      },
                    }}>
                    {dAgent.emailVerification === "not verified"
                      ? "NOT VERIFIED"
                      : "VERIFIED"}
                  </Typography>{" "}
                  <img
                    alt="verified"
                    src={
                      dAgent.emailVerification === "not verified"
                        ? require("../../assets/chat.png")
                        : require("../../assets/verified.png")
                    }
                    width="27px"
                  />
                </Box>
              </Box>

              <ArrowRightAlt className="text-[#fe7200] font-[25px]" />
            </Box>
          </Link>
          <Link to="/create-password">
            <Box className="shadow-md bg-white hover:bg-gray-200 hover:shadow-none cursor-pointer my-5 rounded-md sm:p-8 p-2 flex justify-between items-center">
              <Box className="flex items-center space-x-2">
                <Password className="text-[#fe7200]" />
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: "15px",
                    "@media(max-width: 575px)": {
                      fontSize: "13px",
                    },
                  }}>
                  {" "}
                  CHANGE PASSWORD
                </Typography>
              </Box>

              <ArrowRightAlt className="text-[#fe7200] font-[25px]" />
            </Box>
          </Link>
          <Link to="/profile/update-profile">
            <Box className="shadow-md bg-white hover:shadow-none cursor-pointer my-5 rounded-md sm:p-8 p-2">
              <Box className=" flex justify-between items-center">
                <Box className="flex items-center space-x-2">
                  <Person className="text-[#fe7200]" />
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: "15px",
                      "@media(max-width: 575px)": {
                        fontSize: "13px",
                      },
                    }}>
                    ACCOUNT HOLDER INFORMATION
                  </Typography>
                </Box>

                <ArrowRightAlt className="text-[#fe7200] font-[25px]" />
              </Box>
              <Box>
                {dAgent?.firstName === "" ? (
                  <Box className="bg-orange-100 p-3 my-5">
                    <Typography>
                      Please complete your account information. Make sure that
                      your name (or company name) matches the name on your bank
                      account as TransecureEscrow.com does not make or accept
                      third party payments.
                    </Typography>
                  </Box>
                ) : (
                  <Box className="bg-green-100 p-3 my-5">
                    <Typography>
                      Account Information updated, click the right arrow to
                      update personal informations
                    </Typography>
                  </Box>
                )}

                {details.map((detail, index) => (
                  <Box
                    className="border-t border-b border-[#f7f7f7] px-3 py-5 flex items-center space-x-16"
                    key={index}>
                    <Typography
                      sx={{
                        fontSize: "17px",
                        width: "20%",
                        "@media(max-width: 575px)": {
                          fontSize: "12px",
                        },
                      }}>
                      {detail.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "17px",
                        fontWeight: "bold",
                        "@media(max-width: 575px)": {
                          fontSize: "12px",
                        },
                      }}>
                      {detail.value}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};
