import React from "react";
import {
  Dashboard,
  StartTransaction,
  Transactions,
  ViewTransaction,
  Signin,
  Signup,
  Logout,
  ResetPassword,
  CreateNewPassword,
  Home,
  AboutUs,
  ContactUs,
} from "./pages";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Profile } from "./pages/Profile";
import { UpdateProfile } from "./pages/UpdateProfile";
import { Verify } from "./pages/Verify";
import { VerifyLink } from "./pages/VerifyLink";
import { Legal } from "./pages/Legal";
import { Policy } from "./pages/Policy";
import { License } from "./pages/License";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="transaction/start" element={<StartTransaction />} />
        <Route path="transactions" element={<Transactions />} />
        <Route path="transaction/view" element={<ViewTransaction />} />
        <Route path="forgot-password" element={<ResetPassword />} />
        <Route path="logout" element={<Logout />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/license" element={<License />} />
        <Route path="/legal" element={<Legal />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/create-password" element={<CreateNewPassword />} />
        <Route path="/verify/:userId/:uniqueString" element={<VerifyLink />} />
        <Route path="/profile/update-profile" element={<UpdateProfile />} />

        {/* landing pages  */}
        <Route path="/" element={<Home />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="sign-up" element={<Signup />} />
        <Route path="sign-in" element={<Signin />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
