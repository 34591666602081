import React from "react";

const PolicyContent = () => {
  return (
    <div className="bg-gray-100 min-h-screen p-8">
      <div className="container mx-auto">
        <h1 className="text-3xl font-semibold mb-4">Policy</h1>
        <ul className="list-disc pl-6">
          <li className="mb-4">
            TranSecure Escrow's main objective is to serve as a trusted
            intermediary, protecting both purchasers and sellers against
            possible fraud.
          </li>
          <li className="mb-4">
            This is done by holding funds in escrow until both parties fulfill
            their obligations stated in the contract, instilling confidence and
            eliminating the fears associated with online transactions.
          </li>
          <li className="mb-4">
            Our stringent security measures and processes ensure a secure and
            reliable platform for all users.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PolicyContent;
