import {
  Box,
  Avatar,
  Alert,
  AlertTitle,
  ClickAwayListener,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  IconButton,
  Tooltip,
  Paper,
  Stack,
  Grow,
  Popper,
  MenuList,
  Typography,
  Icon,
  Container,
} from "@mui/material";
import {
  ArrowRight,
  ArrowRightAlt,
  PersonAdd,
  Settings,
  Logout,
  ArrowDropDown,
  ArrowUpward,
  ArrowDropUp,
  KeyboardArrowRight,
  Person,
} from "@mui/icons-material";
import React, { useEffect, useState, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./Index.module.css";
import { AgentContext } from "../../../contexts";
export const DashboardHeader = () => {
  const { agent, setAgent } = useContext(AgentContext);
  const [email, setEmail] = useState("");
  const [emailAction, setEmailAction] = useState("Send verification email");
  const [navIsOpen, setNavIsOpen] = useState(false);
  const [verify, setVerify] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const location = useLocation();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleVerify = () => {
    navigate("/verify");
  };
  useEffect(() => {
    const ditems = JSON.parse(localStorage.getItem("agent"));
    if (ditems && agent.toString.length === 0) {
      setAgent(ditems);
      setVerify(ditems?.emailVerification);
      setEmail(ditems?.email);
      console.log(localStorage.getItem("token"));
    } else {
      navigate("/sign-in");
    }
  }, []);
  return (
    <Container style={{ padding: "0px", maxWidth: "100%", margin: "0px" }}>
      <header
        style={
          window.innerWidth <= 899
            ? {
                padding: "10px 20px",
                marginBottom: 152,
                margin: "0 auto",
                justifyContent: "space-between",
                background: "#063851",
              }
            : {
                padding: "10px 115px",
                marginBottom: 152,
                margin: "0 auto",
                justifyContent: "space-between",
                background: "#063851",
              }
        }
        className="flex justify-between align-center">
        <Link to="/" style={{ marginRight: 30, zIndex: 9 }}>
          <img src="/images/logo192.png" width="65px" />
        </Link>
        {/* harmburger menu
         */}
        <div className={styles.desktopHide} style={{ alignItems: "center" }}>
          <div
            onClick={() => setNavIsOpen(!navIsOpen)}
            className={`pointer ${styles.desktopHide}`}
            style={{ rowGap: 4, flexDirection: "column", zIndex: 9 }}>
            {[1, 2, 3].map((item, idx) => (
              <Box
                key={idx}
                style={{
                  height: "2px",
                  backgroundColor: "#fff",
                  width: "25px",
                }}
              />
            ))}
          </div>

          <IconButton
            onClick={() => navigate("/profile")}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}>
            <Avatar
              alt={email}
              sx={{
                width: 32,
                height: 32,
                bgcolor: "#fe7200",
                fontWeight: 700,
                fontFamily: "Montserrat",
              }}>
              {email.charAt(0).toUpperCase()}
            </Avatar>
          </IconButton>
        </div>
        <Stack
          sx={{
            position: "absolute",
            right: 5,
            top: 80,
            backgroundColor: "#fff",
            paddingBlock: 2,
            zIndex: 10,
            bgcolor: "#fff",

            borderRadius: 2,
            display: navIsOpen ? "block" : "none",
          }}>
          {[
            { title: "My Transactions", link: "/transactions" },
            { title: "New Transaction", link: "/transaction/start" },
            { title: "Get Verified", link: "/verify" },
            { title: "Profile", link: "/profile" },
            { title: "About Us", link: "/about-us" },
            { title: "Contact Us", link: "/contact-us" },
            { title: "Logout", link: "/logout" },
          ].map((page, idx) => (
            <Link
              key={idx}
              to={page.link}
              style={{
                padding: "5px 20px",
                display: "block",
                fontWeight: 600,
                fontSize: "14px",
              }}>
              <Typography sx={{ color: "#000" }}>{page.title}</Typography>
            </Link>
          ))}
        </Stack>

        <div className={styles.mobileHide} style={{ zIndex: 9 }}>
          <Link
            to="/transactions"
            className={`${
              location.pathname === "/transactions"
                ? "border-b-4 pb-2 border-[#fe7200]"
                : ""
            }`}
            style={{
              marginRight: "2vw",
              color: "#fff",
              fontFamily: "Montserrat",
              fontWeight: 600,
              fontSize: "14px",
            }}>
            My Transactions
          </Link>

          <Link
            className={`${
              location.pathname === "/verify"
                ? "border-b-4 pb-2 border-[#fe7200]"
                : ""
            }`}
            to="/verify"
            style={{
              marginRight: "2vw",
              color: "#fff",
              fontFamily: "Montserrat",
              fontWeight: 600,
              fontSize: "14px",
            }}>
            Get Verified
          </Link>
          <Link
            className={`${
              location.pathname === "/about-us"
                ? "border-b-4 pb-2 border-[#fe7200]"
                : ""
            }`}
            to="/about-us"
            style={{
              marginRight: "2vw",
              color: "#fff",
              fontFamily: "Montserrat",
              fontWeight: 600,
              fontSize: "14px",
            }}>
            About us
          </Link>
          <Link
            to="/contact-us"
            className={`${
              location.pathname === "/contact-us"
                ? "border-b-4 pb-2 border-[#fe7200]"
                : ""
            }`}
            style={{
              marginRight: "2vw",
              color: "#fff",
              fontFamily: "Montserrat",
              fontWeight: 600,
              fontSize: "14px",
            }}>
            Contact us
          </Link>
        </div>

        <div
          className={styles.mobileHide}
          style={{ marginLeft: "auto", marginRight: 0, zIndex: 9 }}>
          <Link
            to="/transaction/start"
            style={{
              padding: "7px 20px 11px",
              border: "1px solid #fff",
              height: "fit-content",
              color: "#1d284a",
              fontFamily: "Montserrat",
              backgroundColor: "#f7f9fc",
              marginRight: 20,
              position: "relative",
              zIndex: 9,
              borderRadius: "4px",
              fontWeight: 700,
              fontSize: "14px",
            }}>
            Start New Transaction
          </Link>

          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}>
            <Avatar
              alt={email}
              sx={{
                width: 32,
                height: 32,
                bgcolor: "#fe7200",
                fontWeight: 700,
                fontFamily: "Montserrat",
              }}>
              {email.charAt(0).toUpperCase()}
            </Avatar>
            {open == true ? (
              <ArrowDropUp sx={{ color: "#fff" }} />
            ) : (
              <ArrowDropDown sx={{ color: "#fff" }} />
            )}
          </IconButton>
        </div>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "#fe7200",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
          <MenuItem
            sx={{
              padding: "18px 18px 19px",
              background: "transparent!important",
              cursor: "default",
            }}>
            <Avatar
              sx={{
                width: "65px!important",
                height: "65px!important",
                bgcolor: "#fe7200",
                fontWeight: 700,
                fontFamily: "Montserrat",
                fontSize: "34px",
              }}>
              {email.charAt(0).toUpperCase()}
            </Avatar>
            <span
              style={{
                fontSize: "12px",
                fontFamily: "Montserrat",
                marginLeft: "16px",
              }}>
              {email}
            </span>
          </MenuItem>
          {verify == "not verified" && (
            <>
              <Divider />
              <MenuItem
                sx={{
                  display: "block",
                  background: "transparent!important",
                  cursor: "default",
                }}>
                <Alert
                  onClick={handleVerify}
                  severity="warning"
                  variant="outlined"
                  style={{
                    cursor: "pointer",
                    padding: "0px 15px",
                    alignItems: "center",
                    background: "#063851",
                    borderColor: "#063851",
                    color: "#fff!important",
                  }}>
                  <AlertTitle
                    style={{
                      fontWeight: 500,
                      fontFamily: "Montserrat",
                      fontWeight: 700,
                      margin: 0,
                      color: "#fff",
                    }}>
                    Verify my account
                  </AlertTitle>
                </Alert>
                <p
                  style={{
                    fontSize: "12px",
                    fontFamily: "Montserrat",
                    lineHeight: 1.4,
                    marginTop: "5px",
                  }}>
                  Account must be verified to
                  <br />
                  make or receive payments
                </p>
              </MenuItem>
            </>
          )}
          <Divider />
          <MenuItem onClick={() => navigate("/profile")}>
            <ListItemIcon>
              <Person fontSize="small" />
            </ListItemIcon>
            My Profile
          </MenuItem>
          <MenuItem onClick={() => navigate("/logout")}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </header>
    </Container>
  );
};
