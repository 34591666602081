import React from "react";
import { Box, Container, Typography, FormControl, Button } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import agreement from "../../assets/icons/deal.png";
import inspect from "../../assets/icons/inspect.png";
import release from "../../assets/icons/product-release.png";
import payment from "../../assets/icons/payment.png";
import customerRecieve from "../../assets/icons/customer_recieve.png";
import dropService from "../../assets/icons/drop.png";
import { LoadingButton } from "@mui/lab";
import { ExitToAppTwoTone } from "@mui/icons-material";

const EscrowSteps = ({ handleOverOut, handleOverSection }) => {
  const Steps = [
    {
      Icon: agreement,
      Header: "Buyers and Sellers Agree to Terms of Services",
      Text: "Both parties acknowledge and agree to the terms of the transaction, ensuring a mutual understanding.",
    },
    {
      Icon: payment,
      Header: "Buyer Submits Payment to TranSecure Escrow",
      Text: "The buyer securely transfers the payment to TranSecure Escrow, ensuring the funds are in safe hands.",
    },
    {
      Icon: dropService,
      Header: "Seller Drops Off Merchandise to TranSecure Escrow",
      Text: "The seller delivers the merchandise to TranSecure Escrow, where it will be held securely during the inspection process.",
    },
    {
      Icon: inspect,
      Header: "TranSecure Escrow: Service Verification and Approval",
      Text: "The TranSecure Escrow team diligently verifies the services being performed to ensure quality and compliance.",
    },
    {
      Icon: release,
      Header: "TranSecure Escrow Releases Payment to Seller",
      Text: "Once the services are verified, TranSecure Escrow releases the payment to the seller, ensuring a fair transaction.",
    },
    {
      Icon: customerRecieve,
      Header: "Buyer Receives Merchandise from TranSecure Escrow",
      Text: "With the payment received, the buyer gets access to the merchandise, completing the successful transaction.",
    },
  ];
  return (
    <Box className=" p-5 sm:mt-28 mt-12" style={{ backgroundColor: "#fafafa" }}>
      <Container>
        <Box className="mt-5 text-center">
          <Typography
            variant="h2"
            sx={{
              fontSize: "38px",
              fontFamily: "Montserrat Bold",
              color: "#063851",
              "@media (max-width: 640px)": {
                fontSize: "32px",
              },
            }}>
            How Do We{" "}
            <span style={{ color: "#fe7200", fontFamily: "Montserrat Bold" }}>
              Operate?
            </span>
          </Typography>
          <h3 class="text-sm  mt-1.5  opacity-80 mx-auto leading-1.5 sm:w-full w-72">
            TranSecure Escrow ensures ultimate security for buyers and sellers,
            with millions of transactions safeguarded.
          </h3>
        </Box>

        <Box className="mt-10">
          <Grid2 container spacing={2}>
            {Steps.map((step, index) => (
              <Grid2 sm={6} md={4} key={index}>
                <Box className="bg-white rounded-xl p-4 shadow-lg mt-3">
                  <img src={step.Icon} alt="deal" width="45px" />

                  <h2 class="text-lg text-center font-semibold mt-6 mb-3">
                    {step.Header}
                  </h2>
                  <p class="text-gray-600 text-sm text-center">{step.Text}</p>
                </Box>
              </Grid2>
            ))}
          </Grid2>
        </Box>

        <Box className="mt-5">
          <LoadingButton
            size="small"
            color="success"
            endIcon={<ExitToAppTwoTone />}
            variant="contained"
            style={{
              backgroundColor: "#fe7200",
              color: "#fff",
              fontFamily: "Montserrat",
              padding: "10px  24px ",
              height: 55,
              fontWeight: 700,
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#063851")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#fe7200")}>
            Get started now
          </LoadingButton>
        </Box>
      </Container>
    </Box>
  );
};

export default EscrowSteps;
