import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonLink,
  DashboardHeader,
  FlexRow,
  Input,
} from "../../components";
import { useUser } from "../../hooks";
import { Link } from "react-router-dom";
import { LandingFooter, LandingHeader } from "../../components/Landing";
import styles from "./Index.module.css";
import { Box } from "@mui/material";
import LicenseContent from "./LicenseContent";
import LicenseHero from "./LicenseHero";
export const License = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [dAgent, setDAgent] = useState([]);
  useEffect(() => {
    const ditems = JSON.parse(localStorage.getItem("agent"));
    setDAgent(ditems);
  }, []);

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: "#fff",
        width: "100vw",
        minHeight: "100vh",
      }}>
      {dAgent?._id === undefined ? <LandingHeader /> : <DashboardHeader />}

      <Box>
        <LicenseHero />
        <LicenseContent />
      </Box>

      <LandingFooter />
    </Box>
  );
};
