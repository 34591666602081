import React from "react";
import { Box, Container, FormControl, Button } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import styles from "./Index.module.css";
const Contact = () => {
  return (
    <Box style={{ backgroundColor: "#fafafa" }}>
      <Container>
        <Box className="pt-3">
          <Grid2 container>
            <Grid2 xs={12} lg={7}>
              <Box className="sm:mt-16 mt-5">
                <div>
                  <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                    <span
                      style={{
                        fontFamily: "Montserrat Bold",
                        color: "#919EAB",
                        fontSize: "12px",
                      }}>
                      Need Help?
                    </span>
                    <h3
                      style={{
                        fontFamily: "Montserrat Bold",
                        color: "#1d284a",
                        fontSize: "44px",
                        marginTop: "1px",
                        marginBottom: "6px",
                        lineHeight: 1.5,
                      }}>
                      Get In Touch
                    </h3>
                    <p
                      className={styles.headings}
                      style={{
                        color: "#637381",
                        fontFamily: "Montserrat",
                        fontSize: "16px",
                        fontWeight: 400,
                        marginLeft: "auto",
                        marginRight: "auto",
                        lineHeight: 1.75,
                      }}>
                      Contact our support team on{" "}
                      <span style={{ fontWeight: 700, color: "#ff7200" }}>
                        090-132-741-90
                      </span>{" "}
                      <br />
                      to find out if your transaction can be covered.
                    </p>
                    <FormControl
                      style={
                        window.innerWidth
                          ? { margin: "20px 0px" }
                          : { marginTop: 20 }
                      }>
                      <Button
                        size="large"
                        href="/sign-up"
                        style={{
                          backgroundColor: "#ff7200",
                          color: "#fff",
                          fontFamily: "Montserrat",
                          fontWeight: 700,
                          textAlign: "center",
                        }}
                        onMouseOver={(e) => {
                          e.target.style.color = "#fff";
                          e.target.style.backgroundColor = "#063851";
                        }}
                        onMouseOut={(e) => {
                          e.target.style.backgroundColor = "#ff7200";
                          e.target.style.color = "#fff";
                        }}>
                        Get started now
                      </Button>
                    </FormControl>
                  </div>
                </div>
              </Box>
            </Grid2>
            <Grid2 xs={12} lg={5}>
              <Box>
                <img src="/images/contac.png" style={{ width: "100%" }} />
              </Box>
            </Grid2>
          </Grid2>
        </Box>
      </Container>
    </Box>
  );
};

export default Contact;
