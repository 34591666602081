import React, { useState, useEffect } from "react";
import {
  Box,
  Tab,
  Modal,
  Typography,
  Button,
  Paper,
  TableContainer,
  TablePagination,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Chip,
  Stack,
} from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import TabList from "@mui/lab/TabList";
import Joyride from "react-joyride";
import { Circle } from "@mui/icons-material";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#fff",
  boxShadow: 24,
  borderRadius: "12px",
  p: 4,
};

const TabView = ({ tabValue, handleChange, userId, email }) => {
  const [transactionsData, setTransactionsData] = useState([]);
  const [showOnNextLogin, setShowOnNextLogin] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [itemId, setItemId] = useState("");
  const [runJoyride, setRunJoyride] = useState(false);
  const [errVal, setErrVal] = useState("");
  const [errType, setErrType] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose2 = () => setOpen(false);
  const handleClose = () => {
    localStorage.setItem("prompt", "true");
    setOpen(false);
  };
  const [selected, setSelected] = React.useState([]);

  const navigate = useNavigate();

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
  // stableSort() brings sort stability to non-modern browsers (notably IE11). If you
  // only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
  // with exampleArray.slice().sort(exampleComparator)
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [steps, setSteps] = useState([
    {
      target: ".step-1",
      content: (
        <div className="text-left">
          <h2 className="font-bold text-lg">Tabs</h2>
          <p className="text-sm">
            Transactions are now grouped depending on their status.
          </p>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: ".step-2",
      content: (
        <div className="text-left">
          <h2 className="font-bold text-lg">Tabs</h2>
          <p className="text-sm">
            Your Open transactions are those that are still ongoing, including
            the ones that require your action.
          </p>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: ".step-3",
      content: (
        <div className="text-left">
          <h2 className="font-bold text-lg">Tabs</h2>
          <p className="text-sm">
            Your Closed transactions are those that were completed or cancelled.
          </p>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: ".step-4",
      content: (
        <div className="text-left">
          <h2 className="font-bold text-lg">Tabs</h2>
          <p className="text-sm">
            Whenever your input is needed in any transaction. It will be
            displayed on this tab.
          </p>
        </div>
      ),
      disableBeacon: true,
    },
  ]);

  const startJoyride = () => {
    if (showOnNextLogin) {
      localStorage.setItem("prompt", "true");
    }
    setRunJoyride(true);
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const columns = [
    { id: "tid", label: "TID", minWidth: 170 },
    { id: "title", label: "TRANSACTION TITLE", minWidth: 170 },
    {
      id: "createdAt",
      label: "CREATED",
      minWidth: 170,
    },
    {
      id: "amount",
      label: "AMOUNT",
      minWidth: 170,
      format: (value) => value.toFixed(2),
    },
    {
      id: "role",
      label: "ROLE",
      minWidth: 170,
    },
    {
      id: "status",
      label: "STATUS",
      minWidth: 170,
    },
  ];

  const handleGetTransactions = async () => {
    console.log(userId);
    console.log(email);
    const token = localStorage.getItem("token");
    const headers = { Authorization: "Bearer " + token };
    const requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };
    const resp = await fetch(
      "https://transecure.onrender.com/api/v1/transactions/" + userId,
      requestOptions
    );
    const data = await resp.json();
    if (data.error) {
      console.log(data.error.message);
      return;
    }
    const arrayList = JSON.stringify(data);
    return arrayList;
  };

  useEffect(() => {
    (async function renderCheckout() {
      const lists = await handleGetTransactions();
      const dataLists = JSON.parse(lists);
      setTransactionsData(dataLists);
      console.log(dataLists);
      // Check if the user has already selected not to show the prompt on the next login.
      const dontShowPrompt = localStorage.getItem("prompt");
      if (!dontShowPrompt) {
        setOpen(true);
      }
    })();
  }, []);
  return (
    <>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="all" value="all" className="text-xs step-1" />
              <Tab label="action" value="action" className="text-xs step-4" />
              <Tab label="opened" value="opened" className="text-xs step-2" />
              <Tab label="closed" value="closed" className="text-xs step-3" />
            </TabList>
          </Box>
          <TabPanel value="all">
            <Box sx={{ width: "100%" }}>
              <Paper sx={{ width: "100%", mb: 2 }}>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={"medium"}>
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}>
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {transactionsData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .reverse()
                        .map((row) => {
                          let trStatus;
                          let trcolor;
                          let trRole;
                          let trCur;
                          let trDate;
                          let trAction = row?.action;
                          trDate = moment(row?.createdAt).format(
                            "MMM DD, YYYY"
                          );
                          if (email == row?.senderEmail) {
                            trRole = row?.senderRole;
                            trStatus = row?.senderStatus;
                          }
                          if (email == row?.beneficiaryEmail) {
                            trRole = row?.beneficiaryRole;
                            trStatus = row?.beneficiaryStatus;
                          }
                          if (row?.currency == "NGN") {
                            trCur = "₦";
                          }
                          return (
                            <TableRow
                              onClick={() => {
                                setItemId(row?.tid);
                                window.open(
                                  "/transaction/view?idNo=" + row?.tid,
                                  "_blank"
                                );
                              }}
                              sx={{ cursor: "pointer" }}
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row?.tid}>
                              <TableCell align={"left"}>
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                  }}>
                                  {row?.tid}
                                </p>
                              </TableCell>
                              <TableCell align={"left"}>
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    textTransform: "capitalize",
                                  }}>
                                  {row?.title}
                                </p>
                                {row?.milestone == true && (
                                  <p style={{ fontSize: "12px" }}>Milestone</p>
                                )}
                              </TableCell>
                              <TableCell align={"left"}>
                                <p style={{ fontWeight: "bold" }}>{trDate}</p>
                              </TableCell>
                              <TableCell align={"left"}>
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    fontWeight: "bold",
                                  }}>
                                  {trCur}
                                  {parseFloat(row?.amount).toFixed(2)}
                                </p>
                                <p style={{ fontSize: "12px" }}>
                                  {row?.currency}
                                </p>
                              </TableCell>
                              <TableCell align={"left"}>
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    textTransform: "capitalize",
                                  }}>
                                  {trRole}
                                </p>
                              </TableCell>
                              <TableCell align={"right"}>
                                {trStatus == "cancelled" ? (
                                  <Chip
                                    sx={{
                                      marginBottom: "10px",
                                      cursor: "pointer",
                                      textTransform: "capitalize",
                                    }}
                                    label={trStatus}
                                    color="default"
                                  />
                                ) : (
                                  <Chip
                                    sx={{
                                      marginBottom: "10px",
                                      cursor: "pointer",
                                      textTransform: "capitalize",
                                    }}
                                    label={trStatus}
                                    color="primary"
                                  />
                                )}
                                <br />
                                {trAction == "beneficiary" &&
                                  email == row?.beneficiaryEmail && (
                                    <Chip
                                      sx={{ cursor: "pointer" }}
                                      label="Action Required"
                                      color="error"
                                    />
                                  )}
                                {trAction == "sender" &&
                                  email == row?.senderEmail && (
                                    <Chip
                                      sx={{ cursor: "pointer" }}
                                      label="Action Required"
                                      color="error"
                                    />
                                  )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={transactionsData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Box>
          </TabPanel>
          <TabPanel value="action">Action Required</TabPanel>
          <TabPanel value="opened">Opened</TabPanel>
          <TabPanel value="closed">Closed</TabPanel>
        </TabContext>
      </Box>

      <Joyride
        steps={steps}
        run={runJoyride} // Start the tour when runJoyride is true
        continuous={true} // Loop the tour
        disableOverlayClose={true} // Prevent closing on overlay click
        showProgress={true}
      />

      <div>
        <Modal
          open={open}
          onClose={handleClose2}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={style}>
            <Box className="flex-col items-center text-center justify-center">
              <img
                src="/images/logo2.png"
                alt=""
                className="sm:w-[25%] w-[26%]"
              />
              <Typography
                id="modal-modal-description"
                sx={{
                  mt: 2,
                  fontSize: "20px",
                  color: "#063851",
                  fontWeight: "bold",
                }}>
                Hello Champ! 👋 <br />
                Welcome to Transecure Escrow?.
              </Typography>
              <Typography
                id=""
                sx={{ mt: 3, fontSize: "15px" }}
                variant="paragraph">
                Allow us to guide you through the newly updated Transactions
                page. You'll be done in under a minute.
              </Typography>

              <Button
                variant="contained"
                className=" mb-2"
                sx={{ mt: 6 }}
                disableElevation
                onClick={startJoyride}>
                Let's get started
              </Button>
              <Button variant="text" onClick={handleClose}>
                Skip
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default TabView;
