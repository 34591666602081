import React from "react";
import { Box, Container, Typography } from "@mui/material";
import about from "../../assets/whyus.jpg";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

const WhyUs = () => {
  const Items = [
    {
      Sn: "01",
      Header: "Enhanced Security",
      Text: "Your transactions are shielded from potential fraud and scams, ensuring both buyers and sellers are protected against unauthorized activities.",
    },
    {
      Sn: "02",
      Header: "Risk Mitigation",
      Text: "TranSecure Escrow reduces the risk of chargebacks and disputes, providing a secure environment for transactions to take place.",
    },
    {
      Sn: "03",
      Header: "Trusted Intermediary",
      Text: "Our service acts as a neutral third party, ensuring a fair and transparent process for both buyers and sellers.",
    },
    {
      Sn: "04",
      Header: "Efficient Dispute Resolution",
      Text: "In the rare event of a dispute, TranSecure Escrow offers a structured process for resolution, ensuring fair outcomes.",
    },
    {
      Sn: "05",
      Header: "Professional Support",
      Text: "Our dedicated support team is available to assist you throughout your transaction journey, offering guidance and answering any questions.",
    },
    {
      Sn: "06",
      Header: "Flexibility and Convenience",
      Text: "TranSecure Escrow caters to a wide range of transactions, from small purchases to larger deals, providing a secure platform for various needs.",
    },
  ];
  return (
    <Container>
      <Box className="mt-16 text-center">
        <Typography
          variant="h2"
          sx={{
            fontSize: "38px",
            fontFamily: "Montserrat Bold",
            color: "#063851",
            "@media (max-width: 640px)": {
              fontSize: "32px",
            },
          }}
        >
          Why Transecure{" "}
          <span style={{ color: "#fe7200", fontFamily: "Montserrat Bold" }}>
            Escrow?
          </span>
        </Typography>
        <h3 class="text-sm  mt-1.5  opacity-80 mx-auto leading-1.5 sm:w-full w-72">
          TranSecure Escrow has earned a reputation for reliability and
          security, making it a preferred choice for online transactions.
        </h3>
      </Box>
      <Grid2 container spacing={2} className="py-10">
        <Grid2 item sm={12} md={5}>
          <Box className="mt-3">
            <img src={about} alt="about image" width="100%" />
          </Box>
        </Grid2>
        <Grid2 item sm={12} md={7}>
          <Grid2 container spacing={2}>
            {Items.map((item, i) => (
              <Grid2 item sm={6} md={6}>
                <Box
                  className="bg-[#fafafa] rounded-xl mb-2 p-4 shadow-md"
                  key={i}
                >
                  <h1
                    className="text-4xl font-bold text-[#fe7200]"
                    style={{ fontFamily: "Montserrat Bold" }}
                  >
                    {" "}
                    {item.Sn}.
                  </h1>
                  <h2 class="text-lg text-center font-semibold mt-6 mb-3">
                    {item.Header}
                  </h2>
                  <p class="text-gray-600 text-sm text-center">{item.Text}</p>
                </Box>
              </Grid2>
            ))}
          </Grid2>
        </Grid2>
      </Grid2>
    </Container>
  );
};

export default WhyUs;
