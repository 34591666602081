import React, { useState } from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { Phone, MailOutline, Instagram, Twitter } from "@mui/icons-material";
import axios from "axios";

const ContactContent = ({ formRef }) => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    message: "",
    title: "",
  });

  const [open, setOpen] = useState(true);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState();

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      const response = await axios.post(
        "https://transecure.onrender.com/api/v1/message",
        formData
      );

      if (response) {
        setLoading(false);
        setMessage("Your message has been sent successfully");
        setOpen(true);
        setSuccess(true);
        setError(false);
      }
    } catch (error) {
      setMessage("Your message could not be sent, please try again");
      setOpen(true);
      setSuccess(false);
      setError(true);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <Container className="my-8">
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Card className="shadow-md">
            <CardContent>
              <h2 className="text-xl font-bold mb-4">Phone Support</h2>
              <p className="text-[13px]">
                If you have a question or need immediate support, please use our
                live phone support. It is available Monday to Friday 8:00 a.m.
                to 6:00 p.m., GMT.
              </p>
              <br />
              <ul className="list-disc pl-6 mt-2">
                <li className="text-[11px]">
                  Customer Support: 090-132-741-90
                </li>
              </ul>
              <br />
              <div className="my-5">
                <p>Connect With us on our social media accounts</p>
                <div className="space-x-2">
                  <a
                    href="https://www.instagram.com/transecureescrow/?utm_source=ig_web_button_share_sheet&igshid=YzAwZjE1ZTI0Zg=="
                    target="_blank">
                    <Instagram className="text-[25px] text-orange-600" />
                  </a>
                  <a href="https://x.com/Transecurescrow?s=20" target="_blank">
                    <Twitter className="text-[25px] text-orange-600" />
                  </a>
                </div>
              </div>
              <p className="text-[13px]">
                For customer assistance beyond our phone support hours, please
                send us an email at support@escrow.com instead. We will have one
                of our Email Support Representatives reach you at the soonest
                possible time.
              </p>
              <br />
              <p className="text-[13px]">
                We are always ready to help you, but because of regulatory
                rules, Escrow.com customer support is limited in what they can
                and cannot assist you with.
              </p>
              <br />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} ref={formRef}>
          <Card className="shadow-md">
            {error || success ? (
              <Alert severity={error ? "error" : "success"} onClose={() => {}}>
                {message}
              </Alert>
            ) : (
              ""
            )}

            <CardContent>
              <h2 className="text-xl font-bold mb-4">Send Us a Message</h2>
              <form onSubmit={handleSubmit} className="space-y-4">
                <TextField
                  label="Name"
                  fullWidth
                  variant="outlined"
                  className="mb-4"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleInputChange}
                  required
                />
                <TextField
                  label="Email"
                  fullWidth
                  variant="outlined"
                  className="mb-4"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  type="email"
                  required
                />
                <TextField
                  label="Title"
                  fullWidth
                  variant="outlined"
                  className="mb-4"
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange}
                  required
                />
                <TextField
                  label="Message"
                  fullWidth
                  variant="outlined"
                  className="mb-4"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  multiline
                  rows={4}
                  required
                />
                <Button
                  variant="contained"
                  type="submit"
                  disabled={loading}
                  color="primary"
                  sx={{ bgcolor: "#fe7200" }}>
                  {loading ? "Please wait..." : "Send Message"}
                </Button>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ContactContent;
