import React, { useContext, useState } from "react";
import { useUser } from "../../hooks";
import { LandingHeader, LandingFooter } from "../../components/Landing";
import styles from "./Index.module.css";
import {
  Box,
  Container,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Button,
  Snackbar,
  List,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  useTheme,
  createTheme,
  Fade,
  Slide,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {
  SyncLockTwoTone,
  HandshakeOutlined,
  ExitToAppTwoTone,
  CheckCircle,
} from "@mui/icons-material";
import { useRef } from "react";
import { useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import * as locales from "@mui/material/locale";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import { DashboardHeader } from "../../components";
import { AgentContext } from "../../contexts";
import EscrowSteps from "./EscrowSteps";
import Contact from "./Contact";
import WhyUs from "./WhyUs";
import Testimonials from "./Testimonials";
import Faq from "./Faq";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let [IsValid, setIsValid] = useState(false);
  const { agent, setAgent } = useContext(AgentContext);
  const [dAgent, setDAgent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [serv, setServ] = useState("");
  const [amount, setAmount] = useState("");
  const [open, setOpen] = useState(false);
  const [openIam, setOpenIam] = useState(false);
  const [errVal, setErrVal] = useState("");
  const [errType, setErrType] = useState("");
  const navigate = useNavigate();
  const timedOut = 2000;
  const handleClose = () => {
    setOpen(false);
    setErrType("");
    setErrVal("");
  };

  const handleOverSection = (e) => {
    let bgC = e.target.style.backgroundColor;
    e.target.querySelector("path").style.transform = "translateY(-10px)";
    e.target.style.color = "#063851";
  };
  const handleOverOut = (e) => {
    let bgC = e.target.style.backgroundColor;
    e.target.querySelector("path").style.transform = "translateY(0px)";
    e.target.style.color = "#ff7200";
    // e.target.style.color="#063851";
  };

  const handleBanner = () => {
    var dLock = document.getElementById("synLck");
    let resd = dLock.classList.contains("lck");
    dLock.style.transition = "all 3s ease-in 0s";
    if (dLock.offsetTop > window.innerHeight && window.innerWidth >= 900) {
      dLock.classList.add("lck");
      dLock.style.transform = "translate(60px,0px)";
      dLock.style.backgroundColor = "#fe7200b3";
      return false;
    }
    if (resd && window.innerWidth >= 900) {
      setTimeout(() => {
        dLock.classList.remove("lck");
        dLock.style.transform = "translate(60px,250px)";
        dLock.style.backgroundColor = "#fe7200b3";
      }, 3500);
    }
    if (!resd && window.innerWidth >= 900) {
      setTimeout(() => {
        dLock.classList.add("lck");
        dLock.style.transform = "translate(60px,0px)";
        dLock.style.backgroundColor = "#fe7200b3";
      }, 3500);
    }
    if (dLock.offsetTop > window.innerHeight && window.innerWidth <= 899) {
      dLock.classList.add("lck");
      dLock.style.transform = "translate(24px,0px)";
      dLock.style.backgroundColor = "#fe7200b3";
      return false;
    }
    if (resd && window.innerWidth <= 899) {
      setTimeout(() => {
        dLock.classList.remove("lck");
        dLock.style.transform = "translate(100%,0px)";
        dLock.style.backgroundColor = "#fe7200b3";
      }, 3500);
    }
    if (!resd && window.innerWidth <= 899) {
      setTimeout(() => {
        dLock.classList.add("lck");
        dLock.style.transform = "translate(24px,0px)";
        dLock.style.backgroundColor = "#fe7200b3";
      }, 3500);
    }
  };

  const handleBannerEx = async () => {
    var dLock = document.getElementById("synLck");
    dLock.style.transition = "all 2s ease-in 0s";
    if (window.innerWidth <= 899) {
      dLock.style.transform = "translateX(24px)";
      dLock.style.backgroundColor = "#fe7200b3";
      dLock.classList.add("lck");
      return false;
    }
    dLock.style.transform = "translateX(60px)";
    dLock.style.backgroundColor = "#fe7200b3";
    dLock.classList.add("lck");
    return;
  };

  const handleServ = (e) => {
    if (serv != "" && amount != "") {
      e.target.style.backgroundColor = "#3cb95d";
      setLoading(true);
      setOpen(true);
      setErrType("primary");
      setErrVal("Redirecting...");
      setTimeout(() => {
        if (dAgent) {
          navigate("/transaction/start?s=" + serv + "&a=" + amount);
        } else {
          navigate("/sign-up?s=" + serv + "&a=" + amount);
        }
      }, 3000);
      return;
    }
    if (serv == "" && amount == "") {
      setOpen(true);
      setErrType("error");
      setErrVal("All fields are required");
      return false;
    }
    if (serv == "") {
      setOpen(true);
      setErrType("error");
      setErrVal("I'm field is required");
      return false;
    }
    if (amount == "") {
      setOpen(true);
      setErrType("error");
      setErrVal("For field is required");
      return false;
    }
  };

  useEffect(() => {
    setIsValid(true);
    const ditems = JSON.parse(localStorage.getItem("agent"));
    setDAgent(ditems);
  }, []);
  return (
    <main
      style={{
        backgroundColor: "#fff",
        width: "100vw",
        minHeight: "100vh",
      }}>
      {console.log(dAgent?._id)}
      {dAgent?._id === undefined ? <LandingHeader /> : <DashboardHeader />}
      <Container>
        <Box
          id="bannerBox"
          sx={{ flexGrow: 1, marginTop: 6, marginBottom: 0 }}
          onMouseOver={handleBanner}>
          <div
            style={{ position: "absolute", top: 0, right: 0, opacity: 0.075 }}>
            <HandshakeOutlined
              style={{ fontSize: "760px", color: "#fe7200" }}
            />
          </div>
          <Grid2 container style={{ position: "relative", zIndex: 1 }}>
            <Grid2 xs={12} md={6}>
              <h1
                className="sm:text-[35px] text-[28px] mb-2 sm:mt-5 mt-2"
                style={{
                  color: "#063851",
                  fontWeight: 900,
                  lineHeight: "1.2",
                  fontFamily: "Montserrat Bold",
                }}>
                Protect yourself with TranSecure Escrow for every online deal
              </h1>
              <h2
                style={{
                  color: "#000",
                  fontSize: "16px",
                  fontWeight: 500,
                  fontFamily: "Montserrat",
                  marginBottom: 31,
                  opacity: 0.8,
                }}>
                TranSecure Escrow ensures safe buying and selling, no
                chargebacks, only truly secure payments.
              </h2>
              <div className=" flex space-x-2 mb-4">
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel
                    id="iamlabel"
                    style={
                      serv != ""
                        ? {
                            fontFamily: "Montserrat",
                            fontWeight: 700,
                            fontSize: "18px",
                            zIndex: 10,
                            backgroundColor: "#fff",
                          }
                        : {
                            color: "#1d284a",
                            fontFamily: "Montserrat",
                            fontWeight: 700,
                            fontSize: "16px",
                            zIndex: 10,
                            backgroundColor: "#fff",
                          }
                    }>
                    I AM
                  </InputLabel>
                  <Select
                    labelId="iamlabel"
                    id="iamselect"
                    open={openIam}
                    onClose={() => setOpenIam(false)}
                    onOpen={() => setOpenIam(true)}
                    value={serv}
                    label="I'm"
                    onChange={(e) => setServ(e.target.value)}
                    style={{
                      color: "#1d284a",
                      background: "#fff",
                      fontFamily: "Montserrat",
                    }}
                    variant="outlined">
                    <MenuItem value="buy"> Buying</MenuItem>
                    <MenuItem value="sell">Selling</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ width: "100%" }}>
                  <TextField
                    disabled
                    id="outlined-disabled"
                    defaultValue="Contracted Services"
                    style={{ fontFamily: "Montserrat" }}
                    variant="outlined">
                    Contracted Services
                  </TextField>
                </FormControl>
              </div>
              <div class="">
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel
                    htmlFor="outlined-adornment-amount"
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: 700,
                      color: "initial",
                    }}>
                    For
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    startAdornment={
                      <InputAdornment position="start">₦</InputAdornment>
                    }
                    label="For"
                    style={{ color: "#1d284a", fontFamily: "Montserrat" }}
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    onBlur={async () => {
                      let price = await amount;
                      let iprice = parseFloat(price).toFixed(2);
                      setAmount(iprice);
                    }}
                  />
                </FormControl>
                <FormControl
                  sx={
                    window.innerWidth <= 899
                      ? { width: "100%", marginBottom: "16px", marginTop: 3 }
                      : { marginTop: 3, marginBottom: 3 }
                  }>
                  <LoadingButton
                    size="large"
                    color="success"
                    onClick={handleServ}
                    loading={loading}
                    loadingPosition="end"
                    endIcon={<ExitToAppTwoTone />}
                    variant="contained"
                    style={{
                      backgroundColor: "#063852",
                      color: "#fff",
                      fontFamily: "Montserrat",
                      padding: "10px  24px ",
                      height: 55,
                      fontWeight: 700,
                    }}
                    onMouseOver={(e) =>
                      (e.target.style.backgroundColor = "#ff7200")
                    }
                    onMouseOut={(e) =>
                      (e.target.style.backgroundColor = "#063852")
                    }>
                    Get started now
                  </LoadingButton>
                </FormControl>
              </div>
            </Grid2>
            <Grid2 container xs={12} md={6}>
              <Grid2 xs={12} md={5} style={{ zIndex: 9 }}>
                <Box sx={window.innerWidth <= 899 && { paddingTop: "60px" }}>
                  <Slide
                    in={IsValid}
                    direction="down"
                    timeout={timedOut}
                    onEntered={handleBannerEx}>
                    <div>
                      <Fade in={IsValid} timeout={timedOut}>
                        <div
                          id="synLck"
                          style={{
                            backgroundColor: "rgba(124,182,206,.7)",
                            paddingTop: "35px",
                            borderRadius: "100%",
                            width: "200px",
                            height: "200px",
                            textAlign: "center",
                            position: "relative",
                          }}>
                          <SyncLockTwoTone
                            style={{
                              fontSize: "120px",
                              color: "#fbf391",
                              filter:
                                "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))",
                            }}
                          />
                        </div>
                      </Fade>
                    </div>
                  </Slide>
                </Box>
              </Grid2>
              <Grid2 xs={12} md={7}>
                <Box>
                  <div
                    style={
                      window.innerWidth <= 899
                        ? { textAlign: "left", marginTop: 35, marginLeft: 15 }
                        : { textAlign: "left", marginTop: 35, marginLeft: 0 }
                    }>
                    <span
                      style={{
                        color: "#063851",
                        fontFamily: "Montserrat",
                        fontSize: "24px",
                        fontWeight: 700,
                      }}>
                      Complete protection for merchandise transactions
                    </span>
                  </div>
                  <List
                    sx={
                      window.innerWidth <= 899
                        ? {
                            color: "#fff",
                            marginTop: "20px",
                            marginBottom: "40px",
                            background: "#063851",
                            borderRadius: "4px",
                          }
                        : {
                            color: "#fff",
                            marginTop: "20px",
                            background: "#063851",
                            borderRadius: "4px",
                          }
                    }>
                    <ListItemButton>
                      <ListItemIcon style={{ minWidth: "35px" }}>
                        <CheckCircle style={{ color: "#fff" }} />
                      </ListItemIcon>
                      <ListItemText primary="Buyer and seller agree on terms" />
                    </ListItemButton>
                    <ListItemButton>
                      <ListItemIcon style={{ minWidth: "35px" }}>
                        <CheckCircle style={{ color: "#fff" }} />
                      </ListItemIcon>
                      <ListItemText primary="Buyer pays TranSecure Escrow" />
                    </ListItemButton>
                    <ListItemButton>
                      <ListItemIcon style={{ minWidth: "35px" }}>
                        <CheckCircle style={{ color: "#fff" }} />
                      </ListItemIcon>
                      <ListItemText primary="Seller drops off merchandise to TranSecure Escrow" />
                    </ListItemButton>
                    <ListItemButton>
                      <ListItemIcon style={{ minWidth: "35px" }}>
                        <CheckCircle style={{ color: "#fff" }} />
                      </ListItemIcon>
                      <ListItemText primary="TranSecure Escrow inspects & approves goods or services" />
                    </ListItemButton>
                    <ListItemButton>
                      <ListItemIcon style={{ minWidth: "35px" }}>
                        <CheckCircle style={{ color: "#fff" }} />
                      </ListItemIcon>
                      <ListItemText primary="TranSecure Escrow pays the seller" />
                    </ListItemButton>
                    <ListItemButton>
                      <ListItemIcon style={{ minWidth: "35px" }}>
                        <CheckCircle style={{ color: "#fff" }} />
                      </ListItemIcon>
                      <ListItemText primary="Buyer gets the merchandise" />
                    </ListItemButton>
                  </List>
                </Box>
              </Grid2>
            </Grid2>
          </Grid2>
        </Box>
      </Container>
      <EscrowSteps />
      <WhyUs />
      <Testimonials />
      <Faq />
      <Contact />
      <LandingFooter />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          id="snckErr"
          onClose={handleClose}
          severity={errType}
          sx={{ width: "100%" }}>
          {errVal}
        </Alert>
      </Snackbar>
    </main>
  );
};
