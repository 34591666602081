import React, { useState } from "react";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import styles from "./Index.module.css";
import { FormControl } from "@mui/material";

export const FeeCalcModal = ({fopen,setFOpen}) => {
  const handleOpen = () => setFOpen(true);
  const handleClose = () => setFOpen(false);

  const getStarted = () => {
    setFOpen(false);
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={fopen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={fopen}>
          <Box className={styles.welcomeBox}>
            <Typography id="transition-modal-title" variant="subtitle1" component="p" style={{color:"#1d284a", fontWeight:600,fontFamily:"Montserrat",marginBottom:"20px"}}>
            How are the totals calculated?
            </Typography>
            <Box>
              <Typography id="transition-modal-description" variant="subtitle2" component="p" style={{color:"#000", fontWeight:600,fontFamily:"Montserrat",marginBottom:"5px"}}>Buyer price:</Typography>
              <Typography id="transition-modal-description" component="p" variant="caption" style={{color:"#6e6893",fontWeight:600, fontFamily:"Montserrat",marginBottom:"10px",}}>This is the estimated amount that the buyer will pay for inclusive of fees.</Typography>
            </Box>
            <Box>
              <Typography id="transition-modal-description" variant="subtitle2" component="p" style={{color:"#000", fontWeight:600,fontFamily:"Montserrat",marginBottom:"5px"}}>Seller proceeds:</Typography>
              <Typography id="transition-modal-description" component="p" variant="caption" style={{color:"#6e6893",fontWeight:600, fontFamily:"Montserrat",marginBottom:"10px",}}>This is the estimated amount that the seller will receive minus fees.</Typography>
            </Box>
            <FormControl sx={{mt:1,display:"block",textAlign:"right",margin:"40px 0px 20px"}}>
            <Button variant="text" onClick={handleClose} style={{textTransform:"initial",fontFamily:"Montserrat",fontWeight:600}}>Got it</Button>
            </FormControl>   
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}