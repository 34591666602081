import React from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
} from "@mui/material";
import { Business, Explore, Security } from "@mui/icons-material";

const AboutUsContent = () => {
  return (
    <Box className="my-8 sm:w-[85%] w-[95%] m-auto ">
      <section className="mb-8">
        <h1 className="text-3xl font-bold mb-4">
          Introducing TranSecure Escrow
        </h1>
        <p className="mb-4">
          In the dynamic realm of e-commerce, where issues like fraud and data
          breaches loom large, TranSecure Escrow emerges as the beacon of hope
          for online retailers and shoppers alike, ensuring trust and security.
        </p>{" "}
        <br />
        <h2 className="text-2xl font-bold mb-4">Main Objectives</h2>
        <div>
          <ul className="list-disc pl-6">
            <li>
              TranSecure Escrow's main objective is to serve as a trusted
              intermediary, protecting both purchasers and sellers against
              possible fraud.
            </li>
            <li>
              This is done by holding funds in escrow until both parties fulfill
              their obligations stated in the contract, instilling confidence
              and eliminating the fears associated with online transactions.
            </li>
            <li>
              Our stringent security measures and processes ensure a secure and
              reliable platform for all users.
            </li>
          </ul>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Serving the Nigerian Market</h2>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Card className="shadow-md flex items-center justify-center flex-col">
              <img
                src={require("../../assets/hr.jpg")}
                alt="Nigerian Market"
                className="sm:w-[70%] w-full h-auto"
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={4}>
              {renderCard(
                "Expand Worldwide",
                "Our mission includes the expansion of our escrow services to customers worldwide, ensuring that people from different corners of the globe can benefit from secure transactions.",
                <Explore className="text-[#fe7200]" />
              )}
              {renderCard(
                "Global Collaboration",
                "We are dedicated to collaborating with international partners to fuel our global expansion. By fostering partnerships with like-minded organizations, we aim to extend our reach and impact.",
                <Business className="text-[#fe7200]" />
              )}
              {renderCard(
                "Enhance Trust",
                "Our commitment to trust-building goes beyond borders. We actively work to enhance trust among online communities, ensuring that people can shop and trade with confidence.",
                <Security className="text-[#fe7200]" />
              )}
              {renderCard(
                "Innovation",
                "We're at the forefront of innovation in online escrow services. With a team of experts, we continuously explore new technologies and strategies to stay ahead in the industry.",
                <Explore className="text-[#fe7200]" />
              )}
              {renderCard(
                "User-Centric Approach",
                "Our user-centric approach ensures that the needs and concerns of our clients are at the forefront. We tailor our services to provide a personalized experience for every user.",
                <Business className="text-[#fe7200]" />
              )}
              {renderCard(
                "Transparent Transactions",
                "Transparency is a cornerstone of our operations. We believe in open and clear transactions, ensuring that every party involved is aware of the terms and conditions.",
                <Security className="text-[#fe7200]" />
              )}
            </Grid>
          </Grid>
        </Grid>
      </section>
    </Box>
  );
};

const renderCard = (title, description, icon) => (
  <Grid item xs={12} md={6}>
    <Card className="shadow-md">
      <CardContent>
        <Box display="flex" alignItems="center" className="space-x-2">
          <Box>{icon}</Box>
          <Typography
            variant="h5"
            component="div"
            sx={{ fontSize: "18px", fontWeight: "bold" }}>
            {title}
          </Typography>
        </Box>
        <div className="mt-3">
          <Typography color="textSecondary">{description}</Typography>
        </div>
      </CardContent>
    </Card>
  </Grid>
);

export default AboutUsContent;
