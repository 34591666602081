import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonLink,
  DashboardHeader,
  FlexRow,
  Input,
} from "../../components";
import { useUser } from "../../hooks";
import { Link } from "react-router-dom";
import { LandingHeader } from "../../components/Landing";
import styles from "./Index.module.css";
import {
  Alert,
  Box,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import { ArrowBack, ExitToAppTwoTone } from "@mui/icons-material";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import img from "../../assets/mailsent.svg";

export const Verify = () => {
  const [dAgent, setDAgent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    const ditems = JSON.parse(localStorage.getItem("agent"));
    setDAgent(ditems);
  }, []);

  const sendVerifyEmail = async () => {
    setLoading(true);
    await axios
      .post(
        `https://transecure.onrender.com/api/v1/users/sendVerifyUserEmail`,
        {
          email: dAgent.email,
        }
      )
      .then((res) => {
        setLoading(false);
        setSuccess(true);
        setError(false);
      })
      .catch((err) => {
        setLoading(false);
        setSuccess(false);
        setError(true);
        console.log(err);
      });
  };

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: "#f5f7f9",
        width: "100vw",
        minHeight: "100vh",
      }}>
      {dAgent?._id === undefined ? <LandingHeader /> : <DashboardHeader />}

      <Box className="sm:py-10 py-4 sm:px-28 px-5 bg-blue-100">
        <Box className={` flex items-center space-x-3`}>
          <Link to="/profile">
            <ArrowBack className="text-[25px]" />
          </Link>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "25px",
              opacity: "0.7",
              "@media(max-width: 575px)": {
                fontSize: "14px",
              },
            }}>
            VERIFY ACCOUNT
          </Typography>
        </Box>
      </Box>

      <Box className="sm:w-[85%] m-auto w-[95%]">
        {dAgent?.emailVerification === "verified" ? (
          <Box className="shadow-md rounded-md sm:px-16 px-5 py-20 mt-10 sm:w-[50%]  w-full m-auto">
            <Box className="flex items-center space-x-2">
              <Typography
                sx={{ fontWeight: "bold", fontSize: "25px", margin: "3% 0%" }}>
                Your Account is Verified
              </Typography>
              <img
                alt="verified"
                src={require("../../assets/verified.png")}
                width="27px"
              />
            </Box>
          </Box>
        ) : (
          <Box className="shadow-md rounded-md sm:px-16 px-5 py-20 mt-10 sm:w-[50%]  w-full m-auto">
            <Typography
              sx={{ fontWeight: "bold", fontSize: "25px", margin: "3% 0%" }}>
              Please verify your account
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                "@media(max-width: 575px)": {
                  fontSize: "14px",
                },
              }}>
              We protect both sides of the transaction by verifying the identity
              of all users. Your verified identity must be the same as the
              account holder's name on any bank account used to pay or receive
              funds from TransecureEscrow.com
              <br />
            </Typography>

            <Box className="mt-5">
              {!loading && success === true ? (
                <>
                  <Alert onClose={() => {}}>
                    {" "}
                    An Email has been sent to your email{" "}
                    <span>{dAgent.email}</span>
                  </Alert>

                  <Box className="flex flex-col items-center justify-center my-5">
                    <img alt="sent" src={img} className="sm:w-[25%] w-[50%]" />
                  </Box>
                </>
              ) : !loading && error === true ? (
                <Alert severity="error" onClose={() => {}}>
                  {" "}
                  Unable to verify your Email, please check your network and
                  click the button to try again
                </Alert>
              ) : (
                ""
              )}
            </Box>

            <Box
              className="flex flex-col items-center justify-center mt-16 text-[#fe7200]"
              color="#fe7200"></Box>

            <LoadingButton
              size="small"
              color="success"
              onClick={sendVerifyEmail}
              endIcon={!loading && <ExitToAppTwoTone />}
              variant="contained"
              style={{
                backgroundColor: "#fe7200",
                color: "#fff",
                fontFamily: "Montserrat",
                padding: "10px  24px ",
                height: 55,
                fontWeight: 700,
                width: "100%",
                margin: "auto",
                "@media (min-width: 567px)": {
                  width: "100%",
                },
              }}
              onMouseOver={(e) => (e.target.style.backgroundColor = "#063851")}
              onMouseOut={(e) => (e.target.style.backgroundColor = "#fe7200")}>
              {loading ? (
                <CircularProgress size={34} sx={{ color: "#fff" }} />
              ) : (
                "Send Verification Link"
              )}
            </LoadingButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};
