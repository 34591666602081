import React, { useState, useEffect, useContext } from "react";
import { Box, Container, Snackbar, Typography, Tab } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import { DashboardHeader } from "../../components";
import { LandingFooter } from "../../components/Landing";
import TabView from "./Tab";
import { AgentContext } from "../../contexts";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const isEmail = (email) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
export const Transactions = () => {
  const { agent, setAgent } = useContext(AgentContext);
  console.log(agent);
  const theUser = JSON.parse(localStorage.getItem("agent"));
  const [userId, setUserId] = useState(theUser?._id);
  const [uEmail, setUEmail] = useState(theUser?.email);
  const [tabValue, setTabValue] = React.useState("all");
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleSearch = () => {};
  const [open, setOpen] = useState(false);
  const [errVal, setErrVal] = useState("");
  const [errType, setErrType] = useState("");

  const handleClose = () => {
    setOpen(false);
    setErrType("");
    setErrVal("");
  };

  
  
  
  useEffect(() => {
    (
      async function renderCheckout() {
        setUserId(theUser?._id);
        setUEmail(theUser?.email);
  }
  )();
    }, []);
  return (
    <main
      style={{
        backgroundColor: "#fff",
        width: "100vw",
        minHeight: "100vh",
      }}
    >
      <DashboardHeader />
      <Container
        style={{
          backgroundColor: "#fff",
          border: "1px solid #e8eef2",
          borderBottom: "none",
        }}
        maxWidth="xl"
      >
        <Box
          sx={
            window.innerWidth <= 899
              ? { minWidth: 275, padding: "24px 10px" }
              : { padding: "20px 80px", margin: "40px auto" }
          }
        >
          <Typography
            variant="h4"
            component="h4"
            style={{
              fontFamily: "Montserrat Bold",
              fontWeight: "bold",
              color: "#1d284a",
              marginBottom: "16px",
            }}
          >
            My Transactions
          </Typography>
          <TabView tabValue={tabValue} userId={userId} email={uEmail} handleChange={handleChange} />
        </Box>
      </Container>
      <LandingFooter />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          id="snckErr"
          onClose={handleClose}
          severity={errType}
          sx={{ width: "100%" }}
        >
          {errVal}
        </Alert>
      </Snackbar>
    </main>
  );
};
