import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import styles from "./Index.module.css";
import { LoadingButton } from "@mui/lab";
import { ExitToAppTwoTone } from "@mui/icons-material";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

const PolicyHero = () => {
  const [dAgent, setDAgent] = useState([]);
  useEffect(() => {
    const ditems = JSON.parse(localStorage.getItem("agent"));
    setDAgent(ditems);
  }, []);
  return (
    <Box className="bg-[#fafafa]">
      <Box className={styles.container}>
        <Grid container>
          <Grid item sm={12} md={6} className="">
            <Box className="my-36">
              <Typography variant="h3" sx={{ fontWeight: "bold" }}>
                Our Policies
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ fontSize: "13px", lineHeight: "1.3rem", color: "#333" }}>
                We don't simply function as an online escrow service; we stand
                as the paramount online escrow service
              </Typography>

              <Box className="mt-10">
                <Link to={`${dAgent ? "/transaction/start" : "/login"}`}>
                  <LoadingButton
                    size="small"
                    color="success"
                    endIcon={<ExitToAppTwoTone />}
                    variant="contained"
                    style={{
                      backgroundColor: "#fe7200",
                      color: "#fff",
                      fontFamily: "Montserrat",
                      padding: "10px  24px ",
                      height: 45,
                      fontWeight: 700,
                    }}
                    onMouseOver={(e) =>
                      (e.target.style.backgroundColor = "#063851")
                    }
                    onMouseOut={(e) =>
                      (e.target.style.backgroundColor = "#fe7200")
                    }>
                    Get started now
                  </LoadingButton>
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            sm={12}
            md={6}
            className="sm:flex hidden flex-col items-center justify-center">
            <img
              alt="about"
              src={require("../../assets/whyus.jpg")}
              className="sm:block hidden"
              width="50%"
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PolicyHero;
