import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonLink,
  DashboardHeader,
  FlexRow,
  Input,
} from "../../components";
import { useUser } from "../../hooks";
import { Link } from "react-router-dom";
import { LandingHeader } from "../../components/Landing";
import styles from "./Index.module.css";
import { Box, Container, Typography } from "@mui/material";
import {
  ArrowBack,
  ArrowRightAlt,
  Person,
  VerifiedUser,
} from "@mui/icons-material";
import ProfileForm from "./ProfileForm";

export const UpdateProfile = () => {
  const [dAgent, setDAgent] = useState([]);
  useEffect(() => {
    const ditems = JSON.parse(localStorage.getItem("agent"));
    setDAgent(ditems);
  }, []);

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: "#f5f7f9",
        width: "100vw",
        minHeight: "100vh",
      }}>
      {dAgent?._id === undefined ? <LandingHeader /> : <DashboardHeader />}

      <Box className="sm:py-10 py-4 sm:px-28 px-5 bg-blue-100">
        <Box className={`${styles.container} flex items-center space-x-3`}>
          <Link to="/profile">
            <ArrowBack className="text-[25px]" />
          </Link>
          <Typography
            sx={{ fontWeight: "bold", fontSize: "18px", opacity: "0.7" }}>
            UPDATE PROFILE
          </Typography>
        </Box>
      </Box>

      <Box className={styles.container}>
        <ProfileForm agent={dAgent} />
      </Box>
    </Box>
  );
};
