import React, { useMemo, useState } from "react";
import { useUser } from "../../hooks";
import { LandingHeader, LandingFooter } from "../../components/Landing";
import styles from "./Index.module.css";
import {
  Box,
  Container,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Button,
  Snackbar,
  List,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  useTheme,
  createTheme,
  Fade,
  Slide,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {
  SyncLockTwoTone,
  HandshakeOutlined,
  ExitToAppTwoTone,
  CheckCircle,
  HandshakeRounded,
  RequestQuoteRounded,
  WorkHistoryRounded,
  TaskAltRounded,
  CurrencyExchangeRounded,
} from "@mui/icons-material";
import { useRef } from "react";
import { useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import * as locales from "@mui/material/locale";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export const Dashboard = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let [IsValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [serv, setServ] = useState("");
  const [amount, setAmount] = useState("");
  const [open, setOpen] = useState(false);
  const [openIam, setOpenIam] = useState(false);
  const [errVal, setErrVal] = useState("");
  const [errType, setErrType] = useState("");
  const navigate = useNavigate();

  const timedOut = 2000;
  const handleClose = () => {
    setOpen(false);
    setErrType("");
    setErrVal("");
  };

  const handleBanner = () => {
    var dLock = document.getElementById("synLck");
    let resd = dLock.classList.contains("lck");
    dLock.style.transition = "all 3s ease-in 0s";
    if (dLock.offsetTop > window.innerHeight && window.innerWidth >= 900) {
      dLock.classList.add("lck");
      dLock.style.transform = "translate(60px,0px)";
      return false;
    }
    if (resd && window.innerWidth >= 900) {
      setTimeout(() => {
        dLock.classList.remove("lck");
        dLock.style.transform = "translate(60px,250px)";
      }, 3500);
    }
    if (!resd && window.innerWidth >= 900) {
      setTimeout(() => {
        dLock.classList.add("lck");
        dLock.style.transform = "translate(60px,0px)";
      }, 3500);
    }
    if (dLock.offsetTop > window.innerHeight && window.innerWidth <= 899) {
      dLock.classList.add("lck");
      dLock.style.transform = "translate(24px,0px)";
      return false;
    }
    if (resd && window.innerWidth <= 899) {
      setTimeout(() => {
        dLock.classList.remove("lck");
        dLock.style.transform = "translate(104px,0px)";
      }, 3500);
    }
    if (!resd && window.innerWidth <= 899) {
      setTimeout(() => {
        dLock.classList.add("lck");
        dLock.style.transform = "translate(24px,0px)";
      }, 3500);
    }
  };

  const handleBannerEx = async () => {
    var dLock = document.getElementById("synLck");
    dLock.style.transition = "all 2s ease-in 0s";
    if (window.innerWidth <= 899) {
      dLock.style.transform = "translateX(24px)";
      dLock.classList.add("lck");
      return false;
    }
    dLock.style.transform = "translateX(60px)";
    dLock.classList.add("lck");
    return;
  };

  const handleServ = (e) => {
    if (serv != "" && amount != "") {
      e.target.style.backgroundColor = "#3cb95d";
      setLoading(true);
      setOpen(true);
      setErrType("primary");
      setErrVal("Redirecting...");
      setTimeout(() => {
        navigate("/sign-up");
      }, 3000);
      return;
    }
    if (serv == "" && amount == "") {
      setOpen(true);
      setErrType("error");
      setErrVal("All fields are required");
      return false;
    }
    if (serv == "") {
      setOpen(true);
      setErrType("error");
      setErrVal("I'm field is required");
      return false;
    }
    if (amount == "") {
      setOpen(true);
      setErrType("error");
      setErrVal("For field is required");
      return false;
    }
  };

  const TabNavItem = ({ idx, title, manageTab, setManageTab }) => {
    const handleClick = () => {
      setManageTab(idx);
    };

    return (
      <li
        onClick={handleClick}
        className={manageTab === idx ? "pointer" : ""}
        style={{
          color: idx == manageTab ? "#19201D" : "#6F7975",
          fontWeight: "500",
          paddingBottom: 22,
          borderBottom:
            idx === manageTab ? "4px solid #19201D" : "4px solid #fff",
          cursor: "pointer",
        }}>
        {title}
      </li>
    );
  };
  const TabContent = ({ idx, manageTab, children }) => {
    return manageTab === idx ? (
      <div className="TabContent">{children}</div>
    ) : null;
  };

  const services = [
    {
      img: "security",
      title: "Encrypted information",
      text: `We provide you with a secure space to share and manage all your information.`,
    },
    {
      img: "affordable",
      title: "Affordable offers",
      text: `Our charges are unbeatable considering the services we provide.`,
    },
    {
      img: "data",
      title: "Effective management of large data",
      text: `Not minding the size of information you enter, we provide stress free handling.`,
    },
    {
      img: "support-service",
      title: "Suport Team",
      text: `Our support team are hands-on and we provide you with instant 24hrs support.`,
    },
  ];
  useEffect(() => {
    setIsValid(true);
  }, []);
  return (
    <main
      style={{
        backgroundColor: "#0d3bb2",
        width: "100vw",
        minHeight: "100vh",
      }}>
      <LandingHeader />
      <Container>
        <Box
          id="bannerBox"
          sx={{ flexGrow: 1, marginTop: 6, marginBottom: 0 }}
          onMouseOver={handleBanner}>
          <div
            style={{ position: "absolute", top: -60, right: 0, opacity: 0.07 }}>
            <HandshakeOutlined style={{ fontSize: "760px", color: "#fff" }} />
          </div>
          <Grid2 container style={{ position: "relative", zIndex: 1 }}>
            <Grid2 xs={12} md={6}>
              <h1
                style={{
                  color: "#fff",
                  fontSize: "64px",
                  fontFamily: "Montserrat Bold",
                  fontWeight: 700,
                  lineHeight: "1.07",
                  marginBottom: 15,
                }}>
                Never buy or sell <br />
                online without <br />
                using <span style={{ color: "#61dafb" }}>REACT</span>
              </h1>
              <h2
                style={{
                  color: "#fff",
                  fontSize: "16px",
                  fontWeight: 400,
                  fontFamily: "Montserrat",
                  marginBottom: 31,
                }}>
                With REACT you can buy and sell anything safely <br />
                without the risk of chargebacks. <br />
                Truly secure payments.
              </h2>
              <div className="buyorsell">
                <FormControl
                  sx={
                    window.innerWidth <= 899
                      ? { width: "40%", marginBottom: "16px" }
                      : { m: 1, minWidth: 150 }
                  }>
                  <InputLabel
                    id="iamlabel"
                    style={
                      serv != ""
                        ? {
                            color: "#fff",
                            fontFamily: "Montserrat",
                            fontWeight: 700,
                          }
                        : {
                            color: "#1d284a",
                            fontFamily: "Montserrat",
                            fontWeight: 700,
                          }
                    }>
                    I'm
                  </InputLabel>
                  <Select
                    labelId="iamlabel"
                    id="iamselect"
                    open={openIam}
                    onClose={() => setOpenIam(false)}
                    onOpen={() => setOpenIam(true)}
                    value={serv}
                    label="I'm"
                    onChange={(e) => setServ(e.target.value)}
                    style={{
                      color: "#1d284a",
                      background: "#fff",
                      fontFamily: "Montserrat",
                    }}
                    variant="outlined">
                    <MenuItem value="buy">Buying</MenuItem>
                    <MenuItem value="sell">Selling</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  sx={
                    window.innerWidth <= 899
                      ? { width: "60%", marginBottom: "16px" }
                      : { m: 1, minWidth: 180 }
                  }>
                  <TextField
                    disabled
                    id="outlined-disabled"
                    defaultValue="Contracted Services"
                    style={{ fontFamily: "Montserrat" }}
                    variant="outlined">
                    Contracted Services
                  </TextField>
                </FormControl>
              </div>
              <div class="buyorsell">
                <FormControl
                  sx={
                    window.innerWidth <= 899
                      ? { width: "100%", marginBottom: "16px" }
                      : { m: 1 }
                  }>
                  <InputLabel
                    htmlFor="outlined-adornment-amount"
                    style={{
                      color: "#fff",
                      fontFamily: "Montserrat",
                      fontWeight: 700,
                    }}>
                    For
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    startAdornment={
                      <InputAdornment position="start">₦</InputAdornment>
                    }
                    label="For"
                    style={{
                      color: "#1d284a",
                      fontFamily: "Montserrat",
                      background: "#fff",
                    }}
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </FormControl>
                <FormControl
                  sx={
                    window.innerWidth <= 899
                      ? { width: "100%", marginBottom: "16px" }
                      : { m: 1 }
                  }>
                  <LoadingButton
                    size="large"
                    color="success"
                    onClick={handleServ}
                    loading={loading}
                    loadingPosition="end"
                    endIcon={<ExitToAppTwoTone />}
                    variant="contained"
                    style={{
                      backgroundColor: "#3cb95d",
                      color: "#fff",
                      fontFamily: "Montserrat",
                      padding: "10px 24px",
                      height: 55,
                      fontWeight: 700,
                    }}
                    onMouseOver={(e) =>
                      (e.target.style.backgroundColor = "#09f")
                    }
                    onMouseOut={(e) =>
                      (e.target.style.backgroundColor = "#3cb95d")
                    }>
                    Get started now
                  </LoadingButton>
                </FormControl>
              </div>
            </Grid2>
            <Grid2 container xs={12} md={6}>
              <Grid2 xs={12} md={5} style={{ zIndex: 9 }}>
                <Box sx={window.innerWidth <= 899 && { paddingTop: "60px" }}>
                  <Slide
                    in={IsValid}
                    direction="down"
                    timeout={timedOut}
                    onEntered={handleBannerEx}>
                    <div>
                      <Fade in={IsValid} timeout={timedOut}>
                        <div
                          id="synLck"
                          style={{
                            backgroundColor: "#6cebe9",
                            paddingTop: "35px",
                            borderRadius: "100%",
                            width: "200px",
                            height: "200px",
                            textAlign: "center",
                            position: "relative",
                          }}>
                          <SyncLockTwoTone
                            style={{
                              fontSize: "120px",
                              color: "#29acb3",
                              filter:
                                "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))",
                            }}
                          />
                        </div>
                      </Fade>
                    </div>
                  </Slide>
                </Box>
              </Grid2>
              <Grid2 xs={12} md={7}>
                <Box>
                  <div
                    style={
                      window.innerWidth <= 899
                        ? { textAlign: "left", marginTop: 35, marginLeft: 15 }
                        : { textAlign: "left", marginTop: 35, marginLeft: 30 }
                    }>
                    <span
                      style={{
                        color: "#fff",
                        fontFamily: "Montserrat",
                        fontSize: "24px",
                        fontWeight: 700,
                      }}>
                      Complete protection for merchandise <br />
                      transactions
                    </span>
                  </div>
                  <List
                    sx={
                      window.innerWidth <= 899
                        ? {
                            color: "#fff",
                            marginTop: "20px",
                            marginBottom: "40px",
                            background: "rgba(0,0,0,.3)",
                            borderRadius: "4px",
                          }
                        : {
                            color: "#fff",
                            marginTop: "20px",
                            background: "rgba(0,0,0,.3)",
                            borderRadius: "4px",
                          }
                    }>
                    <ListItemButton>
                      <ListItemIcon style={{ minWidth: "35px" }}>
                        <CheckCircle style={{ color: "#fff" }} />
                      </ListItemIcon>
                      <ListItemText primary="Buyer and seller agree on terms" />
                    </ListItemButton>
                    <ListItemButton>
                      <ListItemIcon style={{ minWidth: "35px" }}>
                        <CheckCircle style={{ color: "#fff" }} />
                      </ListItemIcon>
                      <ListItemText primary="Buyer pays React" />
                    </ListItemButton>
                    <ListItemButton>
                      <ListItemIcon style={{ minWidth: "35px" }}>
                        <CheckCircle style={{ color: "#fff" }} />
                      </ListItemIcon>
                      <ListItemText primary="Seller ships the merchandise" />
                    </ListItemButton>
                    <ListItemButton>
                      <ListItemIcon style={{ minWidth: "35px" }}>
                        <CheckCircle style={{ color: "#fff" }} />
                      </ListItemIcon>
                      <ListItemText primary="Buyer inspects & approves goods" />
                    </ListItemButton>
                    <ListItemButton>
                      <ListItemIcon style={{ minWidth: "35px" }}>
                        <CheckCircle style={{ color: "#fff" }} />
                      </ListItemIcon>
                      <ListItemText primary="React pays the seller" />
                    </ListItemButton>
                  </List>
                </Box>
              </Grid2>
            </Grid2>
          </Grid2>
        </Box>
      </Container>
      <Container
        style={{
          backgroundColor: "#fff",
          border: "1px solid #e8eef2",
          borderBottom: "none",
        }}
        maxWidth="xl">
        <Box>
          <div
            style={
              window.innerWidth <= 899
                ? {
                    padding: "58px 0vw",
                  }
                : {
                    padding: "58px 7vw",
                  }
            }>
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                textAlign: "center",
                marginBottom: 54,
              }}>
              <h2
                style={{
                  color: "#1d284a",
                  textAlign: "center",
                  fontFamily: "Montserrat",
                  fontSize: 34,
                  lineHeight: 1.18,
                  marginBottom: 20,
                }}>
                Over million of transactions protected with React
              </h2>
              <p
                className={styles.headings}
                style={{
                  color: "#1d284a",
                  textAlign: "center",
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  maxWidth: 780,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}>
                React is the most secure payment method from a counterparty risk
                perspective - safeguarding both buyer and seller, all funds
                transacted using escrow are kept in trust.
              </p>
            </div>
            <Grid2
              container
              sx={
                window.innerWidth <= 899
                  ? { padding: 0 }
                  : { padding: "0px 30px" }
              }>
              <Grid2 xs={12} sm={12} lg={2.4}>
                <Box
                  sx={
                    window.innerWidth <= 899
                      ? { textAlign: "left", display: "flex" }
                      : { textAlign: "center" }
                  }>
                  <HandshakeRounded
                    style={
                      window.innerWidth <= 899
                        ? {
                            fontSize: 120,
                            color: "#1355ff",
                            backgroundColor: "#F9FAFB",
                            overflow: "hidden",
                            padding: 20,
                            borderRadius: "50%",
                            marginBottom: 35,
                            textAlign: "center",
                            marginRight: "15px",
                          }
                        : {
                            fontSize: 120,
                            color: "#1355ff",
                            backgroundColor: "#F9FAFB",
                            overflow: "hidden",
                            padding: 20,
                            borderRadius: "50%",
                            marginBottom: 35,
                            textAlign: "center",
                          }
                    }
                  />
                  <h5
                    style={
                      window.innerWidth <= 899
                        ? {
                            textAlign: "left",
                            fontFamily: "Montserrat",
                            fontSize: 14,
                            fontWeight: 700,
                            paddingTop: "50px",
                          }
                        : {
                            textAlign: "center",
                            fontFamily: "Montserrat",
                            fontSize: 14,
                            fontWeight: 700,
                          }
                    }>
                    1. Buyer and Seller agree to terms
                  </h5>
                </Box>
              </Grid2>
              <Grid2 xs={12} sm={12} lg={2.4}>
                <Box
                  sx={
                    window.innerWidth <= 899
                      ? { textAlign: "left", display: "flex" }
                      : { textAlign: "center" }
                  }>
                  <RequestQuoteRounded
                    style={
                      window.innerWidth <= 899
                        ? {
                            fontSize: 120,
                            color: "#1355ff",
                            backgroundColor: "#F9FAFB",
                            overflow: "hidden",
                            padding: 20,
                            borderRadius: "50%",
                            marginBottom: 35,
                            textAlign: "center",
                            marginRight: "15px",
                          }
                        : {
                            fontSize: 120,
                            color: "#1355ff",
                            backgroundColor: "#F9FAFB",
                            overflow: "hidden",
                            padding: 20,
                            borderRadius: "50%",
                            marginBottom: 35,
                            textAlign: "center",
                          }
                    }
                  />
                  <h5
                    style={
                      window.innerWidth <= 899
                        ? {
                            textAlign: "left",
                            fontFamily: "Montserrat",
                            fontSize: 14,
                            fontWeight: 700,
                            paddingTop: "50px",
                          }
                        : {
                            textAlign: "center",
                            fontFamily: "Montserrat",
                            fontSize: 14,
                            fontWeight: 700,
                          }
                    }>
                    2. Buyer submits payment to React
                  </h5>
                </Box>
              </Grid2>
              <Grid2 xs={12} sm={12} lg={2.4}>
                <Box
                  sx={
                    window.innerWidth <= 899
                      ? { textAlign: "left", display: "flex" }
                      : { textAlign: "center" }
                  }>
                  <WorkHistoryRounded
                    style={
                      window.innerWidth <= 899
                        ? {
                            fontSize: 120,
                            color: "#1355ff",
                            backgroundColor: "#F9FAFB",
                            overflow: "hidden",
                            padding: 20,
                            borderRadius: "50%",
                            marginBottom: 35,
                            textAlign: "center",
                            marginRight: "15px",
                          }
                        : {
                            fontSize: 120,
                            color: "#1355ff",
                            backgroundColor: "#F9FAFB",
                            overflow: "hidden",
                            padding: 20,
                            borderRadius: "50%",
                            marginBottom: 35,
                            textAlign: "center",
                          }
                    }
                  />
                  <h5
                    style={
                      window.innerWidth <= 899
                        ? {
                            textAlign: "left",
                            fontFamily: "Montserrat",
                            fontSize: 14,
                            fontWeight: 700,
                            paddingTop: "50px",
                          }
                        : {
                            textAlign: "center",
                            fontFamily: "Montserrat",
                            fontSize: 14,
                            fontWeight: 700,
                          }
                    }>
                    3. Seller delivers goods or service to buyer
                  </h5>
                </Box>
              </Grid2>
              <Grid2 xs={12} sm={12} lg={2.4}>
                <Box
                  sx={
                    window.innerWidth <= 899
                      ? { textAlign: "left", display: "flex" }
                      : { textAlign: "center" }
                  }>
                  <TaskAltRounded
                    style={
                      window.innerWidth <= 899
                        ? {
                            fontSize: 120,
                            color: "#1355ff",
                            backgroundColor: "#F9FAFB",
                            overflow: "hidden",
                            padding: 20,
                            borderRadius: "50%",
                            marginBottom: 35,
                            textAlign: "center",
                            marginRight: "15px",
                          }
                        : {
                            fontSize: 120,
                            color: "#1355ff",
                            backgroundColor: "#F9FAFB",
                            overflow: "hidden",
                            padding: 20,
                            borderRadius: "50%",
                            marginBottom: 35,
                            textAlign: "center",
                          }
                    }
                  />
                  <h5
                    style={
                      window.innerWidth <= 899
                        ? {
                            textAlign: "left",
                            fontFamily: "Montserrat",
                            fontSize: 14,
                            fontWeight: 700,
                            paddingTop: "50px",
                          }
                        : {
                            textAlign: "center",
                            fontFamily: "Montserrat",
                            fontSize: 14,
                            fontWeight: 700,
                          }
                    }>
                    4. Buyer approves goods or services
                  </h5>
                </Box>
              </Grid2>
              <Grid2 xs={12} sm={12} lg={2.4}>
                <Box
                  sx={
                    window.innerWidth <= 899
                      ? { textAlign: "left", display: "flex" }
                      : { textAlign: "center" }
                  }>
                  <CurrencyExchangeRounded
                    style={
                      window.innerWidth <= 899
                        ? {
                            fontSize: 120,
                            color: "#1355ff",
                            backgroundColor: "#F9FAFB",
                            overflow: "hidden",
                            padding: 20,
                            borderRadius: "50%",
                            marginBottom: 35,
                            textAlign: "center",
                            marginRight: "15px",
                          }
                        : {
                            fontSize: 120,
                            color: "#1355ff",
                            backgroundColor: "#F9FAFB",
                            overflow: "hidden",
                            padding: 20,
                            borderRadius: "50%",
                            marginBottom: 35,
                            textAlign: "center",
                          }
                    }
                  />
                  <h5
                    style={
                      window.innerWidth <= 899
                        ? {
                            textAlign: "left",
                            fontFamily: "Montserrat",
                            fontSize: 14,
                            fontWeight: 700,
                            paddingTop: "50px",
                          }
                        : {
                            textAlign: "center",
                            fontFamily: "Montserrat",
                            fontSize: 14,
                            fontWeight: 700,
                          }
                    }>
                    5. React releases payment to seller
                  </h5>
                </Box>
              </Grid2>
              <Grid2
                xs={12}
                sx={
                  window.innerWidth <= 899
                    ? { margin: "0px 0px 54px", textAlign: "center" }
                    : { m: 8, textAlign: "center" }
                }>
                <FormControl>
                  <Button
                    size="large"
                    href="/sign-up"
                    style={{
                      backgroundColor: "#09f",
                      color: "white",
                      fontFamily: "Montserrat",
                      fontWeight: 700,
                      textAlign: "center",
                    }}>
                    Get started now
                  </Button>
                </FormControl>
              </Grid2>
            </Grid2>
          </div>
        </Box>
      </Container>
      <Container style={{ backgroundColor: "#F9FAFB" }} maxWidth="xl">
        <Grid2
          container
          sx={
            window.innerWidth <= 899
              ? {
                  backgroundColor: "#F9FAFB",
                  padding: "60px 0px",
                  margin: "auto",
                  borderRadius: "24px",
                }
              : {
                  backgroundColor: "#F9FAFB",
                  padding: "80px",
                  margin: "auto",
                  borderRadius: "24px",
                }
          }>
          <Grid2 xs={12} lg={4}>
            <Box>
              <div>
                <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                  <span
                    style={{
                      fontFamily: "Montserrat Bold",
                      color: "#919EAB",
                      fontSize: "12px",
                    }}>
                    Need Help?
                  </span>
                  <h3
                    style={{
                      fontFamily: "Montserrat Bold",
                      color: "#1d284a",
                      fontSize: "48px",
                      marginTop: "20px",
                      marginBottom: "20px",
                      lineHeight: 1.5,
                    }}>
                    Get In Touch
                  </h3>
                  <p
                    className={styles.headings}
                    style={{
                      color: "#637381",
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontWeight: 400,
                      marginLeft: "auto",
                      marginRight: "auto",
                      lineHeight: 1.75,
                    }}>
                    Contact our support team on{" "}
                    <span style={{ fontWeight: 700, color: "#1355ff" }}>
                      +1-234-567-8901
                    </span>{" "}
                    <br />
                    to find out if your transaction can be covered.
                  </p>
                  <FormControl
                    style={
                      window.innerWidth
                        ? { margin: "20px 0px" }
                        : { marginTop: 20 }
                    }>
                    <Button
                      size="large"
                      href="/sign-up"
                      style={{
                        backgroundColor: "#09f",
                        color: "white",
                        fontFamily: "Montserrat",
                        fontWeight: 700,
                        textAlign: "center",
                      }}>
                      Get started now
                    </Button>
                  </FormControl>
                </div>
              </div>
            </Box>
          </Grid2>
          <Grid2 xs={12} lg={6} lgOffset={"auto"}>
            <Box
              sx={
                window.innerWidth <= 899
                  ? { position: "relative", margin: "45px 0px 45px" }
                  : { position: "relative" }
              }>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31483147.216269102!2d7.354321163355572!3d11.987449108579398!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x10a06c0a948cf5d5%3A0x108270c99e90f0b3!2sAfrica!5e0!3m2!1sen!2sus!4v1674537983235!5m2!1sen!2sus"
                width="600"
                height="450"
                style={
                  window.innerWidth <= 899
                    ? {
                        maxWidth: "600px",
                        height: "450px",
                        width: "100%",
                        border: 0,
                        borderRadius: "14px",
                      }
                    : { border: 0, borderRadius: "14px" }
                }
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe>
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  left: "190px",
                  bottom: "280px",
                  backgroundColor: "transparent",
                  position: "absolute",
                }}>
                <div className="MuiBox-root css-0">
                  <svg
                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-xdn17z"
                    focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    style={{ fill: "red" }}>
                    <path d="M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3 c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9 C20.1,15.8,20.2,15.8,20.2,15.7z"></path>
                  </svg>
                </div>
              </div>
            </Box>
          </Grid2>
        </Grid2>
      </Container>
      <LandingFooter />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          id="snckErr"
          onClose={handleClose}
          severity={errType}
          sx={{ width: "100%" }}>
          {errVal}
        </Alert>
      </Snackbar>
    </main>
  );
};
