import React from "react";

const LegalContent = () => {
  return (
    <div className="bg-gray-100 min-h-screen p-8">
      <div className="container mx-auto">
        <h1 className="text-3xl font-semibold mb-4">Legal Information</h1>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Terms and Conditions</h2>
          <p>
            Please read these terms and conditions ("Terms") carefully before
            using our services operated by TransecureEscrow
          </p>

          <p>
            Acceptance of Terms By accessing or using the Service, you agree to
            be bound by these Terms. If you disagree with any part of the Terms,
            you may not use the Service.
            <br />
            <br />
            <b>User Accounts</b> <br />
            1.Registration : To access certain features of the Service, you may
            be required to register for an account. You agree to provide
            accurate, current, and complete information during the registration
            process and to update such information to keep it accurate, current,
            and complete. <br />
            <br />
            2.User Credentials: You are responsible for maintaining the
            confidentiality of your account and password, and you are solely
            responsible for all activities that occur under your account. <br />
            3.Termination: We reserve the right to terminate or suspend your
            account for any reason, without notice, at our sole discretion.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Privacy Policy</h2>
          <p>
            Insert your privacy policy content here. This should outline how you
            handle user data and provide information about cookies, data
            collection, and data protection.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Disclaimer</h2>
          <p>
            Include your disclaimer content here. This may include limitations
            of liability, warranties, and other disclaimers relevant to your
            website or services.
          </p>
        </section>
      </div>
    </div>
  );
};

export default LegalContent;
