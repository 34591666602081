import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Box, Container } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import faq from "../../assets/faq.svg";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function Faq() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const faqs = [
    {
      question: "How does TranSecure Escrow work for service transactions?",
      answer:
        "TranSecure Escrow provides a secure platform for service transactions. When you hire a service provider, your payment is held in escrow until the service is completed to your satisfaction. Once you confirm your satisfaction, the payment is released to the service provider.",
    },
    {
      question: "What types of services can I use TranSecure Escrow for?",
      answer:
        "You can use TranSecure Escrow for a wide range of services, from home maintenance and repairs to creative services like design and writing. Whether you need a plumber or a web developer, our platform ensures safe and transparent transactions.",
    },
    {
      question: "How do I know the service will meet my expectations?",
      answer:
        "TranSecure Escrow promotes quality assurance. Before the payment is released, you have the opportunity to review and confirm the service meets your expectations. This process ensures that you receive the service you've paid for.",
    },
    {
      question: "What if there's an issue with the service provided?",
      answer:
        "In case of any concerns, our platform offers a dispute resolution process. You can communicate with the service provider and reach a resolution. If needed, TranSecure Escrow will assist in finding a fair solution for both parties.",
    },
    {
      question:
        "Is using TranSecure Escrow for services more secure than traditional methods?",
      answer:
        "Absolutely. TranSecure Escrow minimizes risks associated with online service transactions. Your payment is held securely until the service is completed and confirmed by you. This reduces the chances of fraud or dissatisfaction.",
    },
    {
      question: "How do I initiate a service transaction on TranSecure Escrow?",
      answer:
        "Starting a service transaction is simple. Once you find a service provider, you'll initiate the transaction on our platform. The service provider will be notified, and your payment will be held securely until the service is successfully provided.",
    },
    {
      question: "Are there any fees for using TranSecure Escrow for services?",
      answer:
        "Yes, TranSecure Escrow charges a nominal fee for the security and convenience we provide. The fee is transparent and will be clearly communicated to you before you finalize a transaction. It's a small price to pay for a secure service experience.",
    },
  ];
  return (
    <Container>
      <Box className="py-10 mb-5">
        <Box className=" text-center mt-5">
          <Typography
            variant="h2"
            sx={{
              fontSize: "38px",
              fontFamily: "Montserrat Bold",
              color: "#063851",
              "@media (max-width: 640px)": {
                fontSize: "32px",
              },
            }}
          >
            Frequently Asked{" "}
            <span style={{ color: "#fe7200", fontFamily: "Montserrat Bold" }}>
              Questions
            </span>
          </Typography>
          <h3 class="text-sm  mt-1.5  opacity-80 mx-auto leading-1.5 sm:w-full w-72">
            Common Inquiries from TranSecure Escrow Users – Explore the Answers
            Below
          </h3>
        </Box>
        <Box className="py-5 mt-5">
          <Grid2 container>
            <Grid2 item sm={12} md={6}>
              <img src={faq} alt="" className="w-full md:w-[80%]" />
            </Grid2>
            <Grid2 item sm={12} md={6}>
              <div className="mt-5">
                {faqs.map((faq, index) => (
                  <Accordion
                    expanded={expanded === `panel${index}`}
                    onChange={handleChange(`panel${index}`)}
                    key={index}
                  >
                    <AccordionSummary
                      aria-controls={`panel${index}d-content`}
                      id={`panel${index}d-header`}
                    >
                      <Typography sx={{ fontWeight: "600", fontSize: "17px" }}>
                        {faq.question}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography sx={{ fontSize: "14px" }}>
                        {faq.answer}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </Grid2>
          </Grid2>
        </Box>
      </Box>
    </Container>
  );
}
