import React, { useMemo, useState, useRef, useEffect } from "react";
import {
  Box,
  Container,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Button,
  Snackbar,
  List,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  useTheme,
  createTheme,
  Fade,
  Slide,
  Card,
  CardContent,
  Typography,
  Link,
  FormHelperText,
  IconButton,
  CardActions,
  Divider,
  Switch,
  FormControlLabel,
  Collapse,
  ListItem,
  Backdrop,
  CircularProgress,
  Input,
  Checkbox,
  Avatar,
  Stack,
  styled,
  Paper,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Edit, ContentCopy, WhatsApp } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import {
  DashboardHeader,
  FeeCalcModal,
  StartHeader,
  VerifyEmailAlert,
  WelcomeModal,
} from "../../components";
import QRCode from "react-qr-code";
import axios from "axios";
import { bearerPublic, bearerSecret } from "../../config";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const isEmail = (email) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  maxWidth: 400,
}));
export const ViewTransaction = () => {
  const urlRef = useRef(null);
  const search = useLocation().search;
  const theUser = JSON.parse(localStorage.getItem("agent"));
  const [uid, setUid] = useState(theUser?._id);
  const tid = new URLSearchParams(search).get("idNo");
  const [tstage, setTstage] = useState("");
  const [loading, setLoading] = useState(false);
  const [startTransaction, SetStartTransaction] = useState(false);
  const [transactionCreated, setTransactionCreated] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [checkedTerms, setCheckedTerms] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editIsOpen, setEditIsOpen] = useState(false);
  const [fopen, setFOpen] = useState(false);
  const [qrValue, setQrValue] = useState("");
  const [QrUrl, setQrUrl] = useState("");
  const [welcomeOpen, setWelcomeOpen] = useState(true);
  const [open, setOpen] = useState(false);
  const [opensel, setOpensel] = useState(false);
  const [errVal, setErrVal] = useState("");
  const [errType, setErrType] = useState("");
  const [verify, setVerify] = useState("");
  const [role, setRole] = useState("");
  const [beneficiaryEmail, setBeneficiaryEmail] = useState("");
  const [senderEmail, setSenderEmail] = useState("");
  const [beneficiaryPayRef, setBeneficiaryPayRef] = useState("");
  const [senderPayRef, setSenderPayRef] = useState("");
  const [beneficiaryPhone, setBeneficiaryPhone] = useState("");
  const [senderPhone, setSenderPhone] = useState("");
  const [dTitle, setDTitle] = useState("");
  const [dPeriod, setDPeriod] = useState("");
  const [itemId, setItemId] = useState("");
  const [itemKey, setItemKey] = useState("");
  const [itemName, setItemName] = useState("");
  const [itemPrice, setItemPrice] = useState(0);
  const [itemCat, setItemCat] = useState("");
  const [itemDesc, setItemDesc] = useState("");
  const [itemPeriod, setItemPeriod] = useState("");
  const [miles, setMiles] = useState(false);
  const [items, setItems] = useState([]);
  const [cart, setCart] = useState(false);
  const [ncur, setNCur] = useState("");
  const [scur, setSCur] = useState("");
  const [buyerFee, setBuyerFee] = useState("");
  const [sellerFee, setSellerFee] = useState("");
  const [subtotal, setSubtotal] = useState("");
  const [ftotal, setFTotal] = useState("");
  const [adminfee, setAdminfee] = useState("");
  const [adper, setAdper] = useState("0.0325");
  const [adminCharge, setAdminCharge] = useState("");
  const [transactionFulfillment, setTransactionFulfillment] = useState(
    "Milestone Transaction"
  );
  const [sfee, setSfee] = useState("");
  const [bfee, setBfee] = useState("");
  const [eqfee, setEqfee] = useState("");
  const [itemData, setItemData] = useState([]);
  const [tAction, setTAction] = useState("");
  const [tStatus, setTStatus] = useState("");
  const [deliveryType, setDeliveryType] = useState("");
  const [curVal, setCurVal] = useState("");
  const [tCur, setTCur] = useState("");
  const [paystackLocCharge, setPaystackLocCharge] = useState("");
  const [paystackIntCharge, setPaystackIntCharge] = useState("");
  const [totalAfterLocAddFee, setTotalAfterLocAddFee] = useState("");
  const [totalAfterIntAddFee, setTotalAfterIntAddFee] = useState("");
  const [buyerEmail, setBuyerEmail] = useState("");
  const [sellerEmail, setSellerEmail] = useState("");
  const [inspectionPeriod, setInspectionPeriod] = useState(
    "Milestone Transaction"
  );
  const [shippingCharge, setShippingCharge] = useState("");
  const [shippingFee, setShippingFee] = useState("");
  const [senderRole, setSenderRole] = useState("");
  const [beneficiaryRole, setBeneficiaryRole] = useState("");
  const [senderStatus, setSenderStatus] = useState("");
  const [beneficiaryStatus, setBeneficiaryStatus] = useState("");
  const [agreeStatus, setAgreeStatus] = useState(0);
  const [currency, setCurrency] = useState([
    {
      name: "NGN",
      symbol: "₦",
    },
  ]);
  let curname = "";
  const navigate = useNavigate();
  const raw = {
    name: itemName,
    price: itemPrice,
    category: itemCat,
    description: itemDesc,
    period: itemPeriod,
  };
  const timedOut = 2000;
  const handleClose = () => {
    setOpen(false);
    setErrType("");
    setErrVal("");
  };
  const handleCancelTransaction = async () => {};

  const handleAgree = async () => {
    setAgreeStatus(1);
    const token = localStorage.getItem("token");
    const headers = { Authorization: "Bearer " + token };
    const bdy = {
      beneficiaryStatus: "awaiting payment",
      senderStatus: "awaiting payment",
      // email: theUser?.email,
      // userId: uid,
    };
    let dRes;
    await axios
      .put(
        "https://transecure.onrender.com/api/v1/transactions/agree/" + tid,
        bdy,
        { headers }
      )
      .then((respd) => {
        dRes = respd?.data?.data;
      })
      .catch((err) => {
        setOpen(true);
        setErrType("error");
        setErrVal(`${err?.response?.data?.error?.message}`);
        return;
      });
    if (dRes == 2) {
      setAgreeStatus(2);
      setTstage(dRes);
      setOpen(true);
      setErrType("success");
      setErrVal("You have agreed to the terms of this transaction!");
      return;
    } else {
      setAgreeStatus(0);
      return;
    }
  };

  const handleViewTransaction = async () => {
    const token = localStorage.getItem("token");
    const headers = { Authorization: "Bearer " + token };
    const requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };
    const resp = await fetch(
      "https://transecure.onrender.com/api/v1/transactions/view/" +
        tid +
        "/" +
        uid,
      requestOptions
    );
    const data = await resp.json();
    if (data.error) {
      console.log(data.error.message);
      return;
    }
    const arrayList = data[0];
    console.log(arrayList);
    console.log(arrayList?.amount);
    setTstage(arrayList?.tstage);
    setItemData(arrayList?.items);
    setTAction(arrayList?.action);
    setMiles(arrayList?.milestone);
    setDeliveryType("No Shipping Needed");
    setSubtotal(arrayList?.amount);
    setAdminfee(arrayList?.escrowFee);
    setDTitle(arrayList?.title);
    setAdminCharge(arrayList?.escrowCharge);
    setShippingFee(arrayList?.shippingFee);
    setShippingCharge(arrayList?.shippingCharge);
    setSenderRole(arrayList?.senderRole);
    setBeneficiaryRole(arrayList?.beneficiaryRole);
    setSenderStatus(arrayList?.senderStatus);
    setBeneficiaryStatus(arrayList?.beneficiaryStatus);
    setSenderEmail(arrayList?.senderEmail);
    setBeneficiaryEmail(arrayList?.beneficiaryEmail);
    setSenderPhone(arrayList?.senderPhone);
    setBeneficiaryPhone(arrayList?.beneficiaryPhone);
    setSenderPayRef(arrayList?.senderPayRef);
    setBeneficiaryPayRef(arrayList?.beneficiaryPayRef);
    // necessary conditions
    if (
      arrayList?.senderStatus == arrayList?.beneficiaryStatus &&
      arrayList?.tstage == 1
    ) {
      setTStatus("Funds not secured");
    }
    if (arrayList?.currency == "NGN") {
      setTCur("₦");
      setCurVal("NGN");
    }
    if (arrayList?.senderRole == "buyer") {
      setBuyerEmail(arrayList?.senderEmail);
      setSellerEmail(arrayList?.beneficiaryEmail);
    }
    if (arrayList?.beneficiaryRole == "buyer") {
      setBuyerEmail(arrayList?.beneficiaryEmail);
      setSellerEmail(arrayList?.senderEmail);
    }
    if (arrayList?.milestone == false) {
      setInspectionPeriod(arrayList?.inspectionPeriod);
      setTransactionFulfillment("Stages Completion");
    }
    if (arrayList?.shippingFee == 0) {
      setShippingCharge("None");
    }

    // paystack charges & subtotals with totals
    const dtotal = Number(arrayList?.amount) + Number(arrayList?.escrowFee);
    setFTotal(dtotal);
    let paystackLocal = (1.5 / 100) * dtotal;
    let paystackInter = (3.9 / 100) * dtotal;
    paystackInter = paystackInter + 100;
    if (dtotal >= 2500) {
      paystackLocal = paystackLocal + 100;
    }
    if (paystackLocal > 2000) {
      paystackLocal = 2000;
    }
    let totalAfterLocal = paystackLocal + dtotal;
    let totalAfterInter = paystackInter + dtotal;
    setPaystackLocCharge(paystackLocal.toFixed(2));
    setPaystackIntCharge(paystackInter.toFixed(2));
    setTotalAfterLocAddFee(totalAfterLocal.toFixed(2));
    setTotalAfterIntAddFee(totalAfterInter.toFixed(2));
    return arrayList;
  };
  
  let amount = 0;
  let addFee;
  
  if (buyerEmail == theUser?.email) {
    amount = subtotal;
    if (adminCharge == "both") {
      addFee = adminfee/2;
      amount = amount + addFee;
    }
    if (adminCharge == "buyer") {
      addFee = adminfee;
      amount = amount + addFee;
    }
  }

  if (sellerEmail == theUser?.email) {
    amount = 0;
    if (adminCharge == "both") {
      addFee = adminfee/2;
      amount = amount + addFee;
    }
    if (adminCharge == "buyer") {
      addFee = adminfee;
      amount = amount + addFee;
    }
  }

  const dconfig = {
    public_key: bearerPublic,
    tx_ref: tid,
    amount: amount,
    currency: curVal,
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email: theUser?.email,
      phone_number: theUser?.phone,
      name: theUser?.firstName + " " + theUser?.lastName
    },
    customizations: {
      title: "TranSecure Escrow Payments",
      description: "You are about making payment for "+dTitle+"("+tid+")",
      logo: "https://www.transecureescrow.com/images/logoinvert.png",
    },
  };

  const handlePaymentNow = useFlutterwave(dconfig);

  const makePaymentNow = async () => {
    let resp;
    const paynow = await handlePaymentNow({
      callback: (response) => {
        resp = response;
         console.log(response);
          closePaymentModal() // this will close the modal programmatically
          // alert(resp?.status);
          return resp?.status; 
      },
      onClose: () => {
      },
    });
    if (paynow == "successfull") {

    const token = localStorage.getItem("token");
    const headers = { Authorization: "Bearer " + token };
    let bdy;
    let dRes;
    if (beneficiaryEmail == theUser?.email) {
      bdy = {
        beneficiaryStatus: "awaiting services",
        senderStatus: senderStatus,
        beneficiaryPayRef: resp?.transaction_id,
        senderPayRef: senderPayRef,
      };
    }
    if (senderEmail == theUser?.email) {
      bdy = {
        beneficiaryStatus: beneficiaryStatus,
        senderStatus: "awaiting services",
        beneficiaryPayRef: beneficiaryPayRef,
        senderPayRef: resp?.transaction_id,
      };
    }
    await axios
      .put(
        "https://transecure.onrender.com/api/v1/transactions/pay/" + tid,
        bdy,
        { headers }
      )
      .then((respd) => {
        dRes = respd?.data?.data;
        console.log(respd?.response);
      })
      .catch((err) => {
        setOpen(true);
        setErrType("error");
        setErrVal(`${err?.response?.data?.error?.message}`);
        return;
      });
    if (dRes == 3) {
      setAgreeStatus(3);
      setTstage(dRes);
      setOpen(true);
      setErrType("success");
      setErrVal("Your payment has been made successfully!");
      return;
    } else if (dRes == 2) {
      setAgreeStatus(2);
      setTstage(dRes);
      setOpen(true);
      setErrType("success");
      setErrVal("Your payment has been made successfully!");
      return;
    } else {
      setAgreeStatus(2);
      return;
    }
    }
  }
  

  // const handlePaymentNow = async () => {
   
    
  //   const response = useFlutterwave(config);
  //   console.log(response);
  // };
  useEffect(() => {
    (async function renderCheckout() {
      setUid(theUser?._id);
      const lists = await handleViewTransaction();
      console.log(lists);
    })();
  }, []);
  return (
    <main
      style={{
        backgroundColor: "#fff",
        width: "100vw",
        minHeight: "100vh",
      }}>
      <DashboardHeader />
      <Container
        style={
          window.innerWidth <= 899
            ? {
                backgroundColor: "#fff",
                border: "none",
                maxWidth: "1120px",
                padding: "20px",
              }
            : {
                backgroundColor: "#fff",
                border: "none",
                maxWidth: "1120px",
                padding: "20px 0px",
              }
        }>
        <Box>
          <Grid2 container>
            <Grid2 md={12} lg={2}>
              <Box
                sx={{
                  flexGrow: 1,
                  overflow: "hidden",
                  px: 3,
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  marginRight: "10px",
                }}>
                <Item
                  sx={
                    tstage == 1
                      ? { backgroundColor: "#fbf391", my: 1, mx: "auto", p: 2 }
                      : {
                          my: 1,
                          mx: "auto",
                          p: 2,
                        }
                  }>
                  <Stack spacing={1} direction="row" alignItems="center">
                    <Avatar
                      sizes="sm"
                      style={{
                        fontSize: "12px",
                        width: "20px",
                        height: "20px",
                        backgroundColor: "#fe7200",
                      }}>
                      1
                    </Avatar>
                    <Typography fontSize={11} align="left">
                      BUYER AND SELLER AGREE TO TERMS
                    </Typography>
                  </Stack>
                </Item>
                <Item
                  sx={
                    tstage == 2
                      ? { backgroundColor: "#fbf391", my: 1, mx: "auto", p: 2 }
                      : {
                          my: 1,
                          mx: "auto",
                          p: 2,
                        }
                  }>
                  <Stack spacing={1} direction="row" alignItems="center">
                    <Stack>
                      <Avatar
                        style={{
                          fontSize: "12px",
                          width: "20px",
                          height: "20px",
                          backgroundColor: "#fe7200",
                        }}>
                        2
                      </Avatar>
                    </Stack>
                    <Stack sx={{ minWidth: 0 }}>
                      <Typography fontSize={11} align="left">
                        BUYER SENDS PAYMENT TO{" "}
                        <span style={{ color: "#000", fontWeight: "bold" }}>
                          TRANSECUREESCROW
                        </span>
                      </Typography>
                    </Stack>
                  </Stack>
                </Item>
                <Item
                  sx={
                    tstage == 3
                      ? { backgroundColor: "#fbf391", my: 1, mx: "auto", p: 2 }
                      : {
                          my: 1,
                          mx: "auto",
                          p: 2,
                        }
                  }>
                  <Stack spacing={1} direction="row" alignItems="center">
                    <Stack>
                      <Avatar
                        style={{
                          fontSize: "12px",
                          width: "20px",
                          height: "20px",
                          backgroundColor: "#fe7200",
                        }}>
                        3
                      </Avatar>
                    </Stack>
                    <Stack sx={{ minWidth: 0 }}>
                      <Typography fontSize={11} align="left">
                        SELLER DELIVERS SERVICES TO BUYER
                      </Typography>
                    </Stack>
                  </Stack>
                </Item>
                <Item
                  sx={
                    tstage == 4
                      ? { backgroundColor: "#fbf391", my: 1, mx: "auto", p: 2 }
                      : {
                          my: 1,
                          mx: "auto",
                          p: 2,
                        }
                  }>
                  <Stack spacing={1} direction="row" alignItems="center">
                    <Stack>
                      <Avatar
                        style={{
                          fontSize: "12px",
                          width: "20px",
                          height: "20px",
                          backgroundColor: "#fe7200",
                        }}>
                        4
                      </Avatar>
                    </Stack>
                    <Stack sx={{ minWidth: 0 }}>
                      <Typography fontSize={11} align="left">
                        BUYER RECEIVES SERVICES
                      </Typography>
                    </Stack>
                  </Stack>
                </Item>
                <Item
                  sx={
                    tstage == 5
                      ? { backgroundColor: "#fbf391", my: 1, mx: "auto", p: 2 }
                      : {
                          my: 1,
                          mx: "auto",
                          p: 2,
                        }
                  }>
                  <Stack spacing={1} direction="row" alignItems="center">
                    <Stack>
                      <Avatar
                        style={{
                          fontSize: "12px",
                          width: "20px",
                          height: "20px",
                          backgroundColor: "#fe7200",
                        }}>
                        5
                      </Avatar>
                    </Stack>
                    <Stack sx={{ minWidth: 0 }}>
                      <Typography fontSize={11} align="left">
                        <span style={{ color: "#000", fontWeight: "bold" }}>
                          TRANSECUREESCROW
                        </span>{" "}
                        PAYS SELLER
                      </Typography>
                    </Stack>
                  </Stack>
                </Item>
              </Box>
            </Grid2>
            <Grid2 md={12} lg={10}>
              {(senderRole == "buyer" && buyerEmail == theUser?.email) ||
              (senderRole == "seller" && sellerEmail == theUser?.email) ? (
                <Typography
                  variant="h2"
                  component="h2"
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: "22px",
                    fontWeight: "bold",
                    marginBottom: "10px",
                    color: "#000",
                  }}>
                  {senderStatus == "awaiting agreement" &&
                    "The " +
                      beneficiaryRole.charAt(0).toUpperCase() +
                      beneficiaryRole.slice(1) +
                      " has to sign in to review and agree to the terms"}
                  {senderStatus == "awaiting payment" &&
                    senderRole == "buyer" &&
                    "Make payment to seller"}
                  {senderStatus == "awaiting payment" &&
                    senderRole == "seller" &&
                    "Awaiting payment from buyer"}
                </Typography>
              ) : (
                <Typography
                  variant="h2"
                  component="h2"
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: "22px",
                    fontWeight: "bold",
                    marginBottom: "10px",
                    color: "#000",
                  }}>
                  {beneficiaryStatus == "awaiting agreement" &&
                    "Review and agree to terms"}
                  {beneficiaryStatus == "awaiting payment" &&
                    beneficiaryRole == "buyer" &&
                    "Make payment to seller"}
                  {beneficiaryStatus == "awaiting payment" &&
                    beneficiaryRole == "seller" &&
                    "Awaiting payment from buyer"}
                </Typography>
              )}
              <Divider />
              <Box
                sx={{
                  fontSize: "12px",
                  padding: "5px 10px",
                  margin: "15px 0px 20px",
                  color: "#000",
                }}>
                {(senderRole == "buyer" && buyerEmail == theUser?.email) ||
                (senderRole == "seller" && sellerEmail == theUser?.email) ? (
                  <ul style={{ listStyleType: "disc", marginLeft: "15px" }}>
                    <li>
                      {senderStatus == "awaiting agreement" &&
                        "You have provided the terms of this transaction."}
                      {senderStatus == "awaiting payment" && (
                        <span>
                          The{" "}
                          {beneficiaryRole.charAt(0).toUpperCase() +
                            beneficiaryRole.slice(1)}{" "}
                          has accepted the terms of this transaction.
                        </span>
                      )}
                    </li>
                    <li style={{ marginTop: "5px" }}>
                      {senderStatus == "awaiting agreement" && (
                        <span>
                          The{" "}
                          <span className="text-capital">
                            {beneficiaryRole}
                          </span>{" "}
                          must sign in to transecureescrow.com and agree to
                          these terms. An E-Mail reminder was sent to the{" "}
                          <span className="text-capital">
                            {beneficiaryRole}
                          </span>
                          .
                        </span>
                      )}
                      {senderStatus == "awaiting payment" &&
                        senderRole == "buyer" && (
                          <span>
                            Make Payment to Transecureescrow.com. So the seller
                            can start with the delivery of service. Seller will
                            receive an E-Mail notification to begin with
                            delivery of service once payment is confirmed by
                            Transecureescrow.com.
                          </span>
                        )}
                      {senderStatus == "awaiting payment" &&
                        senderRole == "seller" && (
                          <span>
                            The Buyer has been prompted to send payment. An
                            E-Mail reminder have also been sent to the Buyer.
                            You will receive an E-Mail notification once the
                            Buyer's payment has been confirmed by
                            Transecureescrow.com.
                          </span>
                        )}
                    </li>
                  </ul>
                ) : (
                  <ul style={{ listStyleType: "disc", marginLeft: "15px" }}>
                    <li>
                      {beneficiaryStatus == "awaiting agreement" &&
                        "The " +
                          senderRole.charAt(0).toUpperCase() +
                          senderRole.slice(1) +
                          " has provided the terms of this transaction."}
                      {beneficiaryStatus == "awaiting payment" &&
                        "You have agreed to the terms of this transaction."}
                    </li>
                    <li style={{ marginTop: "5px" }}>
                      {beneficiaryStatus == "awaiting agreement" && (
                        <span>
                          If you <b>Agree</b>, the{" "}
                          {senderRole.charAt(0).toUpperCase() +
                            senderRole.slice(1)}{" "}
                          will be prompted to send payment. If you{" "}
                          <b>Do Not Agree</b>, you may cancel the transaction.
                          The{" "}
                          {senderRole.charAt(0).toUpperCase() +
                            senderRole.slice(1)}{" "}
                          must sign in to transecureescrow.com and agree to
                          these terms. An E-Mail reminder was sent to the{" "}
                          {senderRole.charAt(0).toUpperCase() +
                            senderRole.slice(1)}
                          .
                        </span>
                      )}
                      {beneficiaryStatus == "awaiting payment" &&
                        beneficiaryRole == "seller" && (
                          <span>
                            The Buyer has been prompted to send payment. An
                            E-Mail reminder have also been sent to the Buyer.
                            You will receive an E-Mail notification once the
                            Buyer's payment has been confirmed by
                            Transecureescrow.com.
                          </span>
                        )}
                      {beneficiaryStatus == "awaiting payment" &&
                        beneficiaryRole == "buyer" && (
                          <span>
                            Make Payment to Transecureescrow.com. So the seller
                            can start with the delivery of service. Seller will
                            receive an E-Mail notification to begin with
                            delivery of service once payment is confirmed by
                            Transecureescrow.com.
                          </span>
                        )}
                    </li>
                  </ul>
                )}
              </Box>

              {(senderRole == "buyer" && buyerEmail == theUser?.email) ||
              (senderRole == "seller" && sellerEmail == theUser?.email) ? (
                <Box
                  sx={{
                    fontSize: "12px",
                    margin: "15px 0px 20px",
                    color: "#000",
                  }}>
                  <FormControl style={{ marginRight: "10px" }}>
                    {(senderRole == "buyer" &&
                      senderStatus == "awaiting payment") && (
                        <Button
                        onClick={makePaymentNow}
                          sx={{
                            textTransform: "capitalize",
                            marginBottom: "5px",
                          }}
                          variant="contained"
                          color="warning">
                          Make Payment Now
                        </Button>
                      )}
                    {(senderRole == "seller" &&
                      beneficiaryStatus == "awaiting delivery" && adminCharge == "seller" && senderStatus == "awaiting payment") && (
                        <Button
                        onClick={makePaymentNow}
                          sx={{
                            textTransform: "capitalize",
                            marginBottom: "5px",
                          }}
                          variant="contained"
                          color="warning">
                          Pay Transecureescrow Fee
                        </Button>
                      )}
                    {(senderRole == "seller" &&
                      beneficiaryStatus == "awaiting delivery" && adminCharge == "both" && senderStatus == "awaiting payment") && (
                        <Button
                        onClick={makePaymentNow}
                          sx={{
                            textTransform: "capitalize",
                            marginBottom: "5px",
                          }}
                          variant="contained"
                          color="warning">
                          Pay Transecureescrow Fee
                        </Button>
                      )}
                  </FormControl>
                  <FormControl>
                    {senderStatus == "awaiting agreement" && (
                      <Button
                        onClick={handleCancelTransaction}
                        sx={{
                          backgroundColor: "rgb(247, 249, 252)",
                          textTransform: "capitalize",
                          marginBottom: "5px",
                          color: "#000",
                        }}
                        variant="contained"
                        color="inherit">
                        Cancel Transaction
                      </Button>
                    )}
                        <Button
                          onClick={() => navigate("/transactions")}
                          sx={{
                            textTransform: "capitalize",
                            marginBottom: "5px",
                          }}
                          variant="contained"
                          color="success">
                          My Transactions
                        </Button>
                  </FormControl>
                </Box>
              ) : (
                <Box
                  sx={{
                    fontSize: "12px",
                    margin: "15px 0px 20px",
                    color: "#000",
                  }}>
                  <FormControl style={{ marginRight: "10px" }}>
                    {(beneficiaryRole == "buyer" &&
                      beneficiaryStatus == "awaiting payment") && (
                        <Button
                        onClick={makePaymentNow}
                          sx={{
                            textTransform: "capitalize",
                            marginBottom: "5px",
                          }}
                          variant="contained"
                          color="warning">
                          Make Payment Now
                        </Button>
                      )}
                    {(beneficiaryRole == "seller" &&
                      beneficiaryStatus == "awaiting payment" && senderStatus == "awaiting delivery" && adminCharge == "seller") && (
                        <Button
                        onClick={makePaymentNow}
                          sx={{
                            textTransform: "capitalize",
                            marginBottom: "5px",
                          }}
                          variant="contained"
                          color="warning">
                          Pay Transecureescrow Fee
                        </Button>
                      )}
                    {(beneficiaryRole == "seller" &&
                      beneficiaryStatus == "awaiting payment" && senderStatus == "awaiting delivery" && adminCharge == "both") && (
                        <Button
                        onClick={makePaymentNow}
                          sx={{
                            textTransform: "capitalize",
                            marginBottom: "5px",
                          }}
                          variant="contained"
                          color="warning">
                          Pay Transecureescrow Fee
                        </Button>
                      )}
                  </FormControl>
                  <FormControl style={{ marginRight: "10px" }}>
                    {agreeStatus == 0 &&
                    beneficiaryStatus == "awaiting agreement" ? (
                      <Button
                        onClick={handleAgree}
                        sx={{
                          textTransform: "capitalize",
                          marginBottom: "5px",
                        }}
                        variant="contained"
                        color="warning">
                        Agree
                      </Button>
                    ) : agreeStatus == 1 &&
                      beneficiaryStatus == "awaiting agreement" && (
                      <Button
                        sx={{
                          textTransform: "capitalize",
                          marginBottom: "5px",
                        }}
                        variant="contained"
                        color="warning">
                        <CircularProgress
                          size={25}
                          sx={{ fontSize: "inherit  ", color: "#fff" }}
                        />
                      </Button>
                    )}
                      <Button
                        onClick={() => navigate("/transactions")}
                        sx={{
                          textTransform: "capitalize",
                          marginBottom: "5px",
                        }}
                        variant="contained"
                        color="success">
                        My Transactions
                      </Button>
                  </FormControl>
                  <FormControl>
                    {beneficiaryStatus == "awaiting agreement" && (
                      <Button
                        onClick={handleCancelTransaction}
                        sx={{
                          backgroundColor: "rgb(247, 249, 252)",
                          textTransform: "capitalize",
                          marginBottom: "5px",
                          color: "#000",
                        }}
                        variant="contained"
                        color="inherit">
                        Cancel Transaction
                      </Button>
                    )}
                  </FormControl>
                  <FormControl style={{ marginTop: "20px" }}>
                    <Typography
                      component="p"
                      variant="caption"
                      style={{ fontFamily: "Montserrat" }}>
                      By selecting the "Agree" button you agree to the terms of
                      transaction ID {tid} and{" "}
                      <Link
                        onClick={() => navigate("/privacy-policy")}
                        sx={{ cursor: "pointer" }}>
                        General Transecureescrow Instructions
                      </Link>
                      .
                    </Typography>
                  </FormControl>
                </Box>
              )}
              <Box
                sx={{
                  padding: "5px 15px",
                  borderRadius: "5px",
                  backgroundColor: "#eee",
                }}>
                <Grid2
                  container
                  sx={{ padding: "5px 0px", alignItems: "center" }}>
                  <Grid2 lg={8} md={12}>
                    <Box>
                      <table className="merchandise">
                        <thead>
                          <tr>
                            <th colSpan={7} style={{ textAlign: "center" }}>
                              Services
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th style={{ width: "15%" }}>Actions</th>
                            <th style={{ width: "5%" }}>Milestone No.</th>
                            <th style={{ width: "10%" }}>Status</th>
                            <th style={{ width: "30%" }}>Item Description</th>
                            <th style={{ width: "10%" }}>
                              Inspection Period (Calendar Days)
                            </th>
                            <th style={{ width: "10%" }}>Delivery Type</th>
                            <th style={{ width: "20%", paddingRight: "10px" }}>
                              Total
                            </th>
                          </tr>
                          {itemData.length !== 0 ? (
                            itemData.map((ditems, itx) => {
                              return (
                                <tr key={itx}>
                                  <td align="center">{tAction}</td>
                                  <td align="center">{itx + 1}</td>
                                  <td align="left">{tStatus}</td>
                                  <td align="left">
                                    <strong>{ditems?.name}</strong>
                                    <br />
                                    <span>{ditems?.description}</span>
                                  </td>
                                  <td align="center">{ditems?.period}</td>
                                  <td align="center">{deliveryType}</td>
                                  <td>
                                    {tCur}
                                    {parseFloat(ditems?.price)
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={7} align="center">
                                No Item Found.
                              </td>
                            </tr>
                          )}
                          <tr>
                            <td colSpan={6} align="right" className="f13">
                              Subtotal
                            </td>
                            <td>
                              {tCur +
                                parseFloat(subtotal)
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={6} align="right" className="f13">
                              Transecureescrow Fee
                            </td>
                            <td>
                              {tCur +
                                parseFloat(adminfee)
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                          </tr>
                          <tr className="bg-orange">
                            <td colSpan={6} align="right" className="f13">
                              <strong>Total</strong>
                              <br />
                              <span className="f11 fw500">
                                Transaction ID: {tid}
                              </span>
                            </td>
                            <td>
                              <strong>
                                {tCur +
                                  parseFloat(ftotal)
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </strong>
                            </td>
                          </tr>
                          <tr>
                            <td
                              colSpan={7}
                              className="text-left fw600"
                              style={{ paddingTop: "20px" }}>
                              Additional Fees
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={6} className="text-right f13">
                              <span className="fw600">
                                Paystack Processing Fee
                              </span>
                            </td>
                            <td className="text-right f13">
                              +{" "}
                              <span>
                                {tCur +
                                  paystackLocCharge.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}
                              </span>
                              <br />
                              <span className="f11">
                                (
                                {tCur +
                                  totalAfterLocAddFee.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}{" "}
                                <br /> in Total)
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={6} className="text-right f13">
                              <span>If it's an international transaction</span>
                              <br />
                              <span className="fw600 f11">
                                Paystack Processing Fee
                              </span>
                            </td>
                            <td className="text-right f13">
                              +{" "}
                              <span>
                                {tCur +
                                  paystackIntCharge.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}
                              </span>
                              <br />
                              <span className="f11">
                                (
                                {tCur +
                                  totalAfterIntAddFee.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}{" "}
                                <br /> in Total)
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table className="terms">
                        <thead>
                          <tr>
                            <th colSpan={3} className="text-left">
                              Terms
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Transaction Id:</td>
                            <td>&nbsp;</td>
                            <td>{tid}</td>
                          </tr>
                          <tr>
                            <td>Transaction Title:</td>
                            <td>&nbsp;</td>
                            <td>{dTitle}</td>
                          </tr>
                          <tr>
                            <td>Buyer:</td>
                            <td>&nbsp;</td>
                            <td>{buyerEmail}</td>
                          </tr>
                          <tr>
                            <td>Seller:</td>
                            <td>&nbsp;</td>
                            <td>{sellerEmail}</td>
                          </tr>
                          <tr>
                            <td>Inspection Period (Calendar Days):</td>
                            <td>&nbsp;</td>
                            <td>{inspectionPeriod}</td>
                          </tr>
                          <tr valign="top">
                            <td>Transecurescrow Fee to be paid by:</td>
                            <td>&nbsp;</td>
                            <td>
                              <span className="text-capital">
                                {adminCharge}
                              </span>
                              <br />
                              <span className="f11" style={{}}>
                                The {adminCharge} is responsible for the escrow
                                fee in the event the transaction is cancelled,
                                or the merchandise is returned
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>Transaction fulfilled using:</td>
                            <td>&nbsp;</td>
                            <td>{transactionFulfillment}</td>
                          </tr>
                          <tr>
                            <td>Shipping fee to be paid by:</td>
                            <td>&nbsp;</td>
                            <td>{shippingCharge}</td>
                          </tr>
                        </tbody>
                      </table>
                    </Box>
                  </Grid2>
                  <Grid2 lg={4} md={12}></Grid2>
                </Grid2>
              </Box>
            </Grid2>
            <Grid2 md={12}></Grid2>
          </Grid2>
        </Box>
      </Container>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          id="snckErr"
          onClose={handleClose}
          severity={errType}
          sx={{ width: "100%" }}>
          {errVal}
        </Alert>
      </Snackbar>
    </main>
  );
};
