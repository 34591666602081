import React, { useRef, useMemo, useState, useEffect, useContext } from "react";
import {
  Button,
  Box,
  Card,
  CardActions,
  CardContent,
  Container,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  Snackbar,
  Input,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { ArrowRightAlt, Visibility, VisibilityOff } from "@mui/icons-material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import { AgentContext } from "../../contexts";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export const Signin = () => {
  const search = useLocation().search;
  const { agent, setAgent } = useContext(AgentContext);
  const serv = new URLSearchParams(search).get("s");
  const amount = new URLSearchParams(search).get("a");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState("");
  const [open, setOpen] = useState(false);
  const [openIam, setOpenIam] = useState(false);
  const [errVal, setErrVal] = useState("");
  const [errType, setErrType] = useState("");

  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    setErrType("");
    setErrVal("");
  };

  const raw = {
    email,
    password,
  };
  function MyFormHelperText(e) {
    let pass = e.target.value;
    setPassword(pass);
    if (pass == "") {
      document.getElementById("pser").innerHTML = "Password is required";
      document.getElementById("pser").style.color = "red";
      document.querySelector(".pass fieldset").style.borderColor = "red";
      return;
    }
    document.getElementById("pser").innerHTML = "";
    document.querySelector(".pass fieldset").style.borderColor = "";
    return "";
  }
  function emailValid(e) {
    let eml = e.target.value;
    setEmail(eml);
    if (eml == "") {
      document.querySelector("#eser").innerHTML =
        "Please enter a valid email address";
      document.querySelector("#eser").style.color = "red";
      document.querySelector(".email fieldset").style.borderColor = "red";
      return;
    }
    document.querySelector("#eser").innerHTML = "";
    document.querySelector(".email fieldset").style.borderColor = "";
    return "";
  }

  const handleLogin = async () => {
    setOpen(true);
    setErrType("primary");
    setErrVal("Logging in...");
    let body = {
      email: email,
      password: password,
    };
    if (!email && !password) {
      setOpen(true);
      setErrType("error");
      setErrVal("All fields are required!");
      return;
    }
    if (!email) {
      setOpen(true);
      setErrType("error");
      setErrVal("Email is required!");
      return;
    }
    if (!password) {
      setOpen(true);
      setErrType("error");
      setErrVal("Password is required!");
      return;
    }
    const resp = await fetch(
      "https://transecure.onrender.com/api/v1/auth/users/login",

      {
        method: "POST",
        body: JSON.stringify(body),
        headers: { "Content-type": "application/json" },
      }
    );
    const data = await resp.json();
    if (data.error) {
      setOpen(true);
      setErrType("error");
      setErrVal(data.error.message);
      return;
    }
    if (data.user) {
      setAgent(data.user);
      localStorage.setItem("agent", JSON.stringify(data.user));
      localStorage.setItem("token", data.token);
      setTimeout(() => {
        setOpen(true);
        setErrType("success");
        setErrVal("Login successful!");
      }, 2000);
      setTimeout(() => {
        if (serv == "" || amount == "" || serv == null || amount == null) {
          navigate("/transactions");
        } else {
          navigate("/transactions");
        }
      }, 3000);
    }
    return;
  };

  return (
    <main
      style={{
        backgroundColor: "#fff",
        width: "100vw",
        minHeight: "100vh",
      }}
      className="authMain">
      <header
        style={{
          padding: "10px 16px",
          marginBottom: 152,
          margin: "0 auto",
          justifyContent: "space-between",
          boxShadow: "0 2px 4px 0 rgb(0 0 0 / 10%)",
        }}
        className="flex justify-between align-center desktopHide">
        <Link to="/" style={{ marginRight: 30 }}>
          <img src="/images/logoinvert.png" width="65px" />
        </Link>
      </header>
      <Container>
        <header
          style={{
            padding: "10px 0",
            marginBottom: 152,
            margin: "0 auto",
            justifyContent: "space-between",
          }}
          className="flex justify-between align-center mobileHide">
          <Link to="/" style={{ marginRight: 30 }}>
            <img src="/images/logoinvert.png" width="100px" />
          </Link>
        </header>
        <Box className="authMob">
          <Grid2 container>
            <Grid2 className="mobileHide" md lg={6}>
              <Box>
                <img src="/images/illustration_login.svg" />
              </Box>
            </Grid2>
            <Grid2 md={12} lg={4} lgOffset={"auto"} className="cardGrid">
              <Card
                sx={
                  window.innerHeight <= 899
                    ? { minWidth: 275, padding: "24px 20px 20px" }
                    : { minWidth: 275, padding: "32px 20px 0px" }
                }
                className="gridcard">
                <Typography
                  variant="h6"
                  component="h6"
                  style={{
                    color: "#fff",
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                  }}>
                  Kele gi,
                </Typography>
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { marginTop: "0px", width: "25ch" },
                  }}
                  noValidate
                  autoComplete="off">
                  <CardContent>
                    <Typography
                      variant="h3"
                      component="h3"
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: 700,
                        color: "#fff",
                        paddingTop: "0px",
                        marginTop: "0px",
                      }}>
                      Login
                    </Typography>
                    <div
                      style={{
                        margin: "7px 0px 16px",
                        background: "#fff",
                        height: "2.4px",
                        width: "100px",
                      }}></div>

                    <Box
                      sx={{ marginTop: "32px", width: "100%" }}
                      autoComplete="off">
                      <FormControl
                        className="email"
                        style={{
                          fontFamily: "Montserrat!important",
                          width: "100%",
                        }}>
                        <TextField
                          style={{
                            color: "#fff",
                            fontFamily: "Montserrat!important",
                            width: "100%",
                          }}
                          label="Email Address"
                          variant="standard"
                          type="email"
                          value={email}
                          onChange={emailValid}
                          autoComplete="off"
                          className="emailLabel"
                        />
                        <FormHelperText id="eser"></FormHelperText>
                      </FormControl>
                      <FormControl
                        className="pass"
                        sx={{ marginTop: "10px", width: "100%" }}
                        variant="standard">
                        <InputLabel htmlFor="standard-adornment-password">
                          Password
                        </InputLabel>
                        <Input
                          id="standard-adornment-password"
                          type={showPassword ? "text" : "password"}
                          style={{
                            fontFamily: "Montserrat",
                            width: "100%",
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword((show) => !show)}
                                onMouseDown={(e) => e.preventDefault()}
                                edge="end">
                                {showPassword ? (
                                  <VisibilityOff style={{ color: "#fff" }} />
                                ) : (
                                  <Visibility style={{ color: "#fff" }} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Password"
                          value={password}
                          onChange={MyFormHelperText}
                          className="passLabel"
                        />
                        <FormHelperText id="pser"></FormHelperText>
                      </FormControl>
                      <Typography
                        variant="caption"
                        component="p"
                        style={{
                          fontFamily: "Montserrat",
                          marginTop: "5px",
                          textAlign: "right",
                        }}>
                        <Link
                          to="/forgot-password"
                          style={{ fontWeight: 500, color: "#fff" }}>
                          forgot password?
                        </Link>
                        .
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardActions>
                    <Box sx={{ width: "100%", marginBottom: "16px" }}></Box>
                  </CardActions>
                </Box>
              </Card>
              <Box className="space-y-4 mt-3">
                <FormControl style={{ position: "relative", width: "100%" }}>
                  <Button
                    style={{
                      background: "#063851",
                      position: "absolute",

                      zIndex: 1,
                      left: "auto",
                      right: "0px",
                      borderRadius: 0,
                      fontFamily: "Montserrat",
                      fontSize: "18px",
                      textTransform: "none",
                      width: "130px",
                      padding: "10px 10px 10px 30px",
                      borderTopLeftRadius: "20px",
                      borderBottomLeftRadius: "20px",
                      color: "#fff",
                    }}
                    onClick={handleLogin}
                    variant="contained">
                    Login
                  </Button>
                </FormControl>

                <FormControl style={{ position: "relative", width: "100%" }}>
                  <Button
                    style={{
                      background: "#fe7200",
                      position: "absolute",
                      top: "20px",
                      zIndex: 1,
                      left: "auto",
                      right: "0px",
                      borderRadius: 0,
                      fontFamily: "Montserrat",
                      fontSize: "18px",
                      textTransform: "none",
                      width: "130px",
                      padding: "10px 10px 10px 30px",
                      borderTopLeftRadius: "20px",
                      borderBottomLeftRadius: "20px",
                    }}
                    onClick={() =>
                      navigate("/sign-up?s=" + serv + "&a=" + amount)
                    }
                    variant="contained">
                    Sign Up
                  </Button>
                </FormControl>
              </Box>
            </Grid2>
          </Grid2>
        </Box>
      </Container>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          id="snckErr"
          onClose={handleClose}
          severity={errType}
          sx={{ width: "100%" }}>
          {errVal}
        </Alert>
      </Snackbar>
    </main>
  );
};
